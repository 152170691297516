import React, { useState } from "react";
import { Table, Space, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { EditFilled, LinkOutlined } from "@ant-design/icons";
import { date, utils } from "../../../utils";
import {
  Actions,
  ImagesModal,
  Label,
  Pagination,
} from "../../../components/private";
import { useLocale, useMobile } from "../../../hooks";
import Text from "antd/lib/typography/Text";

const { Column } = Table;

const modalDefaults = {
  isOpen: false,
  slides: [],
  initialSlide: 0,
};

const PostTable = ({
  data,
  loading,
  activeTab,
  select,
  getAllowedActions,
  getAction,
  page,
  next,
  prev,
}) => {
  const [modalData, setModalData] = useState(modalDefaults);
  const isMobile = useMobile();

  const t = useLocale("posts/list");
  const tGlobal = useLocale("global");

  const renderImage = (cover, record) => {
    return (
      <Space className="post-images">
        {cover.slice(0, 5).map((item, idx) => (
          <img
            className="post-images__img"
            key={idx}
            onClick={() => {
              setModalData({
                slides: [...cover],
                initialSlide: idx,
                isOpen: true,
              });
            }}
            src={item}
            alt=""
          />
        ))}
        {cover.length - 5 > 0 && (
          <div
            className="post-images__more"
            onClick={() => {
              setModalData({
                slides: [...cover],
                initialSlide: 0,
                isOpen: true,
              });
            }}
          >
            +{cover.length - 5}
          </div>
        )}
      </Space>
    );
  };

  const renderTitle = (title, record) => {
    return (
      <Link to={`/posts/${record.id}`}>
        <Text underline>{title}</Text>
      </Link>
    );
  };

  const renderActions = (record) => {
    return (
      <React.Fragment>
        <Link to={`/posts/${record.id}`}>
          <Button
            type="primary"
            // href={`/posts/${record.id}`}
            ghost
            icon={<EditFilled />}
            style={{ marginLeft: 10 }}
          />
        </Link>

        <Actions
          record={record}
          indicator={tGlobal("post")}
          status={activeTab}
          getAllowedActions={getAllowedActions}
          getAction={getAction}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ImagesModal
        data={modalData}
        onClose={() => setModalData(modalDefaults)}
      />
      <Table
        dataSource={data}
        loading={loading}
        pagination={false}
        scroll={{ x: window.innerWidth < 750 ? 0 : isMobile ? 750 : 1200 }}
        expandable={
          isMobile
            ? {
                expandedRowRender: (record) => {
                  return (
                    <div className="table-row-expanded-card">
                      <Label
                        responsive={["md", "sm", "xs"]}
                        title={t("table.heading.cover")}
                      >
                        {renderImage(record.cover, record)}
                      </Label>
                      <Label
                        responsive={["md", "sm", "xs"]}
                        title={t("table.heading.phoneNumber")}
                      >
                        <span className="ltr-field">{record.phoneNumber}</span>
                      </Label>
                      <Label
                        responsive={["sm", "xs"]}
                        title={t("table.heading.date")}
                      >
                        {date.fromNow(record.date)}
                      </Label>
                      <Label
                        responsive={["sm", "xs"]}
                        title={t("table.heading.functions")}
                      >
                        {renderActions(record)}
                      </Label>
                    </div>
                  );
                },
              }
            : null
        }
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            select(selectedRowKeys);
          },
          getCheckboxProps: (record) => ({
            name: record.title,
          }),
        }}
      >
        <Column
          title={t("table.heading.cover")}
          dataIndex="cover"
          key="cover"
          render={renderImage}
          responsive={["lg"]}
        />
        <Column
          title={t("table.heading.title")}
          dataIndex="title"
          key="title"
          render={renderTitle}
        />
        <Column
          title={t("table.heading.phoneNumber")}
          dataIndex="phoneNumber"
          key="phoneNumber"
          responsive={["lg"]}
        />
        <Column
          title={t("table.heading.date")}
          dataIndex="date"
          responsive={["md"]}
          key="date"
          render={(timestamp) => date.fromNow(timestamp)}
        />
        <Column
          title={t("table.heading.link")}
          dataIndex="id"
          responsive={["md"]}
          key="link"
          render={(id) => (
            <Button
              type="dashed"
              target="_blank"
              href={utils.websiteUrl(id)}
              icon={<LinkOutlined />}
              style={{ marginLeft: 10 }}
            >
              {t("table.fields.link.buttonTitle")}
            </Button>
          )}
        />
        <Column
          title={t("table.heading.functions")}
          responsive={["md"]}
          key="action"
          render={renderActions}
        />
      </Table>
      <Pagination next={next} prev={prev} page={page} />
    </React.Fragment>
  );
};

export default PostTable;
