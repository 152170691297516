import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./ReactPlacesAutocomplete.less";
import classNames from "classnames";
import { useLocale } from "../../../hooks";

const ReactPlacesAutocomplete = ({ onSelect }) => {
  const [address, setAddress] = React.useState("");
  const t = useLocale("components");
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    onSelect(latLng);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={{
          // location: new google.maps.LatLng(-34, 151),
          // radius: 2000,
          // types: ['address']
          componentRestrictions: {
            country: "IQ",
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="reactPlacesAutocomplete">
            <Input
              allowClear
              prefix={<SearchOutlined />}
              {...getInputProps({ placeholder: "Type address" })}
              placeholder={t("reactPlacesAutocomplete.searchInputPlaceholder")}
              className="reactPlacesAutocomplete__input"
            />

            <div className="reactPlacesAutocomplete__results results">
              {loading ? (
                <div className="results__item">
                  {t("reactPlacesAutocomplete.loading")}
                </div>
              ) : null}

              {suggestions.map((suggestion) => {
                console.log(suggestion);
                const itemClassName = classNames("results__item", {
                  "-active": suggestion.active,
                });

                return (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    className={itemClassName}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default ReactPlacesAutocomplete;
