import React from "react";
import { observer } from "mobx-react";
import { Row, Space, Typography } from "antd";

import { useLocale, useMobile, useStore } from "../../../../../hooks";
import { FormFieldWrapper } from "../../../../../components/private";
import { DocList } from "../../../../../components/shared";

const { Text } = Typography;

const DocumentsField = React.memo(({ setFormikField }) => {
  const isSmallDevice = useMobile(true);
  const { ShopStore } = useStore();
  const { documentFiles, upload, hasSomeFailedUploads } = ShopStore.shop;
  const hasDoc = documentFiles.length > 0;
  const t = useLocale("shops/form");

  return (
    <Row justify="space-between" align="middle">
      <FormFieldWrapper
        span={24}
        name="documents"
        placeholder={t("fields.documents.placeholder")}
      >
        {hasDoc && (
          <Space direction={isSmallDevice ? "vertical" : "horizontal"}>
            <Text type="secondary">
              <small>{t("fields.documents.viewImageDescription")}</small>
            </Text>
            {hasSomeFailedUploads && (
              <Text type="danger">
                <small>{t("fields.documents.failedImageDescription")}</small>
              </Text>
            )}
          </Space>
        )}
        <DocList
          editable
          docs={documentFiles}
          upload={upload}
          //   setMainImage={setMainImage}
          onChange={(data) => {
            setFormikField("documents", data);
          }}
        />
      </FormFieldWrapper>
    </Row>
  );
});

export default DocumentsField;
