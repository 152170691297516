import React, { useState } from "react";
import { LoadScript } from "@react-google-maps/api";
import { ReactPlacesAutocomplete, Map } from "../../../../../components/shared";
import { FormFieldWrapper } from "../../../../../components/private";
import { Row } from "antd";

import "./MapField.less";
import { useLocale } from "../../../../../hooks";
const KURDISTAN_LOCATION = {
  lat: 35.8826795,
  lng: 44.7164702,
};
const libraries = ["places"];

const MapField = React.memo(
  ({ label, location: initialLocation, setFormikField }) => {
    const handleChange = (location) => {
      setFormikField("location", location);
    };

    const t = useLocale("shops/form");

    const hasExactCoordinates =
      initialLocation &&
      Object.values(initialLocation).every((item) => !isNaN(item));

    const defaultLocation = hasExactCoordinates
      ? initialLocation
      : KURDISTAN_LOCATION;

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(hasExactCoordinates ? 18 : 9);
    const [markerShown, setMarkerShown] = useState(hasExactCoordinates);
    return (
      <LoadScript
        id="script-loader"
        googleMapsApiKey={"AIzaSyB2UgK53cHtLHipYHCnl_EEF5E29x-sP3k"}
        libraries={libraries}
        language="en"
        region="EN"
        version="weekly"
      >
        <Row className="fillInMobileView">
          <FormFieldWrapper
            span={24}
            name="location"
            defaultDirection="column"
            placeholder={t("fields.location.placeholder")}
          >
            <div className="mapField">
              <div className="mapField__search">
                <ReactPlacesAutocomplete
                  onSelect={(location) => {
                    setLocation(location);
                    setZoom(14);
                    setMarkerShown(false);
                  }}
                />
              </div>
              <div className="mapField__map">
                <Map
                  label={label}
                  location={location}
                  zoom={zoom}
                  markerShown={markerShown}
                  onChange={handleChange}
                />
              </div>
            </div>
          </FormFieldWrapper>
        </Row>
      </LoadScript>
    );
  }
);
export default MapField;
