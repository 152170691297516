import React, { useState } from "react";
import { Table, Space, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { EditFilled, FolderAddOutlined } from "@ant-design/icons";
import { date, utils } from "../../../utils";
import { Actions, Label, Pagination } from "../../../components/private";
import { useLocale, useMobile } from "../../../hooks";
import Text from "antd/lib/typography/Text";

const { Column } = Table;

const ShopTable = ({
  data,
  loading,
  activeTab,
  select,
  getAllowedActions,
  getAction,
  page,
  next,
  prev,
}) => {
  const isMobile = useMobile();

  const t = useLocale("shops/list");
  const tGlobal = useLocale("global");

  const renderImage = (logo, record) => {
    return (
      <Space className="shops-images">
        <img className="shops-images__img" src={logo} alt="" />
      </Space>
    );
  };

  const renderName = (name, record) => {
    return (
      <Link to={`/shops/${record.shopNumber || record.id}`}>
        <Text underline>{name}</Text>
      </Link>
    );
  };

  const renderActions = (text, record) => {
    return (
      <React.Fragment>
        <Link to={`/posts/new?shop=${record.shopNumber || record.id}`}>
          <Button type={"primary"} icon={<FolderAddOutlined />} />{" "}
        </Link>
        <Link to={`/shops/${record.shopNumber || record.id}`}>
          <Button
            type="primary"
            ghost
            icon={<EditFilled />}
            style={{ marginLeft: 10 }}
          />
        </Link>

        <Actions
          indicator={tGlobal("shop")}
          record={record}
          status={activeTab}
          getAllowedActions={getAllowedActions}
          getAction={getAction}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Table
        dataSource={data}
        loading={loading}
        pagination={false}
        scroll={{ x: window.innerWidth < 750 ? 0 : isMobile ? 750 : 1200 }}
        expandable={
          isMobile
            ? {
                expandedRowRender: (record) => {
                  return (
                    <div className="table-row-expanded-card">
                      <Label
                        responsive={["md", "sm", "xs"]}
                        title={t("table.heading.logo")}
                      >
                        {renderImage(record.logo, record)}
                      </Label>
                      <Label
                        responsive={["md", "sm", "xs"]}
                        title={t("table.heading.phoneNumber")}
                      >
                        <span className="ltr-field">{record.phoneNumber}</span>
                      </Label>
                      <Label
                        responsive={["sm", "xs"]}
                        title={t("table.heading.created")}
                      >
                        {date.fromNow(record.created)}
                      </Label>
                      <Label
                        responsive={["sm", "xs"]}
                        title={t("table.heading.functions")}
                      >
                        {renderActions(record, record)}
                      </Label>
                    </div>
                  );
                },
              }
            : null
        }
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            console.log({ selectedRows });
            select(selectedRowKeys);
          },
          getCheckboxProps: (record) => ({
            name: record.name,
          }),
        }}
      >
        <Column
          title={t("table.heading.logo")}
          dataIndex="logo"
          key="logo"
          render={renderImage}
          responsive={["lg"]}
        />
        <Column
          title={t("table.heading.name")}
          dataIndex="name"
          key="name"
          render={renderName}
        />
        <Column
          title={t("table.heading.phoneNumber")}
          dataIndex="phoneNumber"
          key="phoneNumber"
          responsive={["lg"]}
        />
        <Column
          title={t("table.heading.created")}
          dataIndex="created"
          responsive={["md"]}
          key="created"
          render={(timestamp) => date.fromNow(timestamp)}
        />

        <Column
          title={t("table.heading.functions")}
          responsive={["md"]}
          key="action"
          render={renderActions}
        />
      </Table>
      <Pagination next={next} prev={prev} page={page} />
    </React.Fragment>
  );
};

export default ShopTable;
