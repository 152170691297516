/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import { Layout } from "antd";
import {
  PartitionOutlined,
  FilterOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "../fields.less";
import { pageWrapper } from "../../../utils";
import { useLocale, useStore } from "../../../hooks";
import { CategoryModal, FieldsTable } from "../components";
import { FIELDS } from "../../../constants";

const { Content } = Layout;

const CategoryListPage = () => {
  const { FieldsStore } = useStore();
  const { push } = useHistory();
  const { data } = FieldsStore.getCategories();
  const t = useLocale("fields/list");
  const tForm = useLocale("fields/form");

  return (
    <Content className="fields">
      <CategoryModal
        title={
          FieldsStore.editMode
            ? tForm("category.titles.edit")
            : tForm("category.titles.add")
        }
        data={data}
      />
      <FieldsTable
        data={data}
        sortData={FieldsStore.sortData}
        buttonText={t("category.addButton")}
        buttonAction={() =>
          FieldsStore.toggleModal(
            "category",
            FIELDS.category.defaultFormValues,
            false
          )
        }
        columns={[
          {
            title: t("category.table.headings.title"),
            dataIndex: "title",
            className: "drag-visible",
          },
          {
            title: t("category.table.headings.slug"),
            dataIndex: "slug",
          },
        ]}
        actions={[
          {
            title: t("category.table.fields.subCategoriesButton"),
            icon: <PartitionOutlined />,
            onClick: ({ key }) => {
              push(`/fields/subcat/${key}`);
            },
          },
          {
            title: t("category.table.fields.attributesButton"),
            icon: <FilterOutlined />,
            onClick: ({ key }) => {
              push(`/fields/attribues/${key}`);
            },
          },
          {
            title: t("category.table.fields.optionsButton"),
            icon: <AlignRightOutlined />,
            onClick: ({ key }) => {
              push(`/fields/options/${key}?type=category`);
            },
          },
        ]}
      />
    </Content>
  );
};

const CategoryList = pageWrapper(observer(CategoryListPage), {
  hasHeader: true,
  get subTitle() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("fields/list")("category.description");
  },
});

export { CategoryList };
