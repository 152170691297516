/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import { Layout } from "antd";
import { FilterOutlined, AlignRightOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";

import "../fields.less";
import { pageWrapper } from "../../../utils";
import { SubCategoryModal, FieldsTable } from "../components";
import { useLocale, useStore } from "../../../hooks";
import { FIELDS } from "../../../constants";

const { Content } = Layout;

const SubCategoryListPage = () => {
  const { FieldsStore } = useStore();
  const { id } = useParams();
  const { push } = useHistory();
  const { data, parent } = FieldsStore.getCategories(id);
  const t = useLocale("fields/list");
  const tForm = useLocale("fields/form");

  return (
    <Content className="fields">
      <SubCategoryModal
        title={
          FieldsStore.editMode
            ? tForm("subCategories.titles.edit")
            : tForm("subCategories.titles.add")
        }
        data={data}
        subCategory
      />

      <FieldsTable
        data={data}
        sortData={(...params) => parent.sortData(...params, "subCategories")}
        buttonText={t("subCategories.addButton")}
        buttonAction={() =>
          FieldsStore.toggleModal(
            "subCategory",
            FIELDS.subCategory.defaultFormValues,
            false
          )
        }
        columns={[
          {
            title: t("subCategories.table.headings.title"),
            dataIndex: "title",
            className: "drag-visible",
          },
          {
            title: t("subCategories.table.headings.slug"),
            dataIndex: "slug",
          },
        ]}
        actions={[
          {
            title: t("subCategories.table.fields.attributesButton"),
            icon: <FilterOutlined />,
            onClick: ({ key }) => {
              push(`/fields/attribues/${key}?category=${id}`);
            },
          },
          {
            title: t("subCategories.table.fields.optionsButton"),
            icon: <AlignRightOutlined />,
            onClick: ({ key }) => {
              push(`/fields/options/${key}?type=subcat&category=${id}`);
            },
          },
        ]}
      />
    </Content>
  );
};

const SubCategoryList = pageWrapper(observer(SubCategoryListPage), {
  hasHeader: true,
  get subTitle() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("fields/list")("subCategories.description");
  },
});

export { SubCategoryList };
