import React, { useRef } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useLocale } from "./../../../hooks";

import { Modal, Button, Carousel } from "antd";
import "./imagesModal.less";

const ImagesModal = ({ data, onClose }) => {
  const slider = useRef(null);
  const t = useLocale("components");

  const { slides, initialSlide, isOpen } = data;
  return (
    <Modal
      title={t("imagesModal.title")}
      visible={isOpen}
      destroyOnClose
      onCancel={onClose}
      closeIcon={false}
      footer={null}
      width={800}
    >
      <div className="images-modal">
        <Button onClick={() => slider.current.next()} type="text">
          <RightOutlined />
        </Button>

        <div className="images-modal__carousel">
          <Carousel
            ref={slider}
            afterChange={() => {}}
            draggable
            infinite
            initialSlide={initialSlide}
            accessibility
          >
            {slides.map((img, idx) => (
              <img key={idx} className="modal-image" src={img} alt="modal" />
            ))}
          </Carousel>
        </div>

        <Button onClick={() => slider.current.prev()} type="text">
          <LeftOutlined />
        </Button>
      </div>
    </Modal>
  );
};
export default ImagesModal;
