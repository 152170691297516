/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import { Layout } from "antd";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

import { pageWrapper } from "../../../utils";
import { FieldsTable, OptionModal } from "../components";
import { useLocale, useStore } from "../../../hooks";
import { FIELDS } from "../../../constants";

const { Content } = Layout;

const OptionsListPage = () => {
  const { FieldsStore } = useStore();
  const { id } = useParams();
  const { search } = useLocation();
  const { type, category, subcat } = queryString.parse(search);
  const { data, parent, field } = FieldsStore.getOptions({
    id,
    type,
    category,
    subcat,
  });
  const t = useLocale("fields/list");
  const tForm = useLocale("fields/form");

  return (
    <Content className="fields">
      <OptionModal
        title={
          FieldsStore.editMode
            ? tForm("options.titles.edit")
            : tForm("options.titles.add")
        }
        data={data}
      />
      <FieldsTable
        data={data}
        sortData={(...params) => parent.sortData(...params, field)}
        buttonText={t("options.addButton")}
        buttonAction={() =>
          FieldsStore.toggleModal(
            "options",
            FIELDS.option.defaultFormValues,
            false
          )
        }
        columns={[
          {
            title: t("options.table.headings.title"),

            dataIndex: "title",
            className: "drag-visible",
            width: 200,
          },
        ]}
      />
    </Content>
  );
};

const OptionsList = pageWrapper(observer(OptionsListPage), {
  hasHeader: true,
  get subTitle() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("fields/list")("options.description");
  },
});

export { OptionsList };
