import React from "react";
import pageWrapper from "../../utils/pageWrapper";
import { Button } from "antd";
// import './.scss'
const RolesPage = () => {
  return (
    <div>
      <h1>Roles</h1>
      <Button type={"dark"}>hello</Button>
    </div>
  );
};

const Roles = pageWrapper(RolesPage, {
  hasHeader: true,
  subTitle: "تعیین سطح دسترسی نقش ها",
  customHeaderButtons: [<Button type={"dark"}>test button</Button>],
});

export default Roles;
