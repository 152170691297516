import {
  Login,
  Competitors,
  Posts,
  Staff,
  Roles,
  SinglePost,
  CategoryList,
  SubCategoryList,
  OptionsList,
  AttributesList,
  Review,
  Shops,
  SingleShop,
} from "./routes";
import {
  UserSwitchOutlined,
  BarChartOutlined,
  ShopOutlined,
  TeamOutlined,
  FileTextOutlined,
  EditOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { useLocale } from "./hooks";

export default {
  routes: [
    /*
    {
      id : unique (string),
      title : (string) that show in browser tab,
      path : route path (string),
      exact : (boolean) for route in router,
      noNeedLogin : (boolean) true for noNeedLogin pages,
      component : (component) that render in route ,
      sidebarOptions : {
        title : (string) that show in sidebar,
        icon : (component) that show in sidebar as icon ,
        order : (number),
        groupId : (string) that pair with releated group id
      }
    }
    */
    {
      id: "PAGE_LOGIN",
      title: "ورود و ثبت نام",
      path: "/login",
      noNeedLogin: true,
      component: Login,
    },
    {
      id: "CATEGORY_LIST",
      get title() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useLocale("fields/list")("title");
      },
      path: "/fields",
      noNeedLogin: false,
      isExact: true,
      component: CategoryList,
      sidebarOptions: {
        get title() {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useLocale("fields/list")("sidebarTitle");
        },
        icon: FileTextOutlined,
        order: 4,
      },
    },
    {
      id: "SHOPS_LIST",
      get title() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useLocale("shops/list")("title");
      },
      path: "/shops",
      noNeedLogin: false,
      isExact: true,
      component: Shops,
      sidebarOptions: {
        get title() {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useLocale("shops/list")("sidebarTitle");
        },
        icon: ShopOutlined,
        order: 5,
      },
    },
    {
      id: "SINGLE_SHOP",
      title: "ویرایش فروشگاه",
      path: "/shops/:id",
      noNeedLogin: false,
      component: SingleShop,
    },
    {
      id: "CATEGORY_LIST",
      path: "/fields/subcat/:id",
      noNeedLogin: false,
      component: SubCategoryList,
    },
    {
      id: "CATEGORY_LIST",
      path: "/fields/attribues/:id",
      noNeedLogin: false,
      component: AttributesList,
    },
    {
      id: "CATEGORY_LIST",
      path: "/fields/options/:id",
      noNeedLogin: false,
      component: OptionsList,
    },
    {
      id: "CATEGORY_LIST",
      path: "/fields/review",
      noNeedLogin: false,
      component: Review,
    },
    {
      id: "PAGE_POSTS",
      get title() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useLocale("posts/list")("title");
      },
      path: "/posts",
      noNeedLogin: false,
      isExact: true,
      component: Posts,
      sidebarOptions: {
        get title() {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useLocale("posts/list")("sidebarTitle");
        },
        icon: FileTextOutlined,
        order: 2,
      },
    },
    {
      id: "SINGLE_POST",
      get title() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useLocale("global")("post");
      },
      path: "/posts/:id",
      noNeedLogin: false,
      component: SinglePost,
    },

    {
      id: "PAGE_STAFF",
      get title() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useLocale("staff/list")("title");
      },
      path: "/staff",
      noNeedLogin: false,
      component: Staff,
      sidebarOptions: {
        get title() {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useLocale("staff/list")("sidebarTitle");
        },
        icon: TeamOutlined,
        order: 3,
      },
    },
    // {
    //   id: "PAGE_ROLES",
    //   title: "نقش ها",
    //   path: "/roles",
    //   noNeedLogin: false,
    //   component: Roles,
    //   sidebarOptions: {
    //     title: "نقش ها",
    //     icon: SolutionOutlined,
    //     order: 4,
    //   },
    // },
  ],

  groups: [
    // {
    //   id: "ANALYTIC_GROUP",
    //   sidebarOptions: {
    //     title: "آنالیز",
    //     icon: BarChartOutlined,
    //     order: 1,
    //     routes: [], //fill with filledGroupsWithRelatedRoutes function
    //   },
    // },
  ],

  get noNeedLoginRoutes() {
    return this.routes.filter((route) => route.noNeedLogin);
  },
  get panelRoutes() {
    return this.routes.filter((route) => !route.noNeedLogin);
  },
  get inSidebarRoutes() {
    return this.routes.filter((item) => item.sidebarOptions);
  },
  get filledGroupsWithRelatedRoutes() {
    return this.groups.map((item) => ({
      ...item,
      sidebarOptions: {
        ...item.sidebarOptions,
        routes: [
          ...this.inSidebarRoutes.filter(
            (itm) => itm.sidebarOptions.groupId === item.id
          ),
        ],
      },
    }));
  },
  get unGroupedRoutes() {
    return this.inSidebarRoutes.filter((item) => !item.sidebarOptions.groupId);
  },
  get sidebarItems() {
    return [...this.filledGroupsWithRelatedRoutes, ...this.unGroupedRoutes];
  },
};
