import { types, getParent } from "mobx-state-tree";
import { toJS } from "mobx";

const StaffModel = types
  .model("StaffModel", {
    key: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    actionLoading: types.optional(types.boolean, true),
    displayName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    age: types.maybeNull(types.number),
    gender: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    created: types.maybeNull(types.number),
  })
  .actions((self) => ({
    changeLoading(loading) {
      self.actionLoading = loading;
    },
    openUpdateModal() {
      const staffStore = getParent(self, 3);
      staffStore.setEditMode(true);
      staffStore.fillStaff(toJS(self));
      staffStore.toggleModal();
    },
  }))
  .preProcessSnapshot((sn) => {
    if (sn)
      return {
        ...sn,
        key: sn.id,
        created: sn.created && sn.created.seconds,
      };
  });

export default StaffModel;
