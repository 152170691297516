export default [
  "posts/list",
  "posts/form",
  "shops/form",
  "shops/list",
  "staff/list",
  "staff/form",
  "fields/form",
  "fields/list",
  "components",
  "global",
];
