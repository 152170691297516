import React from "react";
import { Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import "./Header.less";
import { HeaderLocales, HeaderProfile } from "..";

const { Header: AntHeader } = Layout;

const Header = ({ collapsed, toggleCollapse }) => {
  const renderTrigger = () => {
    const Icon = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
    const props = {
      className: "trigger",
      onClick: () => toggleCollapse(!collapsed),
    };
    return <Icon {...props} />;
  };

  return (
    <AntHeader className="header">
      {renderTrigger()}
      <div className="header__tools">
        <HeaderProfile />
        <HeaderLocales />
      </div>
    </AntHeader>
  );
};

export default Header;
