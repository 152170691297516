/* eslint-disable react-hooks/rules-of-hooks */
import { toJS } from "mobx";
import { applySnapshot, types } from "mobx-state-tree";

import { useStore } from "../../hooks";

const createTrackableModel = ({ type = "" }, model) =>
  types.compose(
    types
      .model({
        new: types.optional(types.boolean, false),
        removed: types.optional(types.boolean, false),
        updated: types.optional(types.boolean, false),
        firstData: types.frozen({}),
      })
      .views((self) => ({
        get changeType() {
          if (self.removed) {
            return "removed";
          } else if (self.new) {
            return "new";
          } else if (self.updated) {
            return "updated";
          }
          return null;
        },
      }))
      .actions((self) => ({
        remove() {
          self.removed = true;
        },
        editMode() {
          const { FieldsStore } = useStore();
          FieldsStore.toggleModal(type, toJS(self), true);
        },
        recovery() {
          if (self.changeType === "new") {
            self.removed = true;
          } else if (self.changeType === "removed") {
            self.removed = false;
          } else if (self.changeType === "updated") {
            self.update(self.firstData);
            self.updated = false;
          }
        },
        update(data) {
          let firstData = {};
          if (!Object.keys(self.firstData).length) {
            Object.keys(data).forEach((item) => {
              firstData[item] = self[item];
            });
          } else {
            firstData = toJS(self.firstData);
          }

          applySnapshot(self, {
            ...self,
            ...data,
            updated: true,
            firstData,
          });
        },
        addNewItemToChild(key, data) {
          if (self[key]) {
            self[key].push(data);
          }
        },
        sortData(oldIndex, newIndex, key) {
          const sortItem = JSON.parse(
            JSON.stringify(toJS(self[key][oldIndex]))
          );
          self[key].splice(oldIndex, 1);
          self[key].splice(newIndex, 0, sortItem);
        },
      })),
    model
  );

export const CategoryOptionModel = types.snapshotProcessor(
  createTrackableModel(
    { type: "options" },
    types.model("CategoryOptionModel", {
      optionID: types.maybeNull(types.number),
      title: types.maybeNull(types.string),
      optionValue: types.maybeNull(types.string),
      slug: types.maybeNull(types.string),
      parentID: types.maybeNull(types.number),
      optionOrder: types.maybeNull(types.number),
      seo: types.union(
        types.boolean,
        types.string,
        types.model({
          title: types.maybeNull(types.string),
          description: types.maybeNull(types.string),
          keywords: types.maybeNull(types.string),
        })
      ),
    })
  ),
  {
    preProcessor(snapshot) {
      if (snapshot)
        return {
          ...snapshot,
          seo: snapshot.seo || false,
          title: String(snapshot.title || snapshot.optionValue || ""),
          optionValue: snapshot.optionValue && String(snapshot.optionValue),
        };
    },
  }
);

export const CategoryAttributeModel = types.snapshotProcessor(
  createTrackableModel(
    { type: "attribute" },
    types.model("CategoryAttributeModel", {
      attributeID: types.maybeNull(types.number),
      name: types.maybeNull(types.string),
      attributeTitle: types.maybeNull(types.string),
      placeholder: types.maybeNull(types.string),
      attributeOrder: types.maybeNull(types.number),
      type: types.maybeNull(types.string),
      slug: types.maybeNull(types.string),
      attributeOptions: types.optional(types.array(CategoryOptionModel), []),
      isFilterable: types.optional(types.boolean, false),
      isSearchable: types.optional(types.boolean, false),
      isRange: types.optional(types.boolean, false),
      isChild: types.optional(types.boolean, false),
      parentID: types.maybeNull(types.number),
      required: types.optional(types.boolean, false),
      non_zero: types.optional(types.boolean, false),
      custom_validate_regex: types.maybeNull(types.string),
      custom_validate_regex_message: types.maybeNull(types.string),
      seoPattern: types.union(
        types.model({
          title: types.maybeNull(types.string),
          description: types.maybeNull(types.string),
          keywords: types.maybeNull(types.string),
        }),
        types.boolean
      ),
    })
  ),
  {
    preProcessor(snapshot) {
      if (snapshot) {
        return {
          ...snapshot,
          custom_validate_regex: snapshot.custom_validate_regex || "",
          custom_validate_regex_message:
            snapshot.custom_validate_regex_message || "",
          seoPattern: snapshot.seoPattern || false,
        };
      }
    },
  }
);

export const SubCategoryModel = types.snapshotProcessor(
  createTrackableModel(
    { type: "subCategory" },
    types.model("SubCategoryModel", {
      categoryID: types.maybeNull(types.number),
      categoryOrder: types.maybeNull(types.number),
      categoryTitle: types.maybeNull(types.string),
      iconURL: types.maybeNull(types.string),
      attributes: types.optional(types.array(CategoryAttributeModel), []),
      hide: types.optional(types.boolean, false),
      name: types.maybeNull(types.string),
      slug: types.maybeNull(types.string),
      hasPrice: types.maybeNull(types.boolean),
      hasRent: types.maybeNull(types.boolean),
      currencyOptions: types.optional(types.array(CategoryOptionModel), []),
      seo: types.union(
        types.string,
        types.model({
          title: types.maybeNull(types.string),
          description: types.maybeNull(types.string),
          keywords: types.maybeNull(types.string),
        })
      ),
    })
  ),
  {
    preProcessor(snapshot) {
      if (snapshot) {
        return {
          ...snapshot,
          hide: snapshot.hide || false,
        };
      }
    },
  }
);

export const CategoryModel = types.snapshotProcessor(
  createTrackableModel(
    { type: "category" },
    types.model("CategoryModel", {
      categoryID: types.maybeNull(types.number),
      categoryTitle: types.maybeNull(types.string),
      hide: types.optional(types.boolean, false),
      slug: types.maybeNull(types.string),
      iconURL: types.maybeNull(types.string),
      topCategory: types.maybeNull(types.boolean),
      subCategories: types.optional(types.array(SubCategoryModel), []),
      sortOptions: types.optional(types.array(CategoryOptionModel), []),
      attributes: types.optional(types.array(CategoryAttributeModel), []),
      seo: types.maybeNull(
        types.model({
          title: types.maybeNull(types.string),
          description: types.maybeNull(types.string),
          keywords: types.maybeNull(types.string),
        })
      ),
      seoPattern: types.union(
        types.model({
          title: types.maybeNull(types.string),
          description: types.maybeNull(types.string),
          keywords: types.maybeNull(types.string),
        }),
        types.boolean
      ),
    })
  ),
  {
    preProcessor(snapshot) {
      if (snapshot) {
        const injectedSubCategoriesWithSeoPattern = (
          snapshot.subCategories || []
        ).map((item) => {
          const usePattern = item.seo === "pattern";
          return { ...item, seo: usePattern ? snapshot.seo : item.seo };
        });
        return {
          ...snapshot,
          seoPattern: snapshot.seoPattern || false,
          hide: snapshot.hide || false,
          subCategories: injectedSubCategoriesWithSeoPattern,
        };
      }
    },
  }
);
