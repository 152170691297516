import React, { useState } from "react";
import { Table, Typography, Button, Row, Col, Input, Modal, Space } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Link } from "react-router-dom";
import { toJS } from "mobx";
import {
  MenuOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import arrayMove from "array-move";

import { useLocale, useStore } from "../../../hooks";

const { Text } = Typography;
const { Column } = Table;
const { Search } = Input;
const { confirm } = Modal;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const FieldsTable = ({
  data,
  sortData,
  columns,
  actions = [],
  buttonText,
  buttonAction = () => {},
  onDelete = () => false,
  exportable = false,
}) => {
  const t = useLocale("components");
  const { FieldsStore } = useStore();
  const [search, setSearch] = useState("");
  const handleDeleteRow = (item) => {
    confirm({
      title: t("fieldsTable.deleteModal.title"),
      okType: "danger",
      okText: t("fieldsTable.deleteModal.okText"),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("fieldsTable.deleteModal.cancelText"),
      maskClosable: true,
      cancelButtonProps: {
        type: "text",
      },
      onOk: () => {
        item.remove();
        onDelete(item);
      },
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      sortData(oldIndex, newIndex);
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="fields__row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const onSearch = (data) => {
    setSearch(data);
  };

  const createDownloadLink = (blob, name) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleExport = () => {
    const name = `fields.json`;
    const file = new Blob(
      [JSON.stringify(toJS(FieldsStore.exportableData), null, 2)],
      {
        type: "application/json",
      }
    );
    createDownloadLink(file, name);
  };

  return (
    <div className="FieldsTable">
      <Row
        justify="space-between"
        className="FieldsTable__header"
        align="center"
      >
        <Col>
          <Search
            placeholder={t("fieldsTable.searchField.placeholder")}
            onSearch={onSearch}
          />
        </Col>
        <Col>
          {exportable ? (
            <Button type="secondary" onClick={handleExport}>
              {t("fieldsTable.exportButton")}
            </Button>
          ) : (
            <Space>
              <Button type="secondary">
                <Link to="/fields/review">
                  {t("fieldsTable.changesButton")}
                </Link>
              </Button>
              <Button type="primary" onClick={buttonAction}>
                {buttonText}
              </Button>
            </Space>
          )}
        </Col>
      </Row>
      <Table
        pagination={false}
        dataSource={data
          .filter((item) => !item.removed)
          .filter((item) =>
            search
              ? item.title.includes(search) || item.key.includes(search)
              : item
          )}
        rowKey="index"
        scroll={{
          x:
            (columns || []).length * 200 +
            75 +
            ((actions || []).length + 2) * 110,
        }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      >
        {exportable ? (
          <React.Fragment>
            <Column
              key="title"
              title={t("fieldsTable.exportTable.headings.title")}
              dataIndex="title"
              render={(item, record) => (
                <Text key={record.key} className="table__title">
                  <span className={`table__title-type ${record.type}`}></span>
                  {item}
                </Text>
              )}
            />
            <Column
              title={t("fieldsTable.exportTable.headings.functions")}
              dataIndex="actions"
              key="actions"
              className="column-action"
              render={(_, record) => (
                <div key={record.key} className="action-wrapper">
                  <Button type="text" onClick={record.item.recovery}>
                    <Text className="call-to-action">
                      <ReloadOutlined />
                      <span>
                        {t("fieldsTable.exportTable.fields.undoButton")}
                      </span>
                    </Text>
                  </Button>
                </div>
              )}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Column
              title=""
              dataIndex="sort"
              key="sort"
              width={25}
              className="drag-visible"
              render={() => <DragHandle />}
            />
            {columns.map((item) => (
              <Column key={item.dataIndex} {...item} />
            ))}
            <Column
              title={t("fieldsTable.staticHeadings.functions")}
              dataIndex="actions"
              width={(actions.length * 1 + 2) * 110}
              key="actions"
              className="column-action"
              render={(_, data) => (
                <div className="action-wrapper">
                  {[
                    ...actions,
                    {
                      title: t("fieldsTable.staticFields.delete"),
                      icon: <DeleteOutlined />,
                      onClick: () => handleDeleteRow(data),
                    },
                    {
                      title: t("fieldsTable.staticFields.edit"),
                      icon: <EditOutlined />,
                      onClick: data.editMode,
                    },
                  ].map((item, index) => (
                    <Button
                      type="text"
                      key={index}
                      onClick={() => item.onClick(data)}
                    >
                      <Text className="call-to-action">
                        {item.icon}
                        <span>{item.title}</span>
                      </Text>
                    </Button>
                  ))}
                </div>
              )}
            />
          </React.Fragment>
        )}
      </Table>
    </div>
  );
};

export default FieldsTable;
