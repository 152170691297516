import React, { useState } from "react";
import { UploadButton } from "../../../../../components/private";
import classNames from "classnames";
import {
  LoadingOutlined,
  ReloadOutlined,
  WarningOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "./SingleUploader.less";
import { Button } from "antd";
import { ErrorMessage } from "formik";

const SingleUploader = ({
  upload,
  preview,
  placeholder,
  setFormikField,
  hasFormikError,
  name,
}) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [file, setFile] = useState(null);
  const singleUploaderClassName = classNames("singleUploader", {
    "-hasFormikError": hasFormikError,
  });

  const handleUpload = ({ target: { files } }) => {
    setFile({ target: { files } });
    setError(false);
    setLoading(true);

    return upload(files[0])
      .then((url) => {
        setFormikField(name, url);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFormikField(name, "");
  };

  return (
    <div className={singleUploaderClassName}>
      {loading ? (
        <div className="loading">
          <div>
            <LoadingOutlined spin />
            <span>در حال آپلود...</span>
          </div>
        </div>
      ) : hasError ? (
        <div className="error">
          <div>
            <WarningOutlined />
            <span>آپلود شما با خطا مواجه شده است</span>
          </div>
          <Button
            shape="round"
            icon={<ReloadOutlined />}
            onClick={() => handleUpload(file)}
          >
            تلاش مجدد
          </Button>
        </div>
      ) : (
        <UploadButton
          placeholder={placeholder}
          upload={handleUpload}
          preview={preview}
          renderPreview={() => (
            <div className="previewBox">
              <img className="previewBox__image" src={preview} alt="preview" />
              <button type="button" className="previewBox__closeBtn">
                <CloseOutlined onClick={removeImage} />
              </button>
            </div>
          )}
          multiple={false}
        />
      )}
      <ErrorMessage name={name}>
        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default SingleUploader;
