import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Modal, Row, Col, Typography, Button, Divider } from "antd";
import { Form } from "formik-antd";
import { Formik } from "formik";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

import { useLocale, useStore } from "../../../hooks";
import { FIELDS } from "../../../constants";
import { FormField } from "../../../components/private";
import { toJS } from "mobx";
import SeoFieldsGenerator from "./SeoFieldsGenerator";

const { Text } = Typography;

const AttributeModal = ({ data, title }) => {
  const t = useLocale("fields/form");

  const { FieldsStore } = useStore();
  const { search } = useLocation();
  const { category: categoryId } = queryString.parse(search);
  const params = useParams();
  const {
    modal,
    editableData: { attribute },
    toggleModal,
    editMode,
  } = FieldsStore;
  const isAttribute = modal === "attribute";

  const [formValues, setFormValues] = useState(
    FIELDS.attribute.defaultFormValues
  );
  const [isInitialValid, setIsInitialValid] = useState(false);
  const schema = FIELDS.attribute.formValidation;

  useEffect(() => {
    schema
      .validate(formValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    if (isAttribute) {
      const seoPattern = editMode
        ? toJS(attribute.seoPattern)
        : FIELDS.defaultSEO;

      setFormValues({
        seoPattern,
        attributeTitle: attribute.attributeTitle,
        slug: attribute.slug,
        attributeOrder: attribute.attributeOrder,
        isFilterable: attribute.isFilterable,
        isSearchable: attribute.isSearchable,
        custom_validate_regex: attribute.custom_validate_regex,
        custom_validate_regex_message: attribute.custom_validate_regex_message,
        isRange: attribute.isRange,
        type: attribute.type,
        required: attribute.required,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAttribute, attribute]);

  const onRequestClose = () => {
    toggleModal("attribute", FIELDS.attribute.defaultFormValues);
    setFormValues(FIELDS.attribute.defaultFormValues);
  };

  const handleSubmit = (form) => {
    if (editMode) {
      const item = data.find(
        (item) => item.key === String(attribute.attributeID)
      );
      item && item.update(form);
    } else {
      FieldsStore.createModel({
        form,
        categoryId: categoryId || params.id,
        subCategoryId: categoryId ? params.id : categoryId,
        type: "attribute",
      });
    }
    onRequestClose();
  };

  return (
    <Modal
      title={title}
      className="attribute-modal"
      visible={isAttribute}
      onCancel={onRequestClose}
      destroyOnClose
      footer={null}
      maskClosable={true}
      width={900}
    >
      <Formik
        initialValues={formValues}
        enableReinitialize
        validationSchema={schema}
        isInitialValid={isInitialValid}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form layout="vertical" size="large">
            <Text>{t("attributes.formTitle")}</Text>
            <Row
              justify="start"
              align="middle"
              gutter={[48, 0]}
              style={{ marginTop: 25 }}
            >
              <Col span={24}>
                <Row>
                  <Col span={22}>
                    <Row>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="attributeTitle"
                          placeholder={t("attributes.fields.title.placeholder")}
                        />
                      </Col>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="slug"
                          placeholder={t("attributes.fields.slug.placeholder")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <Row>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="type"
                          placeholder={t("attributes.fields.type.placeholder")}
                        />
                      </Col>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="custom_validate_regex"
                          placeholder={t(
                            "attributes.fields.customValidateRegex.placeholder"
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <Row>
                      <Col span={24}>
                        <FormField
                          placeholder={t(
                            "attributes.fields.customValidateRegexMessage.placeholder"
                          )}
                          name="custom_validate_regex_message"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <Row>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="isFilterable"
                          placeholder={t(
                            "attributes.fields.isFilterable.placeholder"
                          )}
                          type="switch"
                        />
                      </Col>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="isRange"
                          placeholder={t(
                            "attributes.fields.isRange.placeholder"
                          )}
                          type="switch"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <Row>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="isSearchable"
                          placeholder={t(
                            "attributes.fields.isSearchable.placeholder"
                          )}
                          type="switch"
                        />
                      </Col>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="required"
                          placeholder={t(
                            "attributes.fields.required.placeholder"
                          )}
                          type="switch"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <SeoFieldsGenerator
                  config={FIELDS.attribute.seoConfig}
                  formik={formik}
                  formValues={formValues}
                />
              </Col>
            </Row>

            <Button
              htmlType="submit"
              style={{ marginLeft: 14 }}
              onClick={formik.handleSubmit}
              type="primary"
              size="middle"
            >
              {t(`attributes.buttons.${editMode ? "edit" : "add"}`)}
            </Button>

            <Button
              htmlType="button"
              size="middle"
              style={{ fontSize: 14 }}
              onClick={onRequestClose}
              type="default"
            >
              {t(`attributes.buttons.cancel`)}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default observer(AttributeModal);
