import React, { useState, useEffect } from "react";
import { Row } from "antd";
import { Select } from "formik-antd";

import { FormFieldWrapper } from "../../../../components/private";
import { useLocale, useMobile, useStore } from "../../../../hooks";
const { Option } = Select;

const CatFields = React.memo(({ setFormikField, defaultCategory }) => {
  const isSmallDevice = useMobile(true);
  const { GlobalStore, PostStore } = useStore();
  const { categoriesOptions, subCategoriesOptions } = GlobalStore;
  const [subCatOptions, setSubCatOptions] = useState([
    ...subCategoriesOptions(defaultCategory),
  ]);
  const { changeData } = PostStore.post;
  const t = useLocale("shops/form");
  const handleChangeSubCat = (data) => {
    changeData("subCategories", data);
  };

  const handleChangeCat = (data) => {
    changeData("category", data);
    changeData("subCategories", null);
    setFormikField("subCategories", null);
    setSubCatOptions(subCategoriesOptions(data));
  };

  return (
    <Row>
      <FormFieldWrapper
        span={isSmallDevice ? 24 : 12}
        name="category"
        placeholder={t("fields.category.placeholder")}
      >
        <Select name="category" onChange={handleChangeCat}>
          {categoriesOptions.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </FormFieldWrapper>

      <FormFieldWrapper
        span={isSmallDevice ? 24 : 12}
        name="subCategories"
        placeholder={t("fields.subCategories.placeholder")}
      >
        <Select
          mode="multiple"
          allowClear
          name="subCategories"
          onChange={handleChangeSubCat}
        >
          {subCatOptions.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </FormFieldWrapper>
    </Row>
  );
});

export default CatFields;
