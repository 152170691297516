import React from "react";
import { Row } from "antd";
import { Input, Select } from "formik-antd";

import { FormFieldWrapper } from "../../../../components/private";
import { useLocale, useMobile } from "../../../../hooks";

const { Option } = Select;

const PriceFields = React.memo(({ hasPrice, currencyOptions }) => {
  const isSmallDevice = useMobile(true);
  const name = hasPrice ? "price" : "rent";
  const options = currencyOptions.map((item) => ({
    value: item.optionID.toString(),
    label: item.title,
  }));
  const t = useLocale("posts/form");

  return (
    <Row justify="start" align="middle" gutter={[48, 0]}>
      <FormFieldWrapper
        span={isSmallDevice ? 24 : 12}
        name={name}
        placeholder={t("fields.price.placeholder")}
      >
        <Input name={name} />
      </FormFieldWrapper>
      <FormFieldWrapper
        span={isSmallDevice ? 24 : 12}
        name="currency"
        placeholder={t("fields.currency.placeholder")}
      >
        <Select name="currency">
          {options.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </FormFieldWrapper>
    </Row>
  );
});

export default PriceFields;
