import { types, destroy, flow, applySnapshot } from "mobx-state-tree";
import { toJS } from "mobx";
import { api, FilterModel } from "./../utils";
import utils from "./utils";

let paginate;
const createListStore = ({
  itemModel,
  methods = {
    method: "",
    initial: "",
  },
  pageSize = 20,
  sort,
}) =>
  types
    .compose(
      types.model({
        data: types.optional(types.array(itemModel), []),
        selectedKeys: types.optional(types.array(types.string), []),

        //customize data
        sort: types.optional(types.maybeNull(types.string), sort),

        //pagination
        pageSize: types.optional(types.number, pageSize),
        page: types.optional(types.number, 1),
        isLastPage: types.optional(types.boolean, false),
        isFirstPage: types.optional(types.boolean, true),
        type: types.optional(
          types.enumeration("type", ["initial", "next", "prev"]),
          "initial"
        ),

        //loadings
        fetchLoading: types.optional(types.boolean, true),
      }),
      FilterModel
    )
    .views((self) => ({
      get list() {
        return toJS(self.data);
      },
      get selection() {
        return self.list.filter((item) => self.selectedKeys.includes(item.id));
      },
    }))
    .actions((self) => ({
      changeLoading(loading) {
        self.fetchLoading = loading;
      },
      select(keys) {
        self.selectedKeys = keys;
      },
      fetch: flow(function* () {
        if (self.type === "initial") {
          self.isLastPage = false;
          paginate = null;
          self.data = [];
        }

        if (self.type !== "initial")
          if (self.isLastPage || self.fetchLoading) return false;

        self.changeLoading(true);

        try {
          const { method, initial } = methods;
          if (paginate) self.isFirstPage = false;

          const { data, cursor } = yield api.call(method, initial, [
            {
              pagination: {
                pageSize: self.pageSize,
                cursor: paginate,
                type: self.type,
              },
              filter: { ...self.filter.toJSON() },
              sort: { by: self.sort, order: "desc" },
            },
          ]);

          console.log({ fetchedData: data });

          if (data.length) {
            applySnapshot(self.data, [...data]);
            paginate = cursor;
          } else self.isLastPage = true;
        } catch {
          self.isLastPage = true;
        } finally {
          self.changeLoading(false);
        }
      }),
      next() {
        self.type = "next";
        self.fetch().then(() => {
          !self.isLastPage && self.changePage(self.page + 1);
        });
      },
      prev() {
        self.type = "prev";
        self.fetch().then(() => {
          self.changePage(self.page - 1);
        });
      },
      fetchInitial() {
        self.type = "initial";
        self.page = 1;
        self.fetch();
      },

      batchDelete: flow(function* () {
        const { method } = methods;

        try {
          yield api.call(method, "batchDelete", [self.selectedKeys]);
          self.selection.forEach((item) => item.kill());
          self.selectedKeys = [];
        } catch {}
      }),

      deleteItem(model) {
        destroy(model);
      },
      changePage(page) {
        self.page = page;
      },
    }));

export default createListStore;
