import React from "react";
import { observer } from "mobx-react";
import { Row, Space, Typography } from "antd";

import { useLocale, useMobile, useStore } from "../../../../hooks";
import { FormFieldWrapper } from "../../../../components/private";
import { PhotoList } from "../../../../components/shared";

const { Text } = Typography;

const ImagesField = React.memo(({ setFormikField }) => {
  const isSmallDevice = useMobile(true);
  const { PostStore } = useStore();
  const { photos, setMainImage, upload, hasSomeFaildUploads } = PostStore.post;
  const t = useLocale("posts/form");

  const hasPhoto = photos.length > 0;

  return (
    <Row justify="space-between" align="middle">
      <FormFieldWrapper
        span={24}
        name="images"
        placeholder={t("fields.images.placeholder")}
      >
        {hasPhoto && (
          <Space direction={isSmallDevice ? "vertical" : "horizontal"}>
            <Text type="warning">
              <small>{t("fields.images.selectMainImageDescription")}</small>
            </Text>
            <Text type="secondary">
              <small>{t("fields.images.viewImageDescription")}</small>
            </Text>
            {hasSomeFaildUploads && (
              <Text type="danger">
                <small>{t("fields.images.failedImageDescription")}</small>
              </Text>
            )}
          </Space>
        )}
        <PhotoList
          editable
          photos={photos}
          upload={upload}
          setMainImage={setMainImage}
          onChange={(data) => {
            setFormikField("images", data);
          }}
        />
      </FormFieldWrapper>
    </Row>
  );
});

export default ImagesField;
