import { get } from "mobx";
import * as yup from "yup";
import { useLocale } from "../hooks";

const err = (field, step = "information") => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useLocale("staff/form")(`steps.${step}.fields.${field}.error`);
};

const TSL = (ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useLocale("staff/list")(ref);
};

const TSF = (ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useLocale("staff/form")(ref);
};

const TSN = (ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useLocale("staff/form")(`notifications.${ref}`);
};

export default {
  pageSize: 5,

  statuses: [
    {
      value: "Active",
      get text() {
        return TSL("table.filters.status.active");
      },
      color: "green",
    },
    {
      value: "Deactive",
      get text() {
        return TSL("table.filters.status.deActive");
      },
      color: "red",
    },
    {
      value: "Stopped",
      get text() {
        return TSL("table.filters.status.stopped");
      },
      color: "blue",
    },
  ],
  userTypes: [
    {
      value: "user",
      get text() {
        return TSL("table.fields.role.user");
      },
    },
    {
      value: "admin",
      get text() {
        return TSL("table.fields.role.admin");
      },
    },
    {
      value: "marketing",
      get text() {
        return TSL("table.fields.role.marketing");
      },
    },
    {
      value: "shop",
      get text() {
        return TSL("table.fields.role.shop");
      },
    },
    {
      value: "post",
      get text() {
        return TSL("table.fields.role.post");
      },
    },
    {
      value: "category",
      get text() {
        return TSL("table.fields.role.category");
      },
    },
  ],
  informationValidation: () =>
    yup.object().shape({
      displayName: yup.string().required(err("displayName")),
      age: yup.number().typeError(err("age")),
      email: yup.string().email().required(err("email")),
      gender: yup.string(),
      phoneNumber: yup.string().required(err("phoneNumber")),
      address: yup.string(),
    }),
  roleDefinitionValidation: () =>
    yup.object().shape({
      role: yup.string().required(err("role", "roleDefinition")),
    }),
  userDefinitionValidation: yup.object().shape({
    id: yup.string().required(err("id", "findUser")),
  }),
  defaultFormValues: {
    information: {
      displayName: "",
      email: "",
      gender: "",
      age: null,
      phoneNumber: "",
      address: "",
    },
    role: {
      role: "",
    },
    user: {
      id: "",
    },
  },
  addStaffResultError: {
    get title() {
      return TSF("steps.result.failed.add.title");
    },
    get description() {
      return TSF("steps.result.failed.add.description");
    },

    status: "error",
  },
  addStaffResultSuccess: {
    get title() {
      return TSF("steps.result.success.add.title");
    },
    get description() {
      return TSF("steps.result.success.add.description");
    },
    status: "success",
  },
  addStaffNotificationError: {
    get message() {
      return TSN("add.failed.message");
    },
    duration: 4,
    get description() {
      return TSN("add.failed.description");
    },
  },
  addStaffNotificationSuccess: {
    get message() {
      return TSN("add.success.message");
    },
    duration: 4,
  },
  editStaffResultError: {
    get title() {
      return TSF("steps.result.failed.edit.title");
    },
    get description() {
      return TSF("steps.result.failed.edit.description");
    },
    status: "error",
  },
  editStaffResultSuccess: {
    get title() {
      return TSF("steps.result.success.edit.title");
    },
    get description() {
      return TSF("steps.result.success.edit.description");
    },

    status: "success",
  },
  editStaffNotificationError: {
    get message() {
      return TSN("edit.failed.message");
    },
    duration: 4,
    get description() {
      return TSN("edit.failed.description");
    },
  },
  editStaffNotificationSuccess: {
    get message() {
      return TSN("edit.success.message");
    },
    duration: 4,
  },
};
