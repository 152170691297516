import React from "react";
import { Row } from "antd";
import { Input } from "formik-antd";
import { FormFieldWrapper } from "../../../../components/private";
import { useLocale } from "../../../../hooks";

const TitleField = React.memo(() => {
  const t = useLocale("posts/form");

  return (
    <Row justify="start" align="middle" gutter={[48, 0]}>
      <FormFieldWrapper
        name="title"
        placeholder={t("fields.title.placeholder")}
      >
        <Input name="title" />
      </FormFieldWrapper>
    </Row>
  );
});

export default TitleField;
