import React from "react";
import { observer } from "mobx-react";
import { Row, Button, Col, Alert } from "antd";
import { useHistory } from "react-router-dom";

import { useLocale, useStore } from "../../../../../hooks";
import { Actions } from "../../../../../components/private";

const FormButtons = React.memo(
  observer(({ newShopMode }) => {
    const { push } = useHistory();
    const { ShopStore } = useStore();
    const { getAction, getAllowedActions, shop } = ShopStore;
    const t = useLocale("shops/form");
    const tGlobal = useLocale("global");

    const {
      isReported,
      getStatusType,
      updateAndCreateLoading,
      uploadLoading,
    } = shop;

    const renderAlertProps = () => {
      if (isReported)
        return {
          message: t("status.reported"),
          type: "warning",
        };

      switch (getStatusType()) {
        case "ACCEPTED":
          return {
            message: t("status.accepted"),
            type: "success",
          };

        case "REJECTED":
          return {
            message: t("status.rejected"),
            type: "warning",
          };

        case "NOT_VERIFIED":
          return {
            message: t("status.notVerified"),
            type: "info",
          };

        case "REPORTED":
          return {
            message: t("status.reported"),
            type: "info",
          };

        case "REMOVED":
          return {
            message: t("status.removed"),
            type: "error",
          };

        default:
          break;
      }
    };
    return (
      <Row justify="space-between" align="middle" gutter={[48, 0]}>
        <Col>
          <Button
            htmlType="submit"
            style={{ fontSize: 14 }}
            disabled={false}
            type="primary"
            loading={updateAndCreateLoading || uploadLoading}
          >
            {newShopMode ? t("create.submitButton") : t("update.submitButton")}
          </Button>
        </Col>
        {!newShopMode && (
          <Col style={{ display: "flex" }}>
            <Alert
              {...renderAlertProps()}
              showIcon
              style={{ marginLeft: 15 }}
            />
            <Actions
              type="singlePage"
              indicator={tGlobal("shop")}
              record={ShopStore.shop}
              getAction={getAction}
              status={isReported ? "REPORTED" : getStatusType()}
              getAllowedActions={getAllowedActions}
              removeCallBack={() => {
                push("/shops");
              }}
            />
          </Col>
        )}
      </Row>
    );
  })
);

export default FormButtons;
