import { types, getParent } from "mobx-state-tree";
import { useStore } from "../../../hooks";
import { api } from "../../../utils";

export const ShopItemModelUnprocessed = types
  .compose(
    "ShopItemModel",
    types.model({
      id: types.maybeNull(types.string),
      key: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      logo: types.maybeNull(types.string),
      phoneNumber: types.maybeNull(types.string),
      shopNumber: types.maybeNull(types.string),
      created: types.maybeNull(types.number),
    }),
    types.model({
      actionLoading: types.optional(types.boolean, false),
    })
  )
  .actions((self) => {
    const { ShopStore } = useStore();

    const updateStatus = (status) => {
      self.loadingOn();

      return api
        .call("Shops", "updateStatus", [self.id, ShopStore.getStatus(status)])
        .then(self.kill)
        .finally(self.loadingOff);
    };

    return {
      loadingOff() {
        self.actionLoading = false;
      },
      loadingOn() {
        self.actionLoading = true;
      },

      delete() {
        self.loadingOn();

        return api
          .call("Shops", "removeShop", [self.id])
          .then(self.kill)
          .finally(self.loadingOff);
      },

      accept: () => updateStatus("ACCEPTED"),
      reject: () => updateStatus("REJECTED"),
      unverify: () => updateStatus("NOT_VERIFIED"),
      kill: () => {
        getParent(self, 2).deleteItem(self);
      },
    };
  });

export const ShopItemModel = types.snapshotProcessor(ShopItemModelUnprocessed, {
  preProcessor(sn) {
    return {
      ...sn,
      key: sn.id,
      created: sn.created && sn.created.seconds && sn.created.seconds,
    };
  },
});
