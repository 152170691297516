import * as yup from "yup";

export default {
  validation: yup.object().shape({
    phone: yup
      .string()
      .matches(/^([0-9]{10})+$/, "شماره تلفن را صحیح وارد کنید")
      .required("وارد کردن شماره تلفن الزامی است"),
    phoneRegion: yup
      .string()
      .matches(/^(\+?\d{1,3}|\d{1,4})$/, "فرمت اشتباه")
      .required("ورود پسورد الزامی است"),
  }),
  defaultValues: {
    phoneRegion: "+964",
    phone: "",
    otp: "",
  },
};
