import React from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import { useStore } from "../hooks";

const authentication = (Component) =>
  observer((compProps) => {
    const { AuthStore } = useStore();
    const { isAuthenticated, loading, fetchingProfile } = AuthStore;

    if (!(loading || fetchingProfile) && !isAuthenticated)
      return <Redirect to="/login" />;
    return <Component />;
  });


export default authentication;
