import React, { useEffect } from "react";
import { Tabs, Button, Space } from "antd";
import { observer } from "mobx-react";
import {
  SyncOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";

import "./Posts.less";
import pageWrapper from "../../utils/pageWrapper";
import POSTS from "../../constants/POSTS";
import { PostTable } from "./components";
import { useLocale, useMobile } from "../../hooks";
import { ActionDropMenu } from "../../components/private";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

const PostsPage = ({ useStore }) => {
  const { PostStore, GlobalStore } = useStore;
  const t = useLocale("posts/list");
  const isMobile = useMobile();
  const {
    activeTab,
    changeTab,
    postList,
    getAllowedActions,
    getAction,
  } = PostStore;

  const { list, fetchLoading, select, next, prev, page, fetch } = postList;

  const { fullScreenMode, setFullScreenMode } = GlobalStore;

  useEffect(() => {
    changeTab("ACCEPTED");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const operations = (
    <Space className="operations">
      <ActionDropMenu
        title={t("batchActions.title")}
        data={getAllowedActions({ ignore: null, isSingleAction: false })}
        model={postList}
        badge={postList.selectedKeys.length}
      />
      <Button
        icon={<SyncOutlined spin={fetchLoading} />}
        type={fetchLoading ? "primary" : "ghost"}
        shape="circle"
        onClick={fetch}
      />

      {!isMobile && (
        <Button
          icon={
            fullScreenMode ? <FullscreenExitOutlined /> : <FullscreenOutlined />
          }
          type={fullScreenMode ? "primary" : "ghost"}
          shape="circle"
          onClick={() => setFullScreenMode(!fullScreenMode, !isMobile)}
        />
      )}
    </Space>
  );

  return (
    <div className="posts">
      <Tabs
        defaultActiveKey={activeTab}
        onChange={changeTab}
        size="small"
        className="posts__tabs"
        tabBarExtraContent={operations}
      >
        {POSTS.tabs.map((item) => (
          <TabPane tab={item.title} key={item.key}>
            <PostTable
              loading={fetchLoading}
              data={list}
              page={page}
              next={next}
              prev={prev}
              activeTab={activeTab}
              select={select}
              getAllowedActions={getAllowedActions}
              getAction={getAction}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

const AddPostButton = () => {
  const t = useLocale("posts/list");
  return (
    <Link to="/posts/new">
      <Button type={"primary"}>{t("addPostButtonTitle")}</Button>
    </Link>
  );
};

const Posts = pageWrapper(observer(PostsPage), {
  hasHeader: true,
  get subTitle() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("posts/list")("description");
  },
  customHeaderButtons: [<AddPostButton />],
});

export default Posts;
