import React, { useEffect, useState } from "react";
import { Button, Input, Tag, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./TagInput.less";

const TagInput = ({ onChange, value = "" }) => {
  const [tags, setTags] = useState(value ? value.split(",") : []);
  const [inputVal, setValue] = useState("");

  useEffect(() => {
    onChange(tags);
  }, [tags]);

  useEffect(() => {
    setTags(value ? value.split(",") : []);
  }, [value]);

  const handlePressEnter = (e) => {
    e.preventDefault();
    if (inputVal) {
      setTags([...tags, inputVal]);
      setValue("");
    }
  };

  const suffix = (
    <Button
      shape="circle"
      type="primary"
      onClick={handlePressEnter}
      icon={<PlusOutlined />}
    ></Button>
  );

  return (
    <div className="tagInput">
      <Input
        value={inputVal}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={handlePressEnter}
        suffix={suffix}
      />

      <div className="tag-list">
        {tags.map((tag) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              className="edit-tag"
              key={tag}
              closable
              onClose={() => {
                setTags(tags.filter((item) => item !== tag));
              }}
            >
              <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
            </Tag>
          );

          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      </div>
    </div>
  );
};
export default TagInput;
