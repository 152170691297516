import { toJS } from "mobx";
import {
  types,
  getParent,
  destroy,
  flow,
  applySnapshot,
} from "mobx-state-tree";
import shortid from "shortid";
import { api } from "../../../utils";

const ImageModel = types
  .model({
    uid: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    thumb: types.maybeNull(types.string),
    thumb64: types.maybeNull(types.string),
    origin: types.maybeNull(types.string),
    preview: types.maybeNull(types.string),

    isMainImage: types.optional(types.boolean, false),
    removing: types.optional(types.boolean, false),
    uploading: types.optional(types.boolean, false),
    canRemove: types.optional(types.boolean, true),
    uploadStatus: types.optional(
      types.maybe(types.enumeration(["success", "faild"])),
      "success"
    ),
    file: types.frozen({}),
  })
  .actions((self) => {
    return {
      apply: (key, value) => {
        self[key] = value;
      },

      remove: () => {
        self.apply("removing", true);

        setTimeout(() => {
          if (self.canRemove) {
            getParent(self, 2).removePhoto(self);
          }
          self.apply("removing", false);
        }, 3000);
      },

      reUpload: () => {
        getParent(self, 2).upload({
          target: { files: [self.file] },
          uid: self.uid,
        });
      },

      preventDeletion: () => {
        self.canRemove = false;
        self.removing = false;
      },
    };
  });

export const PostImagesModel = types
  .model({
    images: types.optional(types.array(ImageModel), []),
  })
  .views((self) => ({
    get photos() {
      return self.images.map((img) => ({
        ...img,
        remove: img.remove,
        reUpload: img.reUpload,
        preventDeletion: img.preventDeletion,
      }));
    },
    get hasSomeFaildUploads() {
      return self.images.some((img) => img.uploadStatus === "faild");
    },
  }))
  .actions((self) => {
    let uploadQueue = [];

    return {
      removePhoto: (childModel) => {
        destroy(childModel);
      },
      uploadProcess: flow(function* () {
        if (!uploadQueue.length) return false;
        const { file, model } = uploadQueue[0];

        try {
          const { data } = yield api.call("Post", "addImage", [self.id, file]);
          self.modifyImages({
            ...model,
            ...data,
            name: data.fileName,
            uid: model.uid,
            uploading: false,
          });
        } catch {
          self.modifyImages({ ...model, uploading: false }, true);
        } finally {
          uploadQueue = uploadQueue.filter(
            (item) => item.model.uid !== model.uid
          );

          self.uploadProcess();
        }
      }),
      upload: ({ target: { files: images }, uid: oldUid }) => {
        let counter = 0;
        [...images].forEach((img) => {
          let reader = new FileReader();
          reader.onload = ({ target: e }) => {
            counter++;
            const uid = oldUid || shortid.generate();
            const newImage = ImageModel.create({
              uid,
              uploading: true,
              thumb64: e.result,
              file: img,
            });
            self.modifyImages(newImage);
            uploadQueue.push({
              file: img,
              model: newImage,
            });
            if (counter === images.length) {
              self.uploadProcess();
            }
          };
          reader.readAsDataURL(img);
        });
      },
      setMainImage: (id) => {
        const newImages = self.images.map((item) => ({
          ...item,
          isMainImage: item.uid === id,
        }));
        applySnapshot(self.images, [...newImages]);
      },
      modifyImages: (incomingImage, faild = false) => {
        const isDuplicated = self.images.some(
          (item) => item.uid === incomingImage.uid
        );

        if (isDuplicated) {
          const foundedIdx = self.images.findIndex(
            ({ uid }) => uid === incomingImage.uid
          );

          if (foundedIdx >= 0) {
            self.images[foundedIdx] = {
              ...incomingImage,
              thumb64: self.images[foundedIdx]["thumb64"],
              uploadStatus: faild ? "faild" : "success",
            };
          }
        } else applySnapshot(self.images, [...self.images, incomingImage]);
      },
    };
  })
  .preProcessSnapshot((sn) => {
    if (sn)
      return {
        ...sn,
        images: (sn.images || []).map((item, idx) => ({
          ...item,
          uid: shortid.generate(),
          isMainImage: !idx,
          thumb64: item.thumb,
        })),
      };
  });
