import React, { useEffect, useRef, useState } from "react";
import { Form } from "formik-antd";
import { observer } from "mobx-react";
import { Formik, replace } from "formik";
import { Row, Col, Spin, Input, notification, Switch, Collapse } from "antd";
import { ShopOutlined, LeftOutlined, LoadingOutlined } from "@ant-design/icons";

import "./Posts.less";
import pageWrapper from "../../utils/pageWrapper";
import { useLocale, useStore } from "../../hooks";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import {
  BodyField,
  CatFields,
  CityField,
  DynamicFields,
  FormButtons,
  ImagesField,
  PhoneField,
  PriceFields,
  TitleField,
  VipField,
} from "./components/formFields";
import { POSTS } from "../../constants";
import { ShopStore } from "../../stores";
const { Search } = Input;
const { Panel } = Collapse;

const SinglePost = () => {
  const { push } = useHistory();
  const { PostStore, GlobalStore } = useStore();
  const { id } = useParams();

  const formikRef = useRef(null);
  const {
    getValues,
    subCategory,
    category,
    generate,
    kill,
    renew,
    editPost,
    addPost,
    fetch,
    attributes,
    fetchLoading,
  } = PostStore.post;

  const [isInitialValid, setIsInitialValid] = useState(false);

  const newPostMode = id === "new";

  const formInitialValues = getValues(newPostMode);
  const subCat = GlobalStore.getSubCat(category, subCategory) || {};
  const { hasPrice, hasRent, currencyOptions } = subCat;
  const shouldRenderPriceField = hasPrice || hasRent;
  const shouldRenderDynamicFields = attributes.length > 0;
  const schema = POSTS.createPostValidation(newPostMode);
  const t = useLocale("posts/form");

  const loading = newPostMode ? false : fetchLoading;

  useEffect(() => {
    newPostMode ? generate() : fetch(id);
    return () => kill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    schema
      .validate(formInitialValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialValues]);

  const handleSubmitForm = (form) => {
    const submitFunction = newPostMode ? addPost : editPost;
    submitFunction(
      form,
      (id) => {
        let message = t("create.notification.success");
        if (newPostMode) push(`/posts`);
        else message = t("update.notification.success");
        notification.success({ message });
      },
      () => {
        let message = t("update.notification.success");
        if (!newPostMode) message = t("update.notification.failed");
        notification.error({ message });
      }
    );
  };

  const setFormikField = (key, value) => {
    formikRef.current.setFieldValue(key, value);
  };

  if (loading) return <FormLoading />;

  console.log({ formInitialValues });

  return (
    <div className="singlePost">
      <ShopDetails newPostMode={newPostMode} />
      <div className="container">
        <Formik
          initialValues={formInitialValues}
          onSubmit={handleSubmitForm}
          innerRef={formikRef}
          validationSchema={schema}
          isInitialValid={isInitialValid}
        >
          {(formik) => (
            <Form layout="vertical" size="large">
              <Row justify="start" align="middle">
                <Col span={24}>
                <VipField />
                  <Row>
                    {newPostMode && <PhoneField />}
                    <CityField />
                  </Row>
                  <ImagesField setFormikField={setFormikField} />
                  <CatFields
                    defaultCategory={category}
                    defaultSubCat={subCategory}
                    setFormikField={setFormikField}
                  />

                  {shouldRenderDynamicFields && (
                    <DynamicFields
                      specialAttributes={formik.values.specialAttributes}
                      setFormikField={setFormikField}
                    />
                  )}

                  {shouldRenderPriceField && (
                    <PriceFields
                      hasPrice={hasPrice}
                      currencyOptions={currencyOptions}
                    />
                  )}
                  <TitleField />
                  <BodyField />
                </Col>
              </Row>
              <FormButtons
                onClick={() => console.log(formik)}
                newPostMode={newPostMode}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const FormLoading = () => {
  return (
    <div className="singlePost__loading">
      <Spin size="large" />
    </div>
  );
};

const Posts = pageWrapper(observer(SinglePost), {
  hasHeader: true,
  get subTitle() {
    const pageType =
      window.location.pathname.split("/posts/")[1] === "new"
        ? "create"
        : "update";

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("posts/form")(`${pageType}.description`);
  },
  get title() {
    const pageType =
      window.location.pathname.split("/posts/")[1] === "new"
        ? "create"
        : "update";

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("posts/form")(`${pageType}.title`);
  },
});

const ShopDetails = React.memo(
  observer(({ newPostMode }) => {
    const { search } = useLocation();
    const { replace } = useHistory();
    const t = useLocale("posts/form");

    const { PostStore } = useStore();
    const {
      fetchLoading,
      name,
      shopNumber,
      kill,
      logo,
      description,
    } = PostStore.post.shop || {};

    const shopIdParam = newPostMode
      ? search.slice(1).split("shop=")[1]
      : shopNumber;

    const [shopId, setShopId] = useState(shopIdParam || "");
    const [searchValue, setSearchValue] = useState(shopIdParam || "");
    const [activeKey, setActiveKey] = useState(shopId ? ["1"] : []);

    // console.log({ shop: PostStore.post.shop });

    useEffect(() => {
      setSearchValue(shopId);
      if (shopId) {
        newPostMode && replace(`/posts/new?shop=${shopId}`);
        console.log(shopId);
        PostStore.post.shop.fetch(shopId).then(() => {
          console.log({ shop: PostStore.post.shop });
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopId]);

    const handleSearch = (data) => {
      setShopId(data);
    };

    const handleChangeSwitch = (status) => {
      setActiveKey(status ? ["1"] : []);
      if (!status) {
        kill();
        setShopId("");
        newPostMode && replace(`/posts/new`);
      }
    };

    const handleChangeSearch = ({ target: { value } }) => {
      setSearchValue(value);
    };

    return (
      <div className="shop">
        <Collapse activeKey={activeKey} ghost>
          <Panel
            key="1"
            header={
              <div className="shop__header">
                <b>{t("shop.title")}</b>
                <Switch
                  checkedChildren={<span>{t("shop.status.yes")}</span>}
                  unCheckedChildren={<span>{t("shop.status.no")}</span>}
                  defaultChecked={Boolean(shopId)}
                  size="default"
                  onChange={handleChangeSwitch}
                />
              </div>
            }
            showArrow={false}
          >
            <div className="shopPanelContent">
              <div className="shopSearch">
                <b>{t("shop.fields.search.placeholder")}</b>
                <Search
                  prefix={<ShopOutlined />}
                  enterButton={t("shop.fields.search.button")}
                  size="large"
                  value={searchValue}
                  onSearch={handleSearch}
                  loading={fetchLoading}
                  onChange={handleChangeSearch}
                />
              </div>

              {shopId ? (
                fetchLoading ? (
                  <div className="loadCard">
                    <LoadingOutlined spin />
                    <b>{t("shop.loadingCard")}</b>
                  </div>
                ) : (
                  <Link
                    to={`/shops/${shopNumber}`}
                    target="_blank"
                    className="shopCard"
                  >
                    <img src={logo} alt={name} />
                    <div>
                      <strong className="shopCard__name">{name}</strong>
                      <span>{description}</span>
                    </div>
                    <LeftOutlined />
                  </Link>
                )
              ) : null}
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  })
);

export default Posts;
