import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Modal, Steps, notification } from "antd";

import { useLocale, useMobile, useStore } from "../../../hooks";
import {
  InformationStep,
  RoleDefinitionStep,
  ResultStep,
  FindUserStep,
} from "./";
import { STAFF } from "../../../constants";

const { Step } = Steps;

const AddStaffModal = () => {
  const isSmallDevice = useMobile(true);
  const { StaffStore } = useStore();
  const {
    addStaff,
    addStaffLoading,
    result,
    isVisibleModal,
    staffList,
    toggleModal,
    editMode,
    staffInfo,
    editStaff,
    checkUserId,
    actionLoading,
  } = StaffStore;

  const [current, setCurrent] = useState(0);
  const [formValues, setFormValues] = useState(STAFF.defaultFormValues);
  const t = useLocale("staff/form");

  const setInformation = (values) => {
    setFormValues({
      ...formValues,
      information: values,
    });
    nextStep();
  };

  const findUser = ({ id }) => {
    if (!id) return;
    checkUserId(id).then((res) => {
      if (res) {
        const role = res.role;
        delete res.role;
        delete res.id;
        setFormValues({
          information: { ...res },
          role: { role },
          user: {
            id,
          },
        });
        nextStep();
      } else {
        notification.error({
          message: "کاربر پیدا نشد",
        });
      }
    });
  };

  useEffect(() => {
    if (editMode) {
      setFormValues({
        role: { role: staffInfo.role },
        information: staffInfo,
        user: {
          id: staffInfo.id,
        },
      });
    }
    setCurrent(editMode * 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleModal]);

  const setRoles = (values) => {
    const newState = {
      ...formValues,
      role: values,
    };
    setFormValues(newState);
    const method = editMode ? editStaff : addStaff;
    method(newState).finally(() => nextStep());
  };

  const nextStep = () => setCurrent(current + 1);

  const prevStep = () => setCurrent(current - 1);

  const onRequestClose = () => {
    toggleModal();
    setCurrent(0);
    setFormValues(STAFF.defaultFormValues);
  };

  const steps = [
    {
      title: t("steps.findUser.title"),
      content: (
        <FindUserStep
          defaultValues={formValues.user}
          next={findUser}
          onCancel={toggleModal}
          loading={actionLoading}
        />
      ),
    },
    {
      title: t("steps.information.title"),
      content: (
        <InformationStep
          defaultValues={formValues.information}
          next={setInformation}
          prev={prevStep}
        />
      ),
    },
    {
      title: t("steps.roleDefinition.title"),
      content: (
        <RoleDefinitionStep
          userInfo={formValues.information}
          defaultValues={formValues.role}
          next={setRoles}
          prev={prevStep}
          loading={addStaffLoading}
        />
      ),
    },
    {
      title: t("steps.result.title"),
      content: (
        <ResultStep
          {...result}
          loading={addStaffLoading}
          onRetry={() => addStaff(formValues)}
          onClose={() => {
            onRequestClose();
            staffList.fetch();
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      title={t("title")}
      className="add-staff-modal"
      visible={isVisibleModal}
      onCancel={onRequestClose}
      destroyOnClose
      footer={null}
      maskClosable={true}
      width={750}
    >
      {!isSmallDevice && (
        <Steps current={current} direction="horizontal">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      )}
      <div className="steps-content">{steps[current].content}</div>
    </Modal>
  );
};
export default observer(AddStaffModal);
