import React from "react";
import { Row, Col } from "antd";

import { SingleUploader } from "..";
import { useLocale } from "../../../../../hooks";

const ImagesField = React.memo(
  ({ cover, logo, setFormikField, upload, logoError, coverError }) => {
    const t = useLocale("shops/form");

    return (
      <Row gutter={[10, 50]}>
        <Col span={20}>
          <SingleUploader
            upload={upload}
            preview={cover}
            setFormikField={setFormikField}
            hasFormikError={coverError}
            name="cover"
            placeholder={t("fields.cover.placeholder")}
          />
        </Col>
        <Col span={4}>
          <SingleUploader
            upload={upload}
            preview={logo}
            setFormikField={setFormikField}
            hasFormikError={logoError}
            name="logo"
            placeholder={t("fields.logo.placeholder")}
          />
        </Col>
      </Row>
    );
  }
);

export default ImagesField;
