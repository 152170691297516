import { notification } from "antd";
import { useLocale } from "../hooks";

const responseHandler = (res) => {
  console.log(res);
  if (res && res.code) {
    notification[res.success ? "success" : "error"]({
      message: res.message || detectMessage(res),
      duration: 4,
    });
  }
};

const detectMessage = (res) => {
  switch (res.code) {
    case "auth/invalid-verification-code":
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useLocale("global")("errorHandler.wrongCode");

    case "auth/captcha-check-failed":
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useLocale("global")("errorHandler.captchaFailed");

    case "user/not_found":
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useLocale("global")("errorHandler.userNotFound");

    default:
      break;
  }
};

export default responseHandler;
