import * as services from "@abdi.fakhruddin/services/dashboard";
import { notification } from "antd";

import responseHandler from "./responseHandler";
// import { useLng } from "../hooks";

class Api {
  call = (service, method, args = []) => {
    return new Promise(async (resolve, reject) => {
      if (!service || !method) {
        reject("CODE_ERROR");
      }
      try {
        const res = await services[service][method](...args);
        if (
          res &&
          typeof res === "object" &&
          res.hasOwnProperty("success") &&
          !res.success
        ) {
          console.log("ERROR: ", res);
          responseHandler(res);
          reject(res);
          return;
        }
        resolve(res);
        responseHandler(res);
      } catch (err) {
        const { logLevel, message } = err;
        if (logLevel === "error") {
          if (message) {
            notification.error({ message });
          } else {
            notification.error({
              message: "به مشکل برخوردیم",
            });
          }
        }
        console.log("CATCH ERROR: ", err);
        reject(err);
      }
    });
  };

  static = (service, method, args = []) => {
    if (!service || !method) {
      throw new Error("CODE_ERROR");
    }
    const res = services[service][method](...args);
    return res;
  };

  get = (service, method) => {
    if (!service || !method) {
      throw new Error("CODE_ERROR");
    }
    return services[service][method];
  };
}

const api = new Api();
export default api;
