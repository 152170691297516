import React from "react";
import { Result, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useLocale } from "../../../hooks";

const ResultStep = ({
  loading,
  title,
  description,
  status,
  onClose,
  onRetry,
}) => {
  const t = useLocale("staff/form");
  return (
    <div>
      <Result
        status={status}
        title={title}
        subTitle={description}
        extra={[
          <Button type="text" key="close" onClick={onClose}>
            {t("steps.result.seeTableButton")}
          </Button>,
          status === "error" ? (
            <Button
              type="primary"
              key="retry"
              onClick={onRetry}
              icon={<ReloadOutlined spin={loading} />}
            >
              {t("steps.result.retryButton")}
            </Button>
          ) : null,
        ]}
      />
    </div>
  );
};
export default ResultStep;
