import React from "react";
import classNames from "classnames";

import { Form } from "formik-antd";
import { Col, Typography } from "antd";
import "./FormFieldWrapper.less";

const { Text } = Typography;

const FormFieldWrapper = ({
  span,
  multiLine,
  children,
  className,
  name,
  placeholder,
  defaultDirection = "row",
}) => {
  const label = <Text strong>{placeholder} :</Text>;
  return (
    <Col
      span={span}
      className={classNames("field", `-${defaultDirection}`, {
        "-multiLine": multiLine,
      })}
    >
      <Form.Item className={className} name={name} label={label}>
        {children}
      </Form.Item>
    </Col>
  );
};
export default FormFieldWrapper;
