import { types } from "mobx-state-tree";

const FilterModel = types
  .model("FilterModel", {
    filter: types.map(types.frozen({})),
  })
  .views((self) => ({
    get filterToJSON() {
      return self.filter.toJSON();
    },
  }))
  .actions((self) => {
    return {
      setFilterProperty: (key, value, cb = () => false) => {
        self.filter[key] = value;
        cb();
      },
      removeFilterPropert: (targetKey) => {
        delete self.filter[targetKey];
      },
      changeFilter: (obj, cb = () => false) => {
        if (Object.keys(obj).length) {
          self.filter = obj;
          cb();
        }
      },
      resetFilter: () => {
        self.filter = {};
      },
    };
  });

export default FilterModel;
