import React from "react";
import { Button, Table, Typography, Tag, Space } from "antd";
import { STAFF } from "../../../constants";
import { useLocale, useMobile } from "../../../hooks";
import { Label, Pagination } from "../../../components/private";
import { date } from "../../../utils";
import { EditFilled } from "@ant-design/icons";

const { Column } = Table;
const { Text } = Typography;

const StaffTable = ({
  data,
  loading,
  getStatusInfo,
  getTypesInfo,
  next,
  prev,
  page,
  filter,
}) => {
  const isMobile = useMobile();
  const t = useLocale("staff/list");

  const renderActions = (text, record) => {
    return (
      <Space size="middle">
        <Button
          type="primary"
          ghost
          icon={<EditFilled />}
          onClick={record.openUpdateModal}
          style={{ marginLeft: 10 }}
        />
      </Space>
    );
  };

  return (
    <div className="site-layout-content">
      <Table
        dataSource={data}
        className="staff__table"
        loading={loading}
        pagination={false}
        expandable={
          isMobile
            ? {
                expandedRowRender: (record) => {
                  return (
                    <div className="table-row-expanded-card">
                      <Label
                        responsive={["md", "sm", "xs"]}
                        title={t("table.heading.role")}
                      >
                        {getTypesInfo(record.role, "text")}
                      </Label>
                      <Label
                        responsive={["md", "sm", "xs"]}
                        title={t("table.heading.created")}
                      >
                        {date.getDay(record.created)}
                      </Label>
                      <Label
                        responsive={["sm", "xs"]}
                        title={t("table.heading.functions")}
                      >
                        {renderActions(record, record)}
                      </Label>
                    </div>
                  );
                },
              }
            : null
        }
      >
        <Column
          title={t("table.heading.displayName")}
          dataIndex="displayName"
          key="displayName"
        />
        <Column
          responsive={["lg"]}
          title={t("table.heading.created")}
          dataIndex="created"
          key="created"
          render={(created) => date.getDay(created)}
        />
        <Column
          title={t("table.heading.role")}
          responsive={["lg"]}
          dataIndex="role"
          render={(type) => getTypesInfo(type, "text")}
        />
        <Column
          title={t("table.heading.status")}
          dataIndex="status"
          key="status"
          filters={STAFF.statuses}
          filterMultiple={false}
          onFilter={filter}
          render={(status) => (
            <Tag color={getStatusInfo(status, "color")} key={status}>
              {getStatusInfo(status, "text")}
            </Tag>
          )}
        />
        <Column
          title={t("table.heading.functions")}
          responsive={["md"]}
          key="action"
          render={renderActions}
        />
      </Table>
      <Pagination next={next} prev={prev} page={page} />
    </div>
  );
};
export default StaffTable;
