import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Button } from "antd";

import "./staff.less";
import { pageWrapper } from "../../utils";
import { AddStaffModal, StaffTable } from "./components";
import { useLocale, useStore } from "../../hooks";

const StaffPage = ({ useStore }) => {
  const { StaffStore } = useStore;
  const { staffList, getTypesInfo, getStatusInfo } = StaffStore;
  const t = useLocale("staff/list");

  const {
    list,
    fetchLoading,
    fetchInitial,
    page,
    next,
    prev,
    filter,
  } = staffList;

  useEffect(() => {
    fetchInitial();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="staff">
      <AddStaffModal />
      <StaffTable
        loading={fetchLoading}
        getTypesInfo={getTypesInfo}
        getStatusInfo={getStatusInfo}
        page={page}
        next={next}
        prev={prev}
        data={list}
        filter={filter}
      />
    </div>
  );
};

const AddStaffButton = () => {
  const { StaffStore } = useStore();
  const t = useLocale("staff/list");

  const openAddModal = () => {
    StaffStore.setEditMode(false);
    StaffStore.toggleModal();
  };

  return (
    <Button type={"primary"} onClick={openAddModal}>
      {t("addStaffButtonTitle")}
    </Button>
  );
};

const Staff = pageWrapper(observer(StaffPage), {
  hasHeader: true,
  get subTitle() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("staff/list")("description");
  },
  customHeaderButtons: [<AddStaffButton />],
});
export default Staff;
