import React from "react";
import pageWrapper from "../../utils/pageWrapper";
import { Button } from "antd";
// import './.scss'
const CompetitorsPage = () => {
  return (
    <div>
      <h1>Competitors</h1>
      <Button type={"default"}>Hi</Button>
    </div>
  );
};

const Competitors = pageWrapper(CompetitorsPage, {
  hasHeader: true,
  subTitle: "مشاهده و مقایسه فعالیت رقیبان هرزان",
  customHeaderButtons: [<Button type={"dark"}>test button</Button>],
});

export default Competitors;
