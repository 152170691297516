import React from "react";
import { Input } from "formik-antd";

import { FormFieldWrapper } from "../../../../components/private";
import { useLocale, useMobile } from "../../../../hooks";

const PhoneField = React.memo(() => {
  const isSmallDevice = useMobile(true);
  const t = useLocale("posts/form");

  return (
    <FormFieldWrapper
      span={isSmallDevice ? 24 : 12}
      name="phoneNumber"
      className="phoneField"
      placeholder={t("fields.phoneNumber.placeholder")}
    >
      <Input name="phoneNumber" />
    </FormFieldWrapper>
  );
});

export default PhoneField;
