import React, { useEffect, useState } from "react";
import { Form, Select } from "formik-antd";
import { Formik } from "formik";
import { Row, Col, Typography, Button, Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { STAFF } from "../../../constants";
import { useLocale, useStore } from "../../../hooks";

const { Meta } = Card;
const { Text } = Typography;
const { Option } = Select;

const RoleDefinitionStep = ({
  prev,
  next,
  defaultValues,
  userInfo,
  loading,
}) => {
  const [isInitialValid, setIsInitialValid] = useState(false);
  const schema = STAFF.roleDefinitionValidation();
  const t = useLocale("staff/form");
  const { StaffStore } = useStore();

  useEffect(() => {
    schema
      .validate(defaultValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <div>
      <Formik
        initialValues={defaultValues}
        validationSchema={schema}
        enableReinitialize
        isInitialValid={isInitialValid}
        onSubmit={next}
        render={(formik) => (
          <Form layout="vertical" size="large">
            <Row justify="center" align="middle">
              <Col span={12} xs={{ span: 24 }}>
                <UserCard {...userInfo} />
                <Text strong>{t("steps.roleDefinition.formTitle")}</Text>

                <Form.Item style={{ marginTop: 16 }} name="role">
                  <Select
                    name="role"
                    placeholder={t(
                      "steps.roleDefinition.fields.role.placeholder"
                    )}
                  >
                    <Option value="admin">
                      {StaffStore.getTypesInfo("admin", "text")}
                    </Option>
                    <Option value="marketing">
                      {StaffStore.getTypesInfo("marketing", "text")}
                    </Option>
                    <Option value="post">
                      {StaffStore.getTypesInfo("post", "text")}
                    </Option>
                    <Option value="shop">
                      {StaffStore.getTypesInfo("shop", "text")}
                    </Option>
                    <Option value="category">
                      {StaffStore.getTypesInfo("category", "text")}
                    </Option>
                  </Select>
                </Form.Item>

                <Button
                  htmlType="submit"
                  style={{ fontSize: 14 }}
                  disabled={!formik.isValid}
                  onClick={formik.handleSubmit}
                  loading={loading}
                  type="primary"
                >
                  {t("steps.roleDefinition.submitButton")}
                </Button>
                <Button
                  htmlType="button"
                  onClick={prev}
                  style={{ margin: "0 8px", fontSize: 14 }}
                >
                  {t("steps.roleDefinition.backButton")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

const UserCard = ({ displayName, phoneNumber }) => {
  return (
    <Card style={{ marginTop: 16, marginBottom: 16 }}>
      <Meta
        avatar={<Avatar icon={<UserOutlined />} />}
        title={displayName}
        description={<span className="ltr-field">{phoneNumber}</span>}
      />
    </Card>
  );
};
export default RoleDefinitionStep;
