import { types, getParent } from "mobx-state-tree";
import { useStore } from "../../../hooks";
import { api } from "../../../utils";

export const PostItemModelUnprocessed = types
  .compose(
    "PostModel",
    types.model({
      id: types.maybeNull(types.string),
      key: types.maybeNull(types.string),
      title: types.maybeNull(types.string),
      phoneNumber: types.maybeNull(types.string),
      cover: types.optional(types.array(types.string), []),
      date: types.maybeNull(types.number),
    }),
    types.model({
      actionLoading: types.optional(types.boolean, false),
    })
  )
  .actions((self) => {
    const { PostStore } = useStore();

    const updateStatus = (status) => {
      self.loadingOn();

      return api
        .call("Post", "updateStatus", [self.id, PostStore.getStatus(status)])
        .then(self.kill)
        .finally(self.loadingOff);
    };

    return {
      loadingOff() {
        self.actionLoading = false;
      },
      loadingOn() {
        self.actionLoading = true;
      },

      delete() {
        self.loadingOn();

        return api
          .call("Post", "removePost", [self.id])
          .then(self.kill)
          .finally(self.loadingOff);
      },

      accept: () => updateStatus("ACCEPTED"),
      reject: () => updateStatus("REJECTED"),
      unverify: () => updateStatus("NOT_VERIFIED"),
      kill: () => {
        getParent(self, 2).deleteItem(self);
      },
    };
  });

export const PostItemModel = types.snapshotProcessor(PostItemModelUnprocessed, {
  preProcessor(sn) {
    return {
      ...sn,
      date: sn.date && sn.date.seconds && sn.date.seconds,
    };
  },
});
