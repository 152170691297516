import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { REGEX } from ".";
import { useLocale } from "../hooks";

const err = (field) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useLocale("posts/form")(`fields.${field}.error`);
};

const TPL = (ref) => {
  return useLocale("posts/list")(ref);
};

export default {
  createPostValidation(isNewPost) {
    return yup.object().shape({
      title: yup.string().required(err("title")).typeError(err("title")),
      city: yup.number().required(err("city")).typeError(err("city")),
      category: yup
        .number()
        .required(err("category"))
        .typeError(err("category")),
      subCategory: yup
        .number()
        .required(err("subCategory"))
        .typeError(err("subCategory")),
      ...(isNewPost && {
        phoneNumber: yup
          .string()
          .required(err("phoneNumber"))
          .typeError(err("phoneNumber")),
      }),
    });
  },

  tabs: [
    {
      key: "ACCEPTED",
      get title() {
        return TPL("tabs.accepted");
      },
    },
    {
      key: "REPORTED",
      get title() {
        return TPL("tabs.reported");
      },
    },
    {
      key: "NOT_VERIFIED",
      get title() {
        return TPL("tabs.notVerified");
      },
    },
    {
      key: "REJECTED",
      get title() {
        return TPL("tabs.rejected");
      },
    },
    {
      key: "REMOVED",
      get title() {
        return TPL("tabs.removed");
      },
    },
  ],
  actions: [
    {
      key: "ACCEPTED",
      get title() {
        return TPL("actions.accept.title");
      },
      confirmModal: {
        get title() {
          return TPL("actions.accept.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TPL("actions.accept.confirmModal.acceptText");
        },
        actionName: "accept",
      },
    },
    {
      key: "NOT_VERIFIED",
      get title() {
        return TPL("actions.unverify.title");
      },
      confirmModal: {
        get title() {
          return TPL("actions.unverify.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TPL("actions.unverify.confirmModal.acceptText");
        },
        actionName: "unverify",
      },
    },
    {
      key: "REJECTED",
      get title() {
        return TPL("actions.reject.title");
      },
      confirmModal: {
        get title() {
          return TPL("actions.reject.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TPL("actions.reject.confirmModal.acceptText");
        },
        actionName: "reject",
      },
    },
    {
      key: "REMOVED",
      get title() {
        return TPL("actions.remove.title");
      },
      confirmModal: {
        get title() {
          return TPL("actions.remove.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "danger",
        get okText() {
          return TPL("actions.remove.confirmModal.acceptText");
        },
        actionName: "delete",
      },
    },
    {
      key: "RENEW",
      get title() {
        return TPL("actions.renew.title");
      },
      confirmModal: {
        get title() {
          return TPL("actions.renew.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TPL("actions.renew.confirmModal.acceptText");
        },
        actionName: "renew",
      },
    },
  ],
  batchActions: [
    {
      key: "REMOVED",
      get title() {
        return TPL("batchActions.remove.title");
      },
      confirmModal: {
        get title() {
          return TPL("batchActions.remove.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "danger",
        get okText() {
          return TPL("batchActions.remove.confirmModal.acceptText");
        },

        actionName: "batchDelete",
      },
    },
    {
      key: "REJECTED",
      get title() {
        return TPL("batchActions.reject.title");
      },
      confirmModal: {
        get title() {
          return TPL("batchActions.reject.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TPL("batchActions.reject.confirmModal.acceptText");
        },

        actionName: "batchReject",
      },
    },
    {
      key: "NOT_VERIFIED",
      get title() {
        return TPL("batchActions.unverify.title");
      },
      confirmModal: {
        get title() {
          return TPL("batchActions.unverify.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TPL("batchActions.unverify.confirmModal.acceptText");
        },
        actionName: "batchUnverify",
      },
    },
    {
      key: "ACCEPTED",
      get title() {
        return TPL("batchActions.accept.title");
      },
      confirmModal: {
        get title() {
          return TPL("batchActions.accept.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TPL("batchActions.accept.confirmModal.acceptText");
        },
        actionName: "batchAccept",
      },
    },
  ],
};
