import React from "react";
import PropTypes from "prop-types";

import { CloudUploadOutlined } from "@ant-design/icons";
import "./UploadButton.less";

const UploadButton = ({
  upload,
  placeholder,
  preview,
  multiple,
  renderPreview,
}) => {
  const renderCompPreview = () => {
    console.log({ preview });
    if (preview) {
      if (renderPreview)
        return <div className="uploadBtn__preview">{renderPreview()}</div>;
      else
        return (
          <img className="uploadBtn__preview" src={preview} alt="preview" />
        );
    }
  };

  return (
    <label className="uploadBtn">
      {renderCompPreview()}
      <input
        className="uploadBtn__input"
        type="file"
        multiple={multiple}
        onChange={upload}
      />
      <CloudUploadOutlined />
      <span>{placeholder}</span>
    </label>
  );
};

UploadButton.defaultProps = {
  placeholder: "آپلود",
  preview: false,
  renderPreview: null,
  multiple: true,
};

UploadButton.propTypes = {
  upload: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  preview: PropTypes.bool,
  multiple: PropTypes.bool,
  renderPreview: PropTypes.any,
};

export default UploadButton;
