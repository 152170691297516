import React, { useEffect, useState } from "react";
import classNames from "classnames";

import {
  DeleteOutlined,
  LoadingOutlined,
  UndoOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import "./DocList.less";
import { ImagesModal, UploadButton } from "../../private";
import { useLocale } from "../../../hooks";

const modalDefaults = {
  isOpen: false,
  slides: [],
  initialSlide: 0,
};

const DocList = ({ docs, editable, upload, onChange }) => {
  const [modalData, setModalData] = useState(modalDefaults);
  const t = useLocale("components");

  useEffect(() => {
    if (docs.every((item) => !item.uploading)) {
      onChange(
        docs.map((item) => ({
          uid: item.uid,
          name: item.name,
          uploadStatus: item.uploadStatus,
          url: item.url,
        }))
      );
    }
  }, [docs]);

  return (
    <div className="docList">
      {docs.map((docDetails, i) => (
        <DocThumb
          idx={i}
          removable={editable}
          key={docDetails.uid}
          onClick={() => {
            setModalData({
              slides: [
                ...docs
                  .filter((item) => item.uploadStatus === "success")
                  .map((item) => item.url),
              ],
              initialSlide: i,
              isOpen: true,
            });
          }}
          {...docDetails}
        />
      ))}

      <ImagesModal
        data={modalData}
        onClose={() => setModalData(modalDefaults)}
      />

      {editable && (
        <UploadButton placeholder={t("docList.placeholder")} upload={upload} />
      )}
    </div>
  );
};

const DocThumb = ({
  removable,
  preventDeletion,
  thumb64,
  name,
  remove,
  reUpload,
  removing,
  uploading,
  uploadStatus,
  onClick,
}) => {
  const isFailedImage = uploadStatus === "failed";
  const className = classNames("docThumb", {
    "-failed": isFailedImage,
  });

  const optionsClassName = classNames("docThumb__options", {
    "-removing": removing,
  });

  const t = useLocale("components");

  return (
    <div className={className}>
      {removable && !uploading && !isFailedImage && (
        <div className={optionsClassName}>
          {removing ? (
            <LoadingOutlined spin />
          ) : (
            <button type="button" onClick={remove}>
              <DeleteOutlined />
            </button>
          )}

          <span className="loadingText">{t("docList.removing")}</span>

          <button type="button" onClick={preventDeletion} className="undoBtn">
            <span>{t("docList.cancel")}</span>
            <UndoOutlined />
          </button>
        </div>
      )}

      {isFailedImage && (
        <div className="docThumb__options">
          <button type="button" className="-reUpload" onClick={reUpload}>
            <SyncOutlined spin={false} />
          </button>
        </div>
      )}

      {uploading && (
        <div className="docThumb__uploading">
          <LoadingOutlined spin />
          <span className="uploadingText">{t("docList.uploading")}</span>
        </div>
      )}

      <img
        src={thumb64}
        onClick={!isFailedImage && onClick}
        loading="lazy"
        alt={name}
      />
    </div>
  );
};

export default DocList;
