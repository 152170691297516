import React from "react";
import { Row } from "antd";
import { InputNumber, Radio, Select } from "formik-antd";

import { FormFieldWrapper } from "../../../../components/private";
import { useMobile, useStore } from "../../../../hooks";
import { utils } from "../../../../utils";

const { Option } = Select;

const DynamicFields = React.memo(({ specialAttributes, setFormikField }) => {
  const isSmallDevice = useMobile(true);
  const { PostStore } = useStore();
  const { attributes } = PostStore.post;

  const renderFields = (item, specialAttributes) => {
    const { id, type, options, title, isChild, parentID } = item;
    switch (type) {
      case "select": {
        let selectOptions = options;
        if (isChild) {
          const parent = attributes.find((item) => item.id === parentID);
          const parentValue = specialAttributes[`a${parent.id}`];
          if (parentValue) {
            selectOptions = options.filter(
              (item) => item.parentID === parentValue * 1
            );
            const itemValue = specialAttributes[`a${id}`];
            if (
              itemValue &&
              !selectOptions.find((item) => item.value * 1 === itemValue * 1)
            ) {
              setFormikField(`specialAttributes.a${id}`, null);
            }
          } else {
            selectOptions = [];
          }
        }

        return (
          <FormFieldWrapper
            span={isSmallDevice ? 24 : 12}
            name={`specialAttributes.a${id}`}
            placeholder={title}
          >
            <Select
              showSearch
              name={`specialAttributes.a${id}`}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {selectOptions.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormFieldWrapper>
        );
      }

      case "radio": {
        let selectOptions = options;

        return (
          <FormFieldWrapper
            span={24}
            name={`specialAttributes.a${id}`}
            placeholder={title}
          >
            <Radio.Group name={`specialAttributes.a${id}`}>
              {selectOptions.map((item) => (
                <Radio value={item.value}>{item.label}</Radio>
              ))}
            </Radio.Group>
          </FormFieldWrapper>
        );
      }

      case "number":
      case "text":
        return (
          <FormFieldWrapper
            span={isSmallDevice ? 24 : 12}
            name={`specialAttributes.a${id}`}
            placeholder={title}
          >
            <InputNumber name={`specialAttributes.a${id}`} />
          </FormFieldWrapper>
        );

      default:
        return null;
    }
  };

  return (
    <section className="newPost-info__section -wide">
      {utils.chunkArray(attributes, 2).map((row, n) => (
        <Row justify="start" align="middle" gutter={[48, 0]}>
          {row.map((item) => renderFields(item, specialAttributes))}
        </Row>
      ))}
    </section>
  );
});

export default DynamicFields;
