import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Layout } from "antd";
import { Menu } from "antd";

import "./Sidebar.less";
import Logo from "./../../../assets/images/logo.svg";
import config from "./../../../config";
import { useMobile, useStore } from "../../../hooks";

const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidebar = ({ collapsed, fullScreenMode }) => {
  const { pathname } = useLocation();
  const defaultOpen = config.filledGroupsWithRelatedRoutes.find((item) =>
    item.sidebarOptions.routes.some((itm) => itm.path === pathname)
  );
  const isMobile = useMobile();
  const { AuthStore } = useStore();
  const { hasAccessTo } = AuthStore;

  return (
    <Sider
      collapsedWidth={fullScreenMode ? 0 : 80 * Number(!isMobile)}
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="sidebar"
    >
      <Link to="/" className="sidebar__logo">
        <img src={Logo} alt="logo" />
      </Link>
      <Menu
        defaultSelectedKeys={[pathname]}
        defaultOpenKeys={[defaultOpen ? defaultOpen.id : null]}
        mode="inline"
        theme="dark"
        // inlineCollapsed={collapsed}
        collapsed={collapsed || undefined}
        className="sidebar__menu"
        activeKey={pathname}
        selectedKeys={pathname}
      >
        {config.sidebarItems
          .filter((item) => hasAccessTo(item.id))
          .map((item) => {
            if (!item.sidebarOptions.routes)
              return (
                <Menu.Item key={item.path} icon={<item.sidebarOptions.icon />}>
                  <NavLink to={item.path}>{item.sidebarOptions.title}</NavLink>
                </Menu.Item>
              );

            return (
              <SubMenu
                key={item.id}
                title={item.sidebarOptions.title}
                icon={<item.sidebarOptions.icon />}
              >
                {item.sidebarOptions.routes.map((child) => (
                  <Menu.Item
                    key={child.path}
                    icon={<child.sidebarOptions.icon />}
                  >
                    <NavLink to={child.path}>
                      {child.sidebarOptions.title}
                    </NavLink>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          })}
      </Menu>
    </Sider>
  );
};
export default Sidebar;
