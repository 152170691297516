import React from "react";
import { Select } from "formik-antd";

import { FormFieldWrapper } from "../../../../../components/private";
import { useLocale, useMobile, useStore } from "../../../../../hooks";
import { Row } from "antd";

const { Option } = Select;

const CityField = React.memo(() => {
  const { GlobalStore } = useStore();
  const { citiesOptions } = GlobalStore;
  const isSmallDevice = useMobile(true);
  const t = useLocale("shops/form");
  return (
    <Row>
      <FormFieldWrapper
        span={isSmallDevice ? 24 : 12}
        name="city"
        placeholder={t("fields.city.placeholder")}
      >
        <Select name="city">
          {citiesOptions.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </FormFieldWrapper>
    </Row>
  );
});

export default CityField;
