import { flow, types } from "mobx-state-tree";
import { STAFF } from "../constants";
import { notification } from "antd";
import { toJS } from "mobx";
import { api } from "../utils";
import StaffListModel from "./models/staff/staffListModel";
import StaffModel from "./models/staff/staffModel";

const StaffStore = types
  .model("StaffStore", {
    isVisibleModal: types.optional(types.boolean, false),
    staffList: StaffListModel,
    staff: types.maybeNull(StaffModel),
    addStaffLoading: types.optional(types.boolean, false),
    editMode: types.optional(types.boolean, false),
    actionLoading: types.optional(types.boolean, false),
    result: types.model({
      title: "",
      status: "",
      description: "",
    }),
  })
  .views((self) => ({
    getStatusInfo: (status, property) => {
      if (status)
        return STAFF.statuses.find((item) => item.value === status)[property];
    },
    getTypesInfo: (type, property) => {
      return STAFF.userTypes.find((item) => item.value === type)[property];
    },
    get staffInfo() {
      return toJS(self.staff);
    },
  }))
  .actions((self) => ({
    changeLoading: (loading) => {
      self.addStaffLoading = loading;
    },
    toggleModal: () => {
      if (self.isVisibleModal)
        self.fillStaff(STAFF.defaultFormValues.information);
      self.isVisibleModal = !self.isVisibleModal;
    },
    fillStaff: (staffDetail) => {
      self.staff = staffDetail;
    },
    setEditMode(editMode) {
      self.editMode = editMode;
    },
    checkUserId: flow(function* (id) {
      self.actionLoading = true;
      try {
        const { data } = yield api.static("User", "getSingleUserById", [id]);
        self.actionLoading = false;
        return data;
      } catch {
        self.actionLoading = false;
        return false;
      }
    }),
    addStaff: flow(function* ({ information, role, user }) {
      const userData = { ...information, ...role };
      console.log({ userData });
      self.changeLoading(true);
      try {
        const res = yield api.static("User", "updateUser", [user.id, userData]);
        notification.success(STAFF.addStaffNotificationSuccess);
        self.apply("result", STAFF.addStaffResultSuccess);
        return "OK";
      } catch {
        notification.error(STAFF.addStaffNotificationError);
        self.apply("result", STAFF.addStaffResultError);
        return false;
      } finally {
        self.changeLoading(false);
      }
    }),
    editStaff: flow(function* ({ information, role, user }) {
      const userData = { ...information, ...role };
      self.changeLoading(true);
      try {
        const res = yield api.static("User", "updateUser", [user.id, userData]);
        notification.success(STAFF.editStaffNotificationSuccess);
        self.apply("result", STAFF.editStaffResultSuccess);
        return "OK";
      } catch {
        notification.error(STAFF.editStaffNotificationError);
        self.apply("result", STAFF.editStaffResultError);
        return false;
      } finally {
        self.changeLoading(false);
      }
    }),

    apply: (key, value) => {
      self[key] = value;
    },
  }));

export default StaffStore.create({
  isVisibleModal: false,
  staffList: {},
  result: {},
});
