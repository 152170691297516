import { Button, Space } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { SyncOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import { ActionDropMenu } from "..";
import { useLocale } from "../../../hooks";

const Actions = ({
  status,
  record,
  getAllowedActions,
  getAction,
  indicator,
  type = "card",
  removeCallBack = () => false,
}) => {
  const t = useLocale("components");
  const tGlobal = useLocale("global");
  const isInSinglePost = type === "singlePage";
  const callAction = (status, model) => {
    const item = getAction({ name: status, isSingleAction: true })[
      "confirmModal"
    ];

    confirm({
      title: item.title,
      okType: item.okType,
      okText: item.okText,
      icon: <item.icon />,
      cancelText: tGlobal("cancel"),
      maskClosable: true,
      cancelButtonProps: {
        type: "text",
      },
      onOk: () => model[item.actionName](),
    });
  };

  return (
    <Space size="middle">
      {status !== "ACCEPTED" && (
        <Button
          type={isInSinglePost ? "primary" : "text"}
          onClick={() => callAction("ACCEPTED", record)}
          disabled={record.actionLoading}
        >
          <Text className="call-to-action">
            {record.actionLoading ? (
              <SyncOutlined spin style={{ marginRight: 20 }} />
            ) : (
              <span style={{ color: isInSinglePost ? "white" : "#ff9100" }}>
                {t("actions.accept")} {indicator}
              </span>
            )}
          </Text>
        </Button>
      )}
      {isInSinglePost && status !== "REJECTED" && (
        <Button
          type="text"
          onClick={() => callAction("REJECTED", record)}
          disabled={record.actionLoading}
        >
          <Text className="call-to-action">
            {record.actionLoading ? (
              <SyncOutlined spin style={{ marginRight: 20 }} />
            ) : (
              <span>
                {t("actions.reject")} {indicator}
              </span>
            )}
          </Text>
        </Button>
      )}

      <Button
          type="text"
          onClick={() => callAction("RENEW", record)}
          disabled={record.actionLoading}
        >
          <Text className="call-to-action">
            {record.actionLoading ? (
              <SyncOutlined spin style={{ marginRight: 20 }} />
            ) : (
              <span>
                {t("actions.renew")} {indicator}
              </span>
            )}
          </Text>
        </Button>

      <ActionDropMenu
        title={t("actions.otherFunctions")}
        data={getAllowedActions({
          ignore: `ACCEPTED${isInSinglePost ? "|REJECTED" : ""}`,
          isSingleAction: true,
          status,
        })}
        model={record}
        removeCallBack={removeCallBack}
      />
    </Space>
  );
};
export default Actions;
