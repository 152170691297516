import React from "react";
import { Col, Row, Typography } from "antd";
import { FieldArray } from "formik";

import "./WorkingTimeFields.less";
import SingleTimeField from "./SingleTimeField";
import { useLocale, useMobile } from "../../../../../hooks";
import { FormFieldWrapper } from "../../../../../components/private";

const { Text } = Typography;

const WorkingTimeFields = React.memo(
  ({ setFormikField, values, name, placeholder }) => {
    const closedTime = 0;
    const isMobile = useMobile();
    const t = useLocale("components");

    return (
      <Row>
        <FormFieldWrapper
          name="workingTimes"
          placeholder={placeholder}
          multiLine
          span={isMobile ? 24 : 18}
        >
          <div className="workingTime">
            <Row gutter={[10, 10]}>
              <Col span={4}>
                <Text strong>{t("workingTimeFields.headings.status")} </Text>
              </Col>
              <Col span={5}>
                <Text strong>{t("workingTimeFields.headings.day")} </Text>
              </Col>
              <Col span={15}>
                <Row gutter={[10, 10]}>
                  <Col span={12}>
                    <Text strong>
                      {t("workingTimeFields.headings.startTime")}
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text strong>
                      {t("workingTimeFields.headings.endTime")}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>

            <FieldArray
              name={name}
              render={() => {
                return values.map((item, idx) => (
                  <SingleTimeField
                    key={idx.toString()}
                    closedTime={closedTime}
                    value={item}
                    setFormikField={setFormikField}
                    idx={idx}
                    name={`${name}.${idx}`}
                  />
                ));
              }}
            />
          </div>
        </FormFieldWrapper>
      </Row>
    );
  }
);

export default WorkingTimeFields;
