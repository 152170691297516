import {
  PostStore,
  GlobalStore,
  StaffStore,
  AuthStore,
  FieldsStore,
  ShopStore,
} from "./../stores";

const useStore = () => {
  return {
    PostStore,
    StaffStore,
    GlobalStore,
    AuthStore,
    FieldsStore,
    ShopStore,
  };
};

export default useStore;
