import React from "react";
import { Form, Input, Select, Switch, Radio } from "formik-antd";
import { Col, Typography } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import classNames from "classnames";
import "./FormField.less";
import TagInput from "../../shared/tagInput/TagInput";

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const FormField = ({
  placeholder,
  name,
  readonly = false,
  multiLine = false,
  type = "text",
  value,
  className = "",
  span = 24,
  options = [],
  onChange = () => false,
}) => {
  const label = <Text strong>{placeholder} :</Text>;
  const InputField = multiLine ? TextArea : Input;

  const renderField = () => {
    if (type === "select")
      return (
        <Select name={name} onChange={onChange}>
          {options.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      );
    else if (type === "tag")
      return <TagInput onChange={onChange} value={value} />;
    else if (type === "switch")
      return (
        <Switch
          name={name}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      );
    else if (type === "radio")
      return (
        <Radio.Group name={name}>
          {options.map((item) => (
            <Radio value={item.value}>{item.label}</Radio>
          ))}
        </Radio.Group>
      );
    return <InputField disabled={readonly} name={name} />;
  };

  return (
    <Col
      span={span}
      className={classNames("field", { "-multiLine": multiLine })}
    >
      <Form.Item className={className} name={name} label={label}>
        {renderField()}
      </Form.Item>
    </Col>
  );
};
export default FormField;
