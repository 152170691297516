import { toJS } from "mobx";
import { types } from "mobx-state-tree";

import { api } from "../utils";
import { CategoryModel, CityModel } from "./models";
import FieldsStore from "./fieldsStore";

const GlobalStore = types
  .compose(
    "GlobalStore",
    types.model({
      collapsed: types.optional(types.boolean, false),
      fullScreenMode: types.optional(types.boolean, false),
      loading: types.optional(types.boolean, true),
    }),
    types.model({
      cities: types.optional(types.array(CityModel), []),
      categories: types.optional(types.array(CategoryModel), []),
    })
  )
  .views((self) => ({
    getCat(id) {
      return (
        toJS(self.categories).find((item) => item.categoryID === Number(id)) ||
        {}
      );
    },
    getSubCat(id, subId) {
      return Object.values(self.getCat(id)).length
        ? (self.getCat(id).subCategories || []).find(
            (item) => item.categoryID === Number(subId)
          ) || {}
        : {};
    },
    get categoriesOptions() {
      return self.categories.map((item) => ({
        value: item.categoryID,
        label: item.categoryTitle,
      }));
    },
    get citiesOptions() {
      return self.cities.map((item) => ({
        value: item.cityID,
        label: item.cityTitle,
      }));
    },

    subCategoriesOptions(catID) {
      return (self.getCat(catID).subCategories || []).map((item) => ({
        value: item.categoryID,
        label: item.categoryTitle,
      }));
    },
  }))
  .actions((self) => ({
    loadingOff() {
      self.loading = false;
    },
    loadingOn() {
      self.loading = true;
    },

    initial: () => {
      self.loadingOn();
      const categories = api.static("Initial", "getCategories");
      self.categories = [...categories];
      FieldsStore.fillCategories(categories);
      const cities = api.static("Initial", "getCities");
      self.cities = [...cities.sort((a, b) => a.cityID - b.cityID)];
      self.loadingOff();
      return "OK";
    },

    setCollapse: (collapse) => {
      self.collapsed = collapse;
      self.fullScreenMode = false;
    },
    setFullScreenMode: (fullScreenMode, shouldChangeCollapse) => {
      self.fullScreenMode = fullScreenMode;
      shouldChangeCollapse && (self.collapsed = fullScreenMode);
    },
  }));

export default GlobalStore.create({
  collapsed: false,
});
