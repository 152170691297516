import React from "react";
import { Row, Col, Spin, Button } from "antd";
import { Form, Input } from "formik-antd";
import { FormFieldWrapper } from "../../../../../components/private";
import { useLocale, useMobile } from "../../../../../hooks";

const BasicInfo = React.memo(() => {
  const isSmallDevice = useMobile(true);
  const fullSpan = 24;
  const halfSPan = isSmallDevice ? fullSpan : 12;
  const t = useLocale("shops/form");

  return (
    <>
      <Row>
        <FormFieldWrapper
          span={halfSPan}
          name="name"
          placeholder={t("fields.name.placeholder")}
        >
          <Input name="name" />
        </FormFieldWrapper>
        <FormFieldWrapper
          span={halfSPan}
          name="phoneNumber"
          className="phoneField"
          placeholder={t("fields.phoneNumber.placeholder")}
        >
          <Input name="phoneNumber" />
        </FormFieldWrapper>
      </Row>
      <Row>
        <FormFieldWrapper
          span={24}
          name="description"
          placeholder={t("fields.description.placeholder")}
        >
          <Input name="description" />
        </FormFieldWrapper>
      </Row>
      <Row>
        <FormFieldWrapper
          span={halfSPan}
          name="owner"
          placeholder={t("fields.owner.placeholder")}
        >
          <Input name="owner" />
        </FormFieldWrapper>
        <FormFieldWrapper
          span={halfSPan}
          name="idCart"
          placeholder={t("fields.idCart.placeholder")}
        >
          <Input name="idCart" />
        </FormFieldWrapper>
      </Row>
      <Row>
        <FormFieldWrapper
          span={24}
          name="address"
          placeholder={t("fields.address.placeholder")}
        >
          <Input name="address" />
        </FormFieldWrapper>
      </Row>
    </>
  );
});

export default BasicInfo;
