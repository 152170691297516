import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import "mobx-react-lite/batchingForReactDom";
import { observer } from "mobx-react";

import config from "./config";
import { Sidebar, Header, Loading } from "./components/private";
import { useStore } from "./hooks";
import { useMobile } from "./hooks/useMobile";
import { authentication, history, date, locales } from "./utils";

const { Footer, Content } = Layout;

function App() {
  const isMobile = useMobile();
  const { GlobalStore } = useStore();
  locales.init();

  isMobile && GlobalStore.setFullScreenMode(true);

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          {config.noNeedLoginRoutes.map((item) => (
            <Route
              key={item.path}
              path={item.path}
              component={item.component}
            />
          ))}
          <Main />
        </Switch>
      </Router>
    </div>
  );
}

const Main = authentication(
  observer(() => {
    const { GlobalStore, AuthStore } = useStore();
    const {
      collapsed,
      setCollapse,
      fullScreenMode,
      initial,
      loading,
    } = GlobalStore;

    const { hasAccessTo, fetch, fetchingProfile } = AuthStore;

    useEffect(() => {
      initial();
      fetch();
      setCollapse(window.innerWidth < 992);
    }, []);

    if (loading || fetchingProfile) return <Loading />;

    return (
      <Layout>
        <Sidebar fullScreenMode={fullScreenMode} collapsed={collapsed} />
        <Layout className="main">
          <Header collapsed={collapsed} toggleCollapse={setCollapse} />
          <Content className="content">
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  return <Redirect to="/posts" />;
                }}
              />
              {config.panelRoutes
                .filter((item) => hasAccessTo(item.id))
                .map(({ path, component: Comp, title, isExact = false }) => (
                  <Route
                    key={path}
                    path={path}
                    render={(props) => <Comp {...props} title={title} />}
                    exact={isExact}
                  />
                ))}
            </Switch>
          </Content>
          {/* <Footer>Footer</Footer> */}
        </Layout>
      </Layout>
    );
  })
);

export default App;
