import { toJS } from "mobx";
import {
  types,
  getParent,
  destroy,
  flow,
  applySnapshot,
} from "mobx-state-tree";
import shortid from "shortid";
import { api } from "../../../utils";

const DocModel = types
  .model({
    uid: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    thumb64: types.maybeNull(types.string),

    removing: types.optional(types.boolean, false),
    uploading: types.optional(types.boolean, false),
    canRemove: types.optional(types.boolean, true),
    uploadStatus: types.optional(
      types.maybe(types.enumeration(["success", "failed"])),
      "success"
    ),
    file: types.frozen({}),
  })
  .actions((self) => {
    return {
      apply: (key, value) => {
        self[key] = value;
      },

      remove: () => {
        self.apply("removing", true);

        setTimeout(() => {
          if (self.canRemove) {
            getParent(self, 2).removeDoc(self);
          }
          self.apply("removing", false);
        }, 3000);
      },

      reUpload: () => {
        getParent(self, 2).upload({
          target: { files: [self.file] },
          uid: self.uid,
        });
      },

      preventDeletion: () => {
        self.canRemove = false;
        self.removing = false;
      },
    };
  });

export const ShopDocumentsModel = types
  .model({
    documents: types.optional(types.array(DocModel), []),
  })
  .views((self) => ({
    get documentFiles() {
      return self.documents.map((img) => ({
        ...img,
        remove: img.remove,
        reUpload: img.reUpload,
        preventDeletion: img.preventDeletion,
      }));
    },
    get hasSomeFailedUploads() {
      return self.documents.some((doc) => doc.uploadStatus === "failed");
    },
  }))
  .actions((self) => {
    let uploadQueue = [];

    return {
      removeDoc: (childModel) => {
        destroy(childModel);
      },
      uploadProcess: flow(function* () {
        if (!uploadQueue.length) return false;
        const { file, model } = uploadQueue[0];

        self.setUploadLoading(true); //outside action
        try {
          const { data } = yield api.call("Shops", "addImage", [self.id, file]);
          self.modifyDocs({
            ...model,
            ...data,
            name: data.fileName,
            uid: model.uid,
            uploading: false,
          });
        } catch {
          self.modifyDocs({ ...model, uploading: false }, true);
        } finally {
          self.setUploadLoading(false); //outside action
          uploadQueue = uploadQueue.filter(
            (item) => item.model.uid !== model.uid
          );

          self.uploadProcess();
        }
      }),
      upload: ({ target: { files: docs }, uid: oldUid }) => {
        console.log(docs);
        let counter = 0;
        [...docs].forEach((img) => {
          let reader = new FileReader();
          reader.onload = ({ target: e }) => {
            counter++;
            const uid = oldUid || shortid.generate();
            const newDoc = DocModel.create({
              uid,
              uploading: true,
              thumb64: e.result,
              file: img,
            });
            self.modifyDocs(newDoc);
            uploadQueue.push({
              file: img,
              model: newDoc,
            });
            if (counter === docs.length) {
              self.uploadProcess();
            }
          };
          reader.readAsDataURL(img);
        });
      },

      modifyDocs: (incomingDoc, failed = false) => {
        const isDuplicated = self.documents.some(
          (item) => item.uid === incomingDoc.uid
        );

        if (isDuplicated) {
          const foundedIdx = self.documents.findIndex(
            ({ uid }) => uid === incomingDoc.uid
          );

          if (foundedIdx >= 0) {
            self.documents[foundedIdx] = {
              ...incomingDoc,
              thumb64: self.documents[foundedIdx]["thumb64"],
              uploadStatus: failed ? "failed" : "success",
            };
          }
        } else {
          applySnapshot(self.documents, [...self.documents, incomingDoc]);
        }
      },
    };
  })
  .preProcessSnapshot((sn) => {
    if (sn) {
      const shouldConvertDocuments =
        sn.documents?.length > 0 &&
        sn.documents.every((item) => typeof item === "string");
      return {
        ...sn,
        ...(shouldConvertDocuments && {
          documents: sn.documents.map((item) => ({
            uid: shortid.generate(),
            thumb64: item,
            url: item,
          })),
        }),
      };
    }
  });
