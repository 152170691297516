import React from "react";
import { Dropdown, Typography, Menu, Button } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import {
  TranslationOutlined,
  LogoutOutlined,
  DownOutlined,
} from "@ant-design/icons";

import "./HeaderLocales.less";
import { useStore } from "../../../hooks";
import { observer } from "mobx-react";
import { locales } from "../../../utils";

const { Text } = Typography;

const HeaderLocales = () => {
  return (
    <Dropdown overlay={DropdownMenu} trigger={["click"]}>
      <div className="header-locale">
        <TranslationOutlined />
      </div>
    </Dropdown>
  );
};

const DropdownMenu = () => {
  const { AuthStore } = useStore();

  return (
    <Menu selectedKeys={[locales.locale]}>
      <Menu.Item key="fa" onClick={() => locales.changeLocale("fa")}>
        <div>FA</div>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="ku" onClick={() => locales.changeLocale("ku")}>
        <div>KU</div>
      </Menu.Item>
    </Menu>
  );
};
export default observer(HeaderLocales);
