import React, { useRef, useState } from "react";
import { Button, Col, Row, Switch, Typography } from "antd";
import { InputNumber } from "formik-antd";

import "./WorkingTimeFields.less";
import { date } from "../../../../../utils";
import { TimePicker } from "../../../../../components/private";
import { useLocale, useMobile } from "../../../../../hooks";
import { ErrorMessage } from "formik";

const { Text } = Typography;

const SingleTimeField = ({ setFormikField, closedTime, value, name, idx }) => {
  const t = useLocale("components");

  const WEEK_DAYS = [
    t("workingTimeFields.weekend.saturday"),
    t("workingTimeFields.weekend.sunday"),
    t("workingTimeFields.weekend.monday"),
    t("workingTimeFields.weekend.tuesday"),
    t("workingTimeFields.weekend.wednesday"),
    t("workingTimeFields.weekend.thursday"),
    t("workingTimeFields.weekend.friday"),
  ];
  const isSmallDevice = useMobile(true);
  const [isClosed, setClosingStatus] = useState(
    Object.values(value).every((time) => time === closedTime)
  );

  const handleToggleSwitch = (isOpen) => {
    setClosingStatus(!isOpen);
    !isOpen &&
      setFormikField(name, {
        from: closedTime,
        to: closedTime,
      });
  };

  // const changeTime = (val, type) => {
  //   setFormikField(`${name}.${type}`, val);
  // };

  return (
    <Row className="workingTime__field" gutter={[10, 10]}>
      <Col span={4}>
        <Switch
          size={isSmallDevice ? "small" : "default"}
          defaultChecked={!isClosed}
          onChange={handleToggleSwitch}
        />
      </Col>
      <Col span={5}>
        <Text strong>{WEEK_DAYS[idx]}</Text>
      </Col>
      <Col span={15}>
        {isClosed ? (
          <Button block disabled size="large">
            {t("workingTimeFields.isClosed")}
          </Button>
        ) : (
          <Row gutter={isSmallDevice ? [0, 0] : [10, 0]}>
            <Col span={12}>
              <InputNumber
                className="hourPicker"
                min={0}
                keyboard
                max={24}
                name={`${name}.from`}
                // defaultValue={value["from"]}
                // onChange={(val) => changeTime(val, "from")}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                className="hourPicker"
                min={0}
                keyboard
                max={24}
                name={`${name}.to`}
                // defaultValue={value["to"]}
                // onChange={(val) => changeTime(val, "to")}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default SingleTimeField;
