/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/

import { types, flow } from "mobx-state-tree";
import { SHOPS } from "../constants";
import { api } from "../utils";
import { ShopModel } from "./models";
import { ShopListModel } from "./models/shop/ShopListModel";

const shopDefaultValue = {
  name: "",
  phoneNumber: "",
  categoryId: null,
  subCategoriesId: [],
  about: "",
  description: "",
  address: "",
  owner: "",
  idCart: "",
  location: null,
  documents: [],
  contactInfo: [],
  workingTimes: [
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 0,
      to: 0,
    },
  ],
};

const ShopStore = types
  .model("ShopStore", {
    shop: ShopModel,
    shopList: ShopListModel,
    activeTab: types.optional(
      types.union(
        types.literal("ACCEPTED"),
        types.literal("REJECTED"),
        types.literal("REMOVED"),
        types.literal("REPORTED"),
        types.literal("NOT_VERIFIED")
      ),
      "ACCEPTED"
    ),
    loading: types.optional(types.boolean, true),
  })
  .views((self) => ({
    getAllowedActions(
      options = { ignore: null, isSingleAction: true, status: null }
    ) {
      const source = SHOPS[options.isSingleAction ? "actions" : "batchActions"];
      const ignores = options.ignore ? options.ignore.split("|") : source;

      return source.filter(
        (item) =>
          item.key !== (options.status || self.activeTab) &&
          !ignores.includes(item.key)
      );
    },
    getAction(options = { name: null, isSingleAction: true }) {
      const source = SHOPS[options.isSingleAction ? "actions" : "batchActions"];
      return source.find((item) => item.key === options.name);
    },
    getStatus(data) {
      return api.get("Shops", data);
    },
  }))
  .actions((self) => ({
    changeTab: (key) => {
      const fetch = () => self.shopList.fetchInitial();

      self.activeTab = key;
      if (key === "REPORTED")
        self.shopList.changeFilter({ isReported: true }, fetch);
      else self.shopList.changeFilter({ status: self.getStatus(key) }, fetch);
    },
  }));

export default ShopStore.create({
  activeTab: "ACCEPTED",
  shop: shopDefaultValue,
  shopList: {},
});
