import createListStore from "../../../utils/createListStore";
import StaffModel from "./staffModel";

const StaffListModel = createListStore({
  itemModel: StaffModel,
  methods: {
    method: "User",
    initial: "getAllUsers",
  },
  pageSize: 20,
}).views((self) => ({
  get list() {
    return self.data.map((item) => ({
      ...item,
      openUpdateModal: item.openUpdateModal,
    }));
  },
}));

export default StaffListModel;
