import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GoogleMap, Marker } from "@react-google-maps/api";
import MapPinIcon from "./../../../assets/images/map-pinn.svg";

import "./Map.less";

const Map = ({ label, location, zoom, markerShown, onChange }) => {
  const [mapLocation, setMapLocation] = useState(location);

  const [markerLocation, setMarkerLocation] = useState(
    markerShown ? location : null
  );
  const hasMarker = markerLocation;

  useEffect(() => {
    if (location) setMapLocation(location);
  }, [location]);

  const markerOptions = {
    icon: {
      url: MapPinIcon,
    },
    ...(label && {
      label: {
        className: "map__label",
        text: label,
        fontFamily: "NRT",
        color: "white",
      },
    }),
  };

  const mapDefaultOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    //  gestureHandling: "none",
  };

  const handleClickOnMap = (e) => {
    const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setMarkerLocation(location);
    onChange(location);
  };

  return (
    <GoogleMap
      options={mapDefaultOptions}
      mapContainerClassName="map"
      center={mapLocation}
      zoom={zoom}
      onClick={handleClickOnMap}
    >
      {hasMarker && (
        <Marker options={markerOptions} position={markerLocation} />
      )}
    </GoogleMap>
  );
};

Map.defaultProps = {
  value: {},
};

Map.propTypes = {
  value: PropTypes.shape({
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    label: PropTypes.string,
  }),
};

export default React.memo(Map);
