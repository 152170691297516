import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Modal, Row, Col, Typography, Button, Divider } from "antd";
import { Form } from "formik-antd";
import { Formik } from "formik";
import { useParams } from "react-router-dom";

import { useLocale, useStore } from "../../../hooks";
import { FIELDS } from "../../../constants";
import { FormField } from "../../../components/private";
import SeoFieldsGenerator from "./SeoFieldsGenerator";
import { toJS } from "mobx";

const { Text } = Typography;

const CategoryModal = ({ title, data }) => {
  const { FieldsStore } = useStore();
  const { id: categoryId } = useParams();
  const {
    modal,
    editableData: { category },
    toggleModal,
    editMode,
  } = FieldsStore;
  const isCategory = modal === "category";
  const [formValues, setFormValues] = useState(
    FIELDS.category.defaultFormValues
  );

  const t = useLocale("fields/form");
  const [isInitialValid, setIsInitialValid] = useState(false);
  const schema = FIELDS.category.formValidation;

  useEffect(() => {
    schema
      .validate(formValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    const seo = editMode ? toJS(category.seo) : FIELDS.defaultSEO;
    if (isCategory)
      setFormValues({
        categoryTitle: category.categoryTitle,
        slug: category.slug,
        iconURL: category.iconURL,
        topCategory: category.topCategory,
        seo: seo,
        hide: category.hide,
        seoPattern: toJS(category.seoPattern),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCategory]);

  const onRequestClose = () => {
    toggleModal("category", FIELDS.category.defaultFormValues);
    setFormValues(FIELDS.category.defaultFormValues);
  };

  const handleSubmit = (form) => {
    if (editMode) {
      const item = data.find(
        (item) => item.key === String(category.categoryID)
      );
      item && item.update(form);
    } else {
      FieldsStore.createModel({
        form,
        categoryId,
        type: "category",
      });
    }
    onRequestClose();
  };

  return (
    <Modal
      title={title}
      className="category-modal"
      visible={isCategory}
      onCancel={onRequestClose}
      destroyOnClose
      footer={null}
      maskClosable={true}
      width={900}
    >
      <Formik
        initialValues={formValues}
        enableReinitialize
        validationSchema={schema}
        isInitialValid={isInitialValid}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form layout="vertical" size="large">
            <Text>{t("category.formTitle")}</Text>
            <Row
              justify="start"
              align="middle"
              gutter={[48, 0]}
              style={{ marginTop: 25 }}
            >
              <Col span={24}>
                <Row>
                  <Col span={22}>
                    <Row>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          loading={false}
                          name="categoryTitle"
                          placeholder={t(
                            "category.fields.categoryTitle.placeholder"
                          )}
                        />
                      </Col>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          loading={false}
                          name="iconURL"
                          placeholder={t("category.fields.iconURL.placeholder")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <Row>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          loading={false}
                          name="slug"
                          placeholder={t("category.fields.slug.placeholder")}
                        />
                      </Col>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          type="switch"
                          loading={false}
                          name="topCategory"
                          placeholder={t(
                            "category.fields.topCategory.placeholder"
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <Row>
                      <Col span={24} md={{ span: 12 }}>
                        <FormField
                          name="hide"
                          type="switch"
                          placeholder={t("category.fields.hide.placeholder")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <SeoFieldsGenerator
                  config={FIELDS.category.seoConfig}
                  formik={formik}
                />
              </Col>
            </Row>

            <Button
              htmlType="submit"
              style={{ marginLeft: 14 }}
              onClick={formik.handleSubmit}
              type="primary"
              size="middle"
            >
              {t(`category.buttons.${editMode ? "edit" : "add"}`)}
            </Button>

            <Button
              htmlType="button"
              size="middle"
              style={{ fontSize: 14 }}
              onClick={onRequestClose}
              type="default"
            >
              {t(`category.buttons.cancel`)}
            </Button>
          </Form>
        )}
      </Formik>
      {/* </div> */}
    </Modal>
  );
};
export default observer(CategoryModal);
