import * as yup from "yup";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useLocale } from "../hooks";

const err = (field) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useLocale("shops/form")(`fields.${field}.error`);
};

const TSL = (ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useLocale("shops/list")(ref);
};

const SHOPS = {
  createShopValidation() {
    return yup.object().shape({
      name: yup.string().required(err("name")),
      phoneNumber: yup.string().required(err("phoneNumber")),
      description: yup.string(),
      owner: yup.string().required(err("owner")),
      idCart: yup.string(),
      address: yup.string().required(err("address")),
      category: yup
        .number()
        .required(err("category"))
        .typeError(err("category")),
      city: yup.number().required(err("city")).typeError(err("city")),
      subCategories: yup
        .array()
        .of(yup.number())
        .when("category", {
          is: (val) => Boolean(val),
          then: yup
            .array()
            .of(yup.number())
            .typeError(err("subCategories"))
            .required(err("subCategories")),
          otherwise: yup.array().of(yup.number()),
        }),
      documents: yup.array().of(yup.string()),
      // logo: yup.string(),
      // cover: yup.string(),
      about: yup.string().required(err("about")),
      contact: yup.array().of(
        yup.object().shape({
          title: yup.string(),
          value: yup.string(),
        })
      ),
      workingTimes: yup
        .array()
        .required()
        .min(7, err("workingTimes"))
        .compact(function (item) {
          return item.from === null || item.to === null;
        }),
    });
  },

  tabs: [
    {
      key: "ACCEPTED",
      get title() {
        return TSL("tabs.accepted");
      },
    },
    {
      key: "REPORTED",
      get title() {
        return TSL("tabs.reported");
      },
    },
    {
      key: "NOT_VERIFIED",
      get title() {
        return TSL("tabs.notVerified");
      },
    },
    {
      key: "REJECTED",
      get title() {
        return TSL("tabs.rejected");
      },
    },
    {
      key: "REMOVED",
      get title() {
        return TSL("tabs.removed");
      },
    },
  ],
  actions: [
    {
      key: "ACCEPTED",
      get title() {
        return TSL("actions.accept.title");
      },
      confirmModal: {
        get title() {
          return TSL("actions.accept.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TSL("actions.accept.confirmModal.acceptText");
        },
        actionName: "accept",
      },
    },
    {
      key: "NOT_VERIFIED",
      get title() {
        return TSL("actions.unverify.title");
      },
      confirmModal: {
        get title() {
          return TSL("actions.unverify.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TSL("actions.unverify.confirmModal.acceptText");
        },
        actionName: "unverify",
      },
    },
    {
      key: "REJECTED",
      get title() {
        return TSL("actions.reject.title");
      },
      confirmModal: {
        get title() {
          return TSL("actions.reject.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TSL("actions.reject.confirmModal.acceptText");
        },
        actionName: "reject",
      },
    },
    {
      key: "REMOVED",
      get title() {
        return TSL("actions.remove.title");
      },
      confirmModal: {
        get title() {
          return TSL("actions.remove.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "danger",
        get okText() {
          return TSL("actions.remove.confirmModal.acceptText");
        },
        actionName: "delete",
      },
    },
  ],
  batchActions: [
    {
      key: "REMOVED",
      get title() {
        return TSL("batchActions.remove.title");
      },
      confirmModal: {
        get title() {
          return TSL("batchActions.remove.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "danger",
        get okText() {
          return TSL("batchActions.remove.confirmModal.acceptText");
        },

        actionName: "batchDelete",
      },
    },
    {
      key: "REJECTED",
      get title() {
        return TSL("batchActions.reject.title");
      },
      confirmModal: {
        get title() {
          return TSL("batchActions.reject.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TSL("batchActions.reject.confirmModal.acceptText");
        },

        actionName: "batchReject",
      },
    },
    {
      key: "NOT_VERIFIED",
      get title() {
        return TSL("batchActions.unverify.title");
      },
      confirmModal: {
        get title() {
          return TSL("batchActions.unverify.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TSL("batchActions.unverify.confirmModal.acceptText");
        },
        actionName: "batchUnverify",
      },
    },
    {
      key: "ACCEPTED",
      get title() {
        return TSL("batchActions.accept.title");
      },
      confirmModal: {
        get title() {
          return TSL("batchActions.accept.confirmModal.title");
        },
        icon: ExclamationCircleOutlined,
        okType: "default",
        get okText() {
          return TSL("batchActions.accept.confirmModal.acceptText");
        },
        actionName: "batchAccept",
      },
    },
  ],
};

export default SHOPS;
