import React from "react";
import { Input } from "formik-antd";
import { FieldArray } from "formik";
import { Row, Col, Button, Tooltip, Typography, notification } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { FormFieldWrapper } from "../../../../../components/private";
import { useLocale, useMobile } from "../../../../../hooks";
import "./ContactFieldGenerator.less";

const { Title } = Typography;

const ContactFieldGenerator = ({ values, placeholder }) => {
  const isSmallDevice = useMobile(true);
  const fullSpan = 24;
  const halfSPan = isSmallDevice ? fullSpan : 11;
  const t = useLocale("components");

  return (
    <Row>
      <Col span={24} className="contactFieldGenerator">
        <Title className="contactFieldGenerator__title" level={4}>
          {placeholder} :
        </Title>
        <FieldArray
          name="contactInfo"
          render={(arrayHelpers) => (
            <div>
              {values.contactInfo &&
                values.contactInfo.length > 0 &&
                values.contactInfo.map((item, index) => (
                  <Row key={index}>
                    <FormFieldWrapper
                      span={halfSPan}
                      name={`contactInfo.${index}.title`}
                      placeholder={t("contactFieldGenerator.title.label")}
                    >
                      <Input
                        name={`contactInfo.${index}.title`}
                        placeholder={t(
                          "contactFieldGenerator.title.placeholder"
                        )}
                      />
                    </FormFieldWrapper>
                    <FormFieldWrapper
                      span={halfSPan}
                      name={`contactInfo.${index}.value`}
                      placeholder={t("contactFieldGenerator.value.label")}
                    >
                      <Input
                        name={`contactInfo.${index}.value`}
                        placeholder={t(
                          "contactFieldGenerator.value.placeholder"
                        )}
                      />
                    </FormFieldWrapper>

                    <Col className="removeField" span={isSmallDevice ? 24 : 2}>
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        danger
                        block
                        type="dashed"
                        onClick={() => arrayHelpers.remove(index)}
                        icon={<MinusOutlined />}
                      >
                        {isSmallDevice &&
                          t("contactFieldGenerator.removeButton")}
                      </Button>
                    </Col>
                  </Row>
                ))}
              <Row>
                <Col className="addFieldContainer" span={24}>
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    type="primary"
                    ghost
                    block={isSmallDevice}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      if (
                        values.contactInfo.every(
                          (item) => item.title && item.value
                        )
                      )
                        arrayHelpers.push({
                          title: "",
                          value: "",
                        });
                      else
                        notification.warn({
                          message: t(
                            "contactFieldGenerator.notification.title"
                          ),
                          description: t(
                            "contactFieldGenerator.notification.description"
                          ),
                        });
                    }}
                  >
                    {t("contactFieldGenerator.addButton")}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        />
      </Col>
    </Row>
  );
};

export default ContactFieldGenerator;
