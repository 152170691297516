// add this function to use it in fake fetching data delay
const later = (value, delay) =>
  new Promise((resolve) => setTimeout(resolve, delay, value));

class FakeApi {
  call = (value, delay = 1000) => later(value, delay);
}

const fakeApi = new FakeApi();
export default fakeApi;
