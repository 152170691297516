import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import objectSupport from "dayjs/plugin/objectSupport";
import "dayjs/locale/ku";
import "dayjs/locale/fa";

dayjs.extend(relativeTime);
dayjs.extend(objectSupport);
class MyDate {
  config(lng) {
    dayjs.locale(lng);
  }
  fromNow(date) {
    return dayjs(date * 1000).fromNow();
  }
  get(date) {
    this.config("ku");
    return dayjs(date * 1000).format("dddd D MMM - HH:mm");
  }
  getDay(timestamp) {
    return dayjs(timestamp * 1000).format("dddd D MMMM");
  }
  getTime(time) {
    return dayjs(time).format("HH:mm");
  }
  getDateFromTime(obj) {
    return dayjs(obj);
  }
}

const date = new MyDate();
export default date;
