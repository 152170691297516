import { flow, types } from "mobx-state-tree";
import { useStore } from "../hooks";
import { api } from "../utils";
import { AdminModel } from "./models";

const AuthStore = types
  .model("AuthStore", {
    adminStatus: types.optional(
      types.enumeration(["login", "loading", "guest"]),
      "loading"
    ),
    admin: types.maybeNull(AdminModel),
    OTPStep: types.optional(types.boolean, false),
    ACL: types.frozen({
      admin: [
        "PAGE_STAFF",
        "PAGE_POSTS",
        "SINGLE_POST",
        "CATEGORY_LIST",
        "SHOPS_LIST",
        "SINGLE_SHOP",
      ],
      post: ["SINGLE_POST", "PAGE_POSTS"],
      category: ["CATEGORY_LIST"],
      shop: ["SINGLE_SHOP", "SHOPS_LIST"],
      marketing: [],
    }),
    //loadings
    fetchingProfile: types.optional(types.boolean, true),
    OTPSending: types.optional(types.boolean, false),
    loginLoading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isAuthenticated() {
      return self.adminStatus === "login";
    },
    hasAccessTo(key) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { AuthStore } = useStore();

      if (!AuthStore.admin) return true;
      else return (self.ACL[AuthStore.admin.role] || []).includes(key);
    },
  }))
  .actions((self) => {
    let recaptchaRef, verifier;

    return {
      login: flow(function* ({ otp }) {
        self.loginLoading = true;

        try {
          yield api.call("Auth", "confirmVerificationCode", [otp, verifier]);
          yield self.fetch();
        } finally {
          self.loginLoading = false;
        }
      }),

      fetch: flow(function* () {
        self.fetchingProfile = true;

        try {
          const { data } = yield api.call("Auth", "me");
          console.log({ data });
          self.admin = { ...data };
          self.adminStatus = "login";
          self.fetchingProfile = false;
        } catch {
          self.adminStatus = "guest";
          self.fetchingProfile = false;
        }
      }),
      sendOTP: flow(function* (values) {
        const { phoneRegion, phone } = values;
        const phoneNumber = `${phoneRegion}${
          phone.startsWith("0") ? phone.slice(1) : phone
        }`;

        self.OTPSending = true;

        const recaptcha = api.static("Auth", "setupRecaptcha", [recaptchaRef]);

        try {
          const { data } = yield api.call("Auth", "sendVerificationCode", [
            phoneNumber,
            recaptcha,
          ]);

          verifier = data;
          self.loginStep = "otp";
        } finally {
          self.OTPSending = false;
        }
      }),

      logout: flow(function* () {
        yield api.call("Auth", "signOut");
        self.adminStatus = "guest";
        self.user = {};
      }),

      setOTP: (isOTPStep) => (self.OTPStep = isOTPStep),
      setRecaptcha: (ref) => (recaptchaRef = ref),
      apply: (key, value) => (self[key] = value),
    };
  });

export default AuthStore.create({
  loading: true,
  adminInfo: null,
  OTPSending: false,
  admin: {},
});
