import * as yup from "yup";

const defaultSeoValue = {
  title: "هەرزان، بازاڕی ئۆنلاین بۆ کڕین و فرۆشتنی کاڵای نوێ و دەسی دوو",
  description:
    "ڕاستەوخۆ و بەبێ نێوانگر و بەتەواوی بێبەرانبەر لە هەرزاندا بکڕن و بفرۆشن. هەزاران کاڵا لە دەستەبەندییە جۆربەجۆرەکاندا لە هەموو کوردستان.",
  keywords:
    "کڕین و فرۆشتن، هەرزان، بازاڕی ئۆنلاین، کڕین و فرۆشتنی ئۆنلاین، کوردستان، دەسی دوو، کاڵا و کەلوپەلی بەکارهاتوو",
};

const FIELDS = {
  defaultSEO: defaultSeoValue,
  category: {
    formValidation: yup.object().shape({
      categoryTitle: yup.string().required("ورود عنوان الزامی است."),
      slug: yup.string().required("ورود اسلاگ الزامی است."),
      iconURL: yup.string().required("ورود ایکون الزامی است."),
      topCategory: yup.boolean().required("مقدار منتخب بودن الزامی است"),
      hide: yup.boolean().required("مقدار پنهان بودن الزامی است"),
      seo: yup.object().required().shape({
        title: yup.string(),
        description: yup.string(),
        keywords: yup.string(),
      }),
      seoPattern: yup.lazy((value) => {
        switch (typeof value) {
          case "object":
            return yup.object().shape({
              title: yup.string(),
              description: yup.string(),
              keywords: yup.string(),
            });

          default:
          case "boolean":
            return yup.boolean();
        }
      }),
    }),

    defaultFormValues: {
      categoryTitle: "",
      slug: "",
      iconURL: "",
      topCategory: false,
      hide: false,
      seo: {
        title: "",
        description: "",
        keywords: "",
      },
      seoPattern: false,
    },
    seoConfig: {
      seo: {
        type: "object",
        mayeb: false,
      },
      seoPattern: {
        maybe: true,
      },
    },
  },
  subCategory: {
    formValidation: yup.object().shape({
      categoryTitle: yup.string().required("ورود عنوان الزامی است."),
      slug: yup.string().required("ورود اسلاگ الزامی است."),
      hasPrice: yup.boolean().required("مقدار قیمت داشتن الزامی است"),
      hasRent: yup.boolean().required("مقدار اجاره داشتن الزامی است"),
      hide: yup.boolean().required("مقدار پنهان بودن الزامی است"),
      seo: yup.lazy((value) => {
        switch (typeof value) {
          case "object":
            return yup.object().shape({
              title: yup.string(),
              description: yup.string(),
              keywords: yup.string(),
            });

          default:
          case "string":
            return yup.string().matches(/pattern/);
        }
      }),
    }),
    defaultFormValues: {
      categoryTitle: "",
      slug: "",
      hasPrice: false,
      hasRent: false,
      hide: false,
      seo: defaultSeoValue,
    },
    seoConfig: {
      seo: {
        type: "mixed",
        maybe: false,
      },
    },
  },
  attribute: {
    formValidation: yup.object().shape({
      attributeTitle: yup.string().required("ورود عنوان الزامی است."),
      slug: yup.string().required("ورود اسلاگ الزامی است."),
      custom_validate_regex_message: yup.string(),
      custom_validate_regex: yup.string(),
      type: yup.string().required(),
      isSearchable: yup.boolean().required(),
      required: yup.boolean().required(),
      isFilterable: yup.boolean().required(),
      isRange: yup.boolean().required(),
      seoPattern: yup.lazy((value) => {
        switch (typeof value) {
          default:
          case "object":
            return yup.object().shape({
              title: yup.string(),
              description: yup.string(),
              keywords: yup.string(),
            });

          case "boolean":
            return yup.boolean();
        }
      }),
    }),
    defaultFormValues: {
      attributeTitle: "",
      slug: "",
      custom_validate_regex_message: "",
      type: "",
      custom_validate_regex: "",
      isFilterable: false,
      isSearchable: false,
      isRange: false,
      required: false,
      seoPattern: defaultSeoValue,
    },
    seoConfig: {
      seoPattern: {
        maybe: true,
      },
    },
  },

  option: {
    formValidation: yup.object().shape({
      title: yup.string().required("ورود عنوان الزامی است."),
      seo: yup.lazy((value) => {
        switch (typeof value) {
          case "string":
            return yup.string().matches(/pattern/);
          default:
          case "boolean":
            return yup.boolean();
        }
      }),
    }),
    defaultFormValues: {
      title: "",
      seo: "pattern",
    },
    seoConfig: {
      seo: {
        type: "string",
        maybe: true,
      },
    },
  },
};

export default FIELDS;
