import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Modal, Row, Col, Button, Divider } from "antd";
import { Form } from "formik-antd";
import { Formik } from "formik";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

import { useLocale, useStore } from "../../../hooks";
import { FIELDS } from "../../../constants";
import { FormField } from "../../../components/private";
import SeoFieldsGenerator from "./SeoFieldsGenerator";

const OptionModal = ({ data, title }) => {
  const { FieldsStore } = useStore();
  const params = useParams();
  const { search } = useLocation();
  const {
    modal,
    editableData: { options },
    toggleModal,
    editMode,
  } = FieldsStore;
  const isAttribute = modal === "options";
  const t = useLocale("fields/form");

  const [formValues, setFormValues] = useState(FIELDS.option.defaultFormValues);
  const [isInitialValid, setIsInitialValid] = useState(false);
  const schema = FIELDS.option.formValidation;

  useEffect(() => {
    schema
      .validate(formValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    if (isAttribute)
      setFormValues({
        title: options.title,
        seo: options.seo,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAttribute]);

  const onRequestClose = () => {
    toggleModal("options", FIELDS.option.defaultFormValues);
    setFormValues(FIELDS.option.defaultFormValues);
  };

  const handleSubmit = (form) => {
    if (editMode) {
      const item = data.find((item) => item.key === String(options.optionID));
      item && item.update(form);
    } else {
      const req = {
        form,
        type: "options",
      };
      const { type, category, subcat } = queryString.parse(search);
      if (type === "attributes") {
        req.categoryId = category;
        req.subCategoryId = subcat;
        req.attributeId = params.id;
      } else if (type === "category") {
        req.categoryId = params.id;
      } else if (type === "subcat") {
        req.categoryId = category;
        req.subCategoryId = params.id;
      }
      FieldsStore.createModel(req);
    }
    onRequestClose();
  };

  return (
    <Modal
      title={title}
      className="option-modal"
      visible={isAttribute}
      onCancel={onRequestClose}
      destroyOnClose
      footer={null}
      maskClosable={true}
      width={900}
    >
      <Formik
        initialValues={formValues}
        enableReinitialize
        validationSchema={schema}
        isInitialValid={isInitialValid}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form layout="vertical" size="large">
            <Row justify="start" gutter={[48, 0]} style={{ marginTop: 25 }}>
              <Col span={12}>
                <FormField
                  name="title"
                  placeholder={t("options.fields.title.placeholder")}
                />
              </Col>
            </Row>
            <Divider />
            <SeoFieldsGenerator
              config={FIELDS.option.seoConfig}
              formik={formik}
            />
            <Button
              htmlType="submit"
              style={{ marginLeft: 14 }}
              onClick={formik.handleSubmit}
              type="primary"
              size="middle"
            >
              {t(`options.buttons.${editMode ? "edit" : "add"}`)}
            </Button>

            <Button
              htmlType="button"
              size="middle"
              style={{ fontSize: 14 }}
              onClick={onRequestClose}
              type="default"
            >
              {t(`options.buttons.cancel`)}
            </Button>
          </Form>
        )}
      </Formik>
      {/* </div> */}
    </Modal>
  );
};
export default observer(OptionModal);
