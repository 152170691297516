import { flow } from "mobx-state-tree";
import { useStore } from "../../../hooks";
import { api } from "../../../utils";
import createListStore from "../../../utils/createListStore";
import { PostItemModel } from "./postItemModel";

export const PostListModel = createListStore({
  itemModel: PostItemModel,
  methods: {
    method: "Post",
    initial: "getAllPosts",
  },
  pageSize: 20,
  sort: "created",
})
  .views((self) => ({
    get list() {
      return self.data.map((item) => ({
        ...item,
        delete: item.delete,
        reject: item.reject,
        accept: item.accept,
        renew: item.renew,
        unverify: item.unverify,
        kill: item.kill,
      }));
    },
  }))
  .actions((self) => {
    const { PostStore } = useStore();

    const batchUpdateStatus = flow(function* (status) {
      try {
        yield api.call("Post", "batchUpdateStatus", [
          self.selectedKeys,
          { status: PostStore.getStatus(status) },
        ]);
        self.selection.forEach((item) => item.kill());
        self.selectedKeys = [];
      } catch {}
    });

    return {
      batchAccept: () => batchUpdateStatus("ACCEPTED"),
      batchReject: () => batchUpdateStatus("REJECTED"),
      batchUnverify: () => batchUpdateStatus("NOT_VERIFIED"),
    };
  });
