import { flow } from "mobx-state-tree";
import { useStore } from "../../../hooks";
import { api } from "../../../utils";
import createListStore from "../../../utils/createListStore";
import { ShopItemModel } from "./ShopItemModel";

export const ShopListModel = createListStore({
  itemModel: ShopItemModel,
  methods: {
    method: "Shops",
    initial: "getShops",
  },
  pageSize: 20,
  sort: "created",
})
  .views((self) => ({
    get list() {
      return self.data.map((item) => ({
        ...item,
        delete: item.delete,
        reject: item.reject,
        accept: item.accept,
        unverify: item.unverify,
        kill: item.kill,
      }));
    },
  }))
  .actions((self) => {
    const { ShopStore } = useStore();

    const batchUpdateStatus = flow(function* (status) {
      try {
        yield api.call("Shops", "batchUpdateStatus", [
          self.selectedKeys,
          { status: ShopStore.getStatus(status) },
        ]);
        self.selection.forEach((item) => item.kill());
        self.selectedKeys = [];
      } catch {}
    });

    return {
      batchAccept: () => batchUpdateStatus("ACCEPTED"),
      batchReject: () => batchUpdateStatus("REJECTED"),
      batchUnverify: () => batchUpdateStatus("NOT_VERIFIED"),
    };
  });
