/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import { Layout } from "antd";

import "../fields.less";
import { pageWrapper } from "../../../utils";
import { useStore } from "../../../hooks";
import { FieldsTable } from "../components";

const { Content } = Layout;

const ReviewPage = () => {
  const { FieldsStore } = useStore();

  return (
    <Content className="fields">
      <FieldsTable data={FieldsStore.reviews} exportable />
    </Content>
  );
};

const Review = pageWrapper(observer(ReviewPage), {
  hasHeader: true,
  subTitle: "در این بخش توضیحات قرار میگیرد.",
});

export { Review };
