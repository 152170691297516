import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Space, Typography, Switch } from "antd";
import { FormField } from "../../../components/private";
import { FIELDS } from "../../../constants";
import { useLocale } from "../../../hooks";

const { Text } = Typography;

const SeoFieldsGenerator = ({ config, formik }) => {
  const { seo, seoPattern } = config;

  return (
    <div className="seoFieldsGenerator">
      {seo && <SeoForm formik={formik} seo={seo} />}
      {seoPattern && <SeoPatternForm formik={formik} seoPattern={seoPattern} />}
    </div>
  );
};

const SeoForm = ({ seo, formik }) => {
  const translate = useLocale("components");
  const t = (ref) => translate(`seoFieldsGenerator.seoForm.${ref}`);

  const [formVisibility, setFormVisibility] = useState(
    typeof formik.values.seo !== "boolean"
  );
  const { type } = seo;
  const [seoType, setSeoType] = useState(typeof formik.values.seo);

  useEffect(() => {
    setFormVisibility(seoType !== "boolean");
  }, [seoType]);

  useEffect(() => {
    setSeoType(typeof formik.values.seo);
  }, [typeof formik.values.seo]);

  const changeSeoType = (checked) => {
    formik.setFieldValue("seo", checked ? FIELDS.defaultSEO : "pattern");
  };

  const onSwitchChange = (checked) => {
    formik.setFieldValue(
      "seo",
      checked ? (type === "string" ? "pattern" : FIELDS.defaultSEO) : false
    );
  };

  const renderSeoFields = () => {
    if (seoType === "string") {
      return (
        <Row style={{ marginTop: 25 }}>
          <Col span={16}>
            <FormField
              name="seo"
              placeholder={t("fields.seo.placeholder")}
              readonly
            />
          </Col>
        </Row>
      );
    } else if (seoType === "object") {
      return (
        <React.Fragment>
          <Row style={{ marginTop: 25 }}>
            <Col span={22}>
              <FormField
                name="seo.title"
                placeholder={t("fields.title.placeholder")}
              />
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <FormField
                loading={false}
                name="seo.description"
                placeholder={t("fields.description.placeholder")}
                multiLine
              />
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <FormField
                loading={false}
                name="seo.keywords"
                placeholder={t("fields.keywords.placeholder")}
                value={formik.values.seo.keywords}
                onChange={(data) => {
                  formik.setFieldValue("seo.keywords", data.join(","));
                }}
                type="tag"
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  };

  return (
    <div className="seoForm">
      <Row style={{ margin: "25px 0" }}>
        <Col span={16}>
          <Text>{t("formTitle")}</Text>
        </Col>
        <Col span={6} style={{ justifyContent: "flex-end", display: "flex" }}>
          <Space>
            {!seo.maybe && (
              <Text type="primary">{t("fields.isRequired.placeholder")}</Text>
            )}

            <Switch
              disabled={!seo.maybe}
              checked={formVisibility}
              onChange={onSwitchChange}
            />
          </Space>
        </Col>
      </Row>

      {formVisibility && (
        <React.Fragment>
          {type === "mixed" && (
            <Row style={{ margin: "20px 0" }}>
              <Col span={16}>
                <Text>{t("fields.type.placeholder")}</Text>
              </Col>
              <Col
                span={6}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                <Space>
                  <Text>{t("fields.type.pattern.placeholder")}</Text>
                  <Switch
                    checked={seoType === "object"}
                    onChange={changeSeoType}
                  />
                  <Text>{t("fields.type.form.placeholder")}</Text>
                </Space>
              </Col>
            </Row>
          )}
          {renderSeoFields()}
        </React.Fragment>
      )}
    </div>
  );
};

const SeoPatternForm = ({ seoPattern, formik }) => {
  const [formVisibility, setFormVisibility] = useState(
    typeof formik.values.seoPattern === "object"
  );
  const translate = useLocale("components");
  const t = (ref) => translate(`seoFieldsGenerator.seoPatternForm.${ref}`);

  useEffect(() => {
    setFormVisibility(typeof formik.values.seoPattern === "object");
  }, [typeof formik.values.seoPattern]);

  const onSwitchChange = (checked) => {
    formik.setFieldValue("seoPattern", checked ? FIELDS.defaultSEO : false);
    setFormVisibility(checked);
  };

  return (
    <div className="seoPatternForm">
      <Row style={{ margin: "50px 0" }}>
        <Col span={16}>
          <Text>{t("formTitle")}</Text>
        </Col>
        <Col span={6} style={{ justifyContent: "flex-end", display: "flex" }}>
          <Space>
            {!formVisibility && <Text type="secondary">false</Text>}
            <Switch
              disabled={!seoPattern.maybe}
              checked={formVisibility}
              onChange={onSwitchChange}
            />
          </Space>
        </Col>
      </Row>

      {formVisibility && (
        <React.Fragment>
          <Row style={{ marginTop: 25 }}>
            <Col span={22}>
              <FormField
                name="seoPattern.title"
                placeholder={t("fields.title.placeholder")}
              />
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <FormField
                name="seoPattern.description"
                placeholder={t("fields.description.placeholder")}
                multiLine
              />
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <FormField
                name="seoPattern.keywords"
                placeholder={t("fields.keywords.placeholder")}
                value={formik.values.seoPattern.keywords}
                onChange={(data) => {
                  formik.setFieldValue("seoPattern.keywords", data.join(","));
                }}
                type="tag"
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

export default SeoFieldsGenerator;
