import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
// import { Skeleton, Typography } from "antd";
// import { SyncOutlined } from "@ant-design/icons";

// import logo from "./../../../assets/images/logo.svg";

import "./Loading.less";
// const { Text } = Typography;

const Loading = () => {
  return (
    <div className="loading-wrapper">
      <LoadingOutlined className="loading-wrapper__spinner" />
    </div>
  );
  // return (
  //   <div className="loading-view">
  //     <div className="loading-view__sidebar">
  //       <Skeleton.Button className="loading-skeleton" active></Skeleton.Button>
  //     </div>
  //     <div className="loading-view__content">
  //       <header>
  //         <Skeleton.Button
  //           className="loading-skeleton"
  //           active
  //         ></Skeleton.Button>
  //       </header>
  //       <div className="body">
  //         <div className="body__article">
  //           <header>
  //             <div>
  //               <Skeleton.Button
  //                 className="loading-skeleton"
  //                 active
  //               ></Skeleton.Button>
  //             </div>
  //             <div>
  //               <Skeleton.Button
  //                 className="loading-skeleton"
  //                 active
  //               ></Skeleton.Button>
  //             </div>
  //             <div>
  //               <Skeleton.Button
  //                 className="loading-skeleton"
  //                 active
  //               ></Skeleton.Button>
  //             </div>
  //           </header>
  //           <img src={logo} alt="logo" />
  //           {/* <Alert
  //             style={{ width: 500 }}
  //             banner
  //             icon={<UserOutlined spin />}
  //             message={"welcome to the my world"}
  //           /> */}
  //           <div>
  //             <div className="message">
  //               <Text className="message__text" strong>
  //                 در حال بارگیری
  //                 <SyncOutlined spin style={{ marginRight: 20 }} />
  //               </Text>
  //               <Skeleton.Button
  //                 className="loading-skeleton"
  //                 active
  //               ></Skeleton.Button>
  //             </div>
  //             <div>
  //               <Skeleton
  //                 paragraph={{ rows: 5 }}
  //                 className="loading-skeleton"
  //                 active
  //               ></Skeleton>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="body__aside">
  //           <Skeleton
  //             paragraph={{ rows: 20 }}
  //             className="loading-skeleton"
  //             active
  //           ></Skeleton>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Loading;
