class Utils {
  cloneObj(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  chunkArray(arr, chunk_size) {
    let result = [];
    for (let index = 0; index < arr.length; index += chunk_size) {
      const myChunk = arr.slice(index, index + chunk_size);
      result.push(myChunk);
    }
    return result;
  }
  fieldsMap(arr = []) {
    return arr
      .filter((item) => !item.removed)
      .map((item) => {
        delete item.new;
        delete item.updated;
        delete item.firstData;
        delete item.removed;
        delete item.changeType;
        return item;
      });
  }
  fieldID(mainID = 0, data, keyID, unit) {
    if (unit) {
      let [last = Number(mainID)] = data
        .map((item) => Number(item[keyID]))
        .sort((a, b) => b - a);
      return last + unit;
    } else {
      return Date.now();
    }
  }
  toEnglishDigits = (str) => {
    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    var e = "۰".charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = "٠".charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  };
  websiteUrl = (postId) => {
    const devPrefix = process.env.REACT_APP_ENV === "development" ? "dev." : "";
    const lang = "ku"; //TODO: after add multi language . this code must be changed
    const address = `https://${devPrefix}harzaan.com/${lang}/post/${postId}`;
    return address;
  };
}
const utils = new Utils();
export default utils;
