import React, { useEffect, useState } from "react";
import { Upload, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const ImageList = ({ images, onRemove, action, loading, onChange }) => {
  const [fileList, setFileList] = useState(images);

  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
  });

  useEffect(() => {
    onChange(fileList.filter((item) => item.status !== "error"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const handleChange = (info) => {
    const { fileList: newFileList } = info;
    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const handleCancel = () => setState({ ...state, previewVisible: false });

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const upload = ({ file }) => {
    action(file)
      .then((file) => {
        setFileList([...fileList, file]);
      })
      .catch((file) => {
        setFileList([...fileList, file]);
      });
  };

  return (
    <React.Fragment>
      <Upload
        listType="picture-card"
        fileList={fileList}
        name="images"
        customRequest={upload}
        onChange={handleChange}
        onRemove={onRemove}
        onPreview={handlePreview}
      >
        {!loading && fileList.length < 5 ? uploadButton : null}
      </Upload>

      <Modal
        visible={state.previewVisible}
        title={"تصویر"}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={state.previewImage} />
      </Modal>
    </React.Fragment>
  );
};

export default ImageList;
