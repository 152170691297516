import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Select } from "formik-antd";
import { Formik } from "formik";
import { Row, Col, Typography, Button } from "antd";

import { STAFF } from "../../../constants";
import { useLocale } from "../../../hooks";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const InformationStep = ({ next, defaultValues, prev }) => {
  const [isInitialValid, setIsInitialValid] = useState(false);
  const schema = STAFF.informationValidation();
  const t = useLocale("staff/form");

  useEffect(() => {
    schema
      .validate(defaultValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <div>
      <Formik
        initialValues={defaultValues}
        enableReinitialize
        validationSchema={schema}
        isInitialValid={isInitialValid}
        onSubmit={next}
      >
        {(formik) => (
          <Form layout="vertical" size="large">
            <Row justify="center" align="middle">
              <Col span={12} xs={{ span: 24 }}>
                <Text strong>{t("steps.information.formTitle")}</Text>

                <Form.Item style={{ marginTop: 16 }} name="displayName">
                  <Input
                    name="displayName"
                    placeholder={t(
                      "steps.information.fields.displayName.placeholder"
                    )}
                  />
                </Form.Item>
                <Form.Item name="email">
                  <Input
                    name="email"
                    placeholder={t(
                      "steps.information.fields.email.placeholder"
                    )}
                  />
                </Form.Item>

                <Row justify="space-between" align="middle">
                  <Col span={12}>
                    <Form.Item name="gender">
                      <Select
                        name="gender"
                        placeholder={t(
                          "steps.information.fields.gender.placeholder"
                        )}
                      >
                        <Option value="male">
                          {t("steps.information.fields.gender.values.male")}
                        </Option>
                        <Option value="female">
                          {t("steps.information.fields.gender.values.female")}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item name="age">
                      <InputNumber
                        min={0}
                        max={80}
                        style={{ width: "100%" }}
                        name="age"
                        placeholder={t(
                          "steps.information.fields.age.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="address">
                  <TextArea
                    style={{ maxHeight: 50 }}
                    name="address"
                    placeholder={t(
                      "steps.information.fields.address.placeholder"
                    )}
                  />
                </Form.Item>
                <Form.Item name="phoneNumber">
                  <Input
                    name="phoneNumber"
                    className="phone__field"
                    placeholder={t(
                      "steps.information.fields.phoneNumber.placeholder"
                    )}
                  />
                </Form.Item>
                <Button
                  htmlType="submit"
                  style={{ fontSize: 14 }}
                  disabled={!formik.isValid}
                  onClick={formik.handleSubmit}
                  type="primary"
                >
                  {t("steps.information.submitButton")}
                </Button>
                <Button
                  htmlType="button"
                  onClick={prev}
                  style={{ margin: "0 8px", fontSize: 14 }}
                >
                  {t("steps.information.backButton")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default InformationStep;
