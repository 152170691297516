import { types, flow, applySnapshot, getSnapshot } from "mobx-state-tree";
import shortid from "shortid";

import { api, utils } from "../../../utils";
import { useStore } from "../../../hooks";
import { PostImagesModel } from "./postImagesModel";
import { ShopModel } from "../shop/ShopModel";
const shopDefaultValue = {
  name: "",
  phoneNumber: "",
  categoryId: null,
  subCategoriesId: [],
  about: "",
  description: "",
  address: "",
  owner: "",
  idCart: "",
  location: null,
  documents: [],
  contactInfo: [],
  workingTimes: [
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 7,
      to: 22,
    },
    {
      from: 0,
      to: 0,
    },
  ],
};
export const PostModel = types
  .compose(
    "PostModel",
    types.model("PostModel", {
      id: types.maybeNull(types.string),
      title: types.maybeNull(types.string),
      uid: types.maybeNull(types.string),
      vip: types.optional(types.boolean, false),
      body: types.maybeNull(types.string),
      currency: types.maybeNull(types.string),
      phoneNumber: types.maybeNull(types.string),
      price: types.maybeNull(types.string),
      rent: types.maybeNull(types.string),
      status: types.maybeNull(types.number),
      category: types.maybeNull(types.number),
      subCategory: types.maybeNull(types.number),
      city: types.maybeNull(types.number),
      created: types.maybeNull(types.number),
      expiresAt: types.maybeNull(types.number),
      hasImage: types.maybeNull(types.boolean),
      isReported: types.maybeNull(types.boolean),
      shop: types.maybeNull(ShopModel),
      specialAttributes: types.optional(
        types.array(
          types.model({
            label: types.string,
            value: types.string,
          })
        ),
        []
      ),
    }),
    PostImagesModel,
    types.model({
      fetchLoading: types.optional(types.boolean, true),
      updateAndCreateLoading: types.optional(types.boolean, false),
      uploadLoading: types.optional(types.boolean, false),
      actionLoading: types.optional(types.boolean, false),
    })
  )
  .views((self) => ({
    // get getImages() {
    //   return self.images.map((item) => ({
    //     uid: shortid.generate(),
    //     name: "post image",
    //     status: "done",
    //     url: item.origin,
    //     origin: item.origin,
    //     thumb: item.thumb,
    //     preview: item.preview,
    //   }));
    // },

    get attributes() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { getSubCat } = useStore()["GlobalStore"];

      if (!self.category || !self.subCategory) return [];
      return (getSubCat(self.category, self.subCategory).attributes || [])
        .map((item) => {
          return {
            id: item.attributeID,
            title: item.attributeTitle,
            placeholder: item.placeholder,
            order: item.attributeOrder,
            required: item.required,
            isChild: item.isChild,
            parentID: item.parentID,
            validation: item.custom_validate_regex,
            validationMessage: item.custom_validate_regex_message,
            type: item.type,
            options: (item.attributeOptions || []).map((item) => ({
              value: String(item.optionID),
              label: item.title,
              parentID: item.parentID,
            })),
          };
        })
        .sort((a, b) => {
          return a.order - b.order;
        });
    },
    get getValues2() {
      const isNewPost = self.id && !self.title;

      let specialAttributes = {};
      self.attributes.forEach((item) => {
        const selected =
          self.specialAttributes
            .slice()
            .find((att) => String(att.label.slice(1)) === String(item.id)) ||
          {};
        specialAttributes[selected.label] = selected.value || "";
      });

      return {
        ...(isNewPost ? { phoneNumber: self.phoneNumber } : {}),
        title: self.title,
        vip: self.vip,
        category: self.category,
        subCategory: self.subCategory,
        body: self.body,
        price: self.price,
        rent: self.rent,
        city: self.city,
        currency: self.currency,
        images: self.photos,
        specialAttributes,
      };
    },

    getValues(isNewPost) {
      let specialAttributes = {};
      let values = {};
      self.attributes.forEach((item) => {
        const selected =
          self.specialAttributes
            .slice()
            .find((att) => String(att.label.slice(1)) === String(item.id)) ||
          {};
        specialAttributes[selected.label] = selected.value || "";
      });

      values = {
        ...(isNewPost ? { phoneNumber: self.phoneNumber } : {}),
        title: self.title,
        vip: self.vip,
        category: self.category,
        subCategory: self.subCategory,
        body: self.body,
        price: self.price,
        rent: self.rent,
        city: self.city,
        currency: self.currency,
        images: self.photos,
        specialAttributes,
      };
      return values;
    },
  }))
  .actions((self) => {
    let initialState = {};
    const { PostStore } = useStore();

    const updateStatus = (status) => {
      self.loadingOn();
      return api
        .call("Post", "updateStatus", [self.id, PostStore.getStatus(status)])
        .then(() => self.fetch(self.id))
        .finally(self.loadingOff);
    };

    return {
      loadingOff() {
        self.actionLoading = false;
      },
      loadingOn() {
        self.actionLoading = true;
      },
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      fetch: flow(function* (id) {
        self.fetchLoading = true;
        try {
          const res = yield api.call("Post", "getSinglePost", [
            id,
            { edit: true },
          ]);

          console.log({ res });
          applySnapshot(self, res.data);
          self.fetchLoading = false;
        } catch {
        } finally {
          self.fetchLoading = false;
        }
      }),
      kill: () => {
        applySnapshot(self, initialState);
      },
      changeData(key, val) {
        self[key] = val;
      },
      rearrengeImages(form) {
        return {
          ...form,
          images: form.images
            .filter((item) => item.uploadStatus === "success")
            .sort((a) => (a.isMainImage ? -1 : 1)),
        };
      },
      checkCountryCode(form) {
        let phoneNumber = utils.toEnglishDigits(
          form.phoneNumber
            .trim()
            .replaceAll(" ", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("-", "") || ""
        );

        if (phoneNumber.startsWith("0")) {
          phoneNumber = phoneNumber.replace("0", "+964");
        } else if (!phoneNumber.includes("+")) {
          phoneNumber = `+964${phoneNumber}`;
        }

        return {
          ...form,
          phoneNumber,
        };
      },
      renew: flow(function* (
        cb = () => false,
        error = () => false
      ) {
        self.updateAndCreateLoading = true;
        try {
          yield api.call("Post", "renewPost", [ self.id ]);
          yield self.fetch(self.id);
          cb(self.id);
        } catch {
          error();
        } finally {
          self.updateAndCreateLoading = false;
        }
      }),
      editPost: flow(function* (
        formData,
        cb = () => false,
        error = () => false
      ) {
        const form = self.rearrengeImages(formData);
        self.updateAndCreateLoading = true;
        try {
          yield api.call("Post", "updatePost", [
            self.id,
            {
              ...form,
              title: utils.toEnglishDigits(form.title),
              body: utils.toEnglishDigits(form.body),
              shop: self.shop.id ? self.shop.exportForPost : null,
              images: form.images.map((item) => ({
                origin: item.origin,
                thumb: item.thumb,
                preview: item.preview,
              })),
            },
          ]);
          yield self.fetch(self.id);
          cb(self.id);
        } catch {
          error();
        } finally {
          self.updateAndCreateLoading = false;
        }
      }),
      addPost: flow(function* (
        formData,
        callback = () => false,
        error = () => false
      ) {
        const form = self.checkCountryCode(self.rearrengeImages(formData));

        self.updateAndCreateLoading = true;

        try {
          yield api.call("Post", "addPost", [
            self.id,
            {
              ...form,
              title: utils.toEnglishDigits(form.title),
              body: utils.toEnglishDigits(form.body),
              ...(self.shop.id ? { shop: self.shop.exportForPost } : {}),
              images: form.images.map((item) => ({
                origin: item.origin,
                thumb: item.thumb,
                preview: item.preview,
              })),
            },
          ]);
          callback(self.id);
          self.kill();
        } catch {
          error();
        } finally {
          self.updateAndCreateLoading = false;
        }
      }),
      generate: () => {
        self.id = api.static("Post", "create");
      },

      setUploadLoading: (loading) => {
        self.uploadLoading = loading;
      },
      delete() {
        self.loadingOn();

        return api
          .call("Post", "removePost", [self.id])
          .then(self.kill)
          .finally(self.loadingOff);
      },
      getStatusType() {
        return api.static("Post", "getStatusType", [self.status]);
      },
      accept: () => updateStatus("ACCEPTED"),
      reject: () => updateStatus("REJECTED"),
      unverify: () => updateStatus("NOT_VERIFIED"),
    };
  })
  .preProcessSnapshot((sn) => {
    let specialAttributes = [];
    if (sn.specialAttributes) {
      for (let item in sn.specialAttributes) {
        specialAttributes.push({
          label: item,
          value: String(sn.specialAttributes[item]),
        });
      }
    }
    return {
      ...sn,
      created: sn.created && sn.created.seconds,
      expiresAt: sn.expiresAt && sn.expiresAt.seconds,
      date: sn.date && sn.date.seconds && sn.date.seconds,
      currency: sn.currency && String(sn.currency),
      price: (sn.price && String(sn.price)) || (sn.rent && String(sn.rent)),
      rent: sn.rent && String(sn.rent),
      specialAttributes,
      shop: sn.shop || shopDefaultValue,
    };
  });
