import React, { useEffect, useState } from "react";
import { Button, Row, Col, Space, Typography } from "antd";
import { ArrowRightOutlined, SyncOutlined } from "@ant-design/icons";
import { Form, Input } from "formik-antd";
import { Formik } from "formik";
import { Input as AntInput } from "antd";
import OtpInput from "react-otp-input";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import packageJson from "../../../package.json";
import "./Login.less";
import Logo from "./../../assets/images/logo.svg";
import { AUTH } from "../../constants";
import { useLocale, useStore } from "../../hooks";
import { Loading } from "../../components/private";

const { Text } = Typography;

const Login = () => {
  const { AuthStore } = useStore();
  const t = useLocale("global");
  const {
    OTPSending,
    sendOTP,
    login,
    loginLoading,
    isAuthenticated,
    fetchingProfile,
    fetch,
  } = AuthStore;

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated) return <Redirect to="/" exact />;
  if (fetchingProfile) return <Loading />;

  return (
    <div className="login">
      <Helmet title={t("login.pageTitle")}></Helmet>

      <Row justify="center" align="middle" className="row">
        <Col span={24} xs={{ span: 18 }} sm={{ span: 12 }} md={{ span: 6 }}>
          <Space direction="vertical" size={15} className="space">
            <header>
              <img src={Logo} alt="logo" />
              <Text strong>{t("login.title")}</Text>
              <div style={{ display: "flex", bottom: 20 }}>
                {" "}
                {packageJson.version}{" "}
              </div>
            </header>

            <div
              className="loginRecaptcha"
              ref={(node) => AuthStore.setRecaptcha(node)}
            >
              <div id="recaptcha-container"></div>
            </div>

            {loginLoading ? (
              <div className="loginLoading ">
                <Text>{t("login.loading")}</Text>
                <SyncOutlined spin style={{ marginRight: 20 }} />
              </div>
            ) : (
              <LoginForm
                login={login}
                sendOTP={sendOTP}
                OTPSending={OTPSending}
              />
            )}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

const LoginForm = ({ login, sendOTP, OTPSending }) => {
  const [isInitialValid, setIsInitialValid] = useState(false);
  const [OTPStep, setOTP] = useState(false);
  const schema = AUTH.validation;
  const t = useLocale("global");

  useEffect(() => {
    schema
      .validate(AUTH.defaultValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeOtp = (value, formik) => {
    formik.setFieldValue("otp", value);
    value.length === 6 && formik.handleSubmit();
  };

  const handleSubmit = (data) => {
    login(data).then(() => {
      setOTP(false);
    });
  };

  return (
    <Formik
      initialValues={AUTH.defaultValues}
      enableReinitialize
      validationSchema={schema}
      isInitialValid={isInitialValid}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form layout="vertical" size="large">
          {OTPStep ? (
            <div className="otpStep">
              <Text>{t("login.steps.otp.title")}</Text>

              <Form.Item
                style={{ marginTop: 16 }}
                name="otp"
                className="otpInputField"
              >
                <OtpInput
                  name="otp"
                  value={formik.values.otp}
                  shouldAutoFocus
                  onChange={(val) => handleChangeOtp(val, formik)}
                  className="otpInput"
                  containerStyle={{ flexDirection: "rowReverse" }}
                  numInputs={6}
                  isInputNum
                />
              </Form.Item>
              <Button
                type="primary"
                block
                size="large"
                ghost
                onClick={() => setOTP(false)}
                icon={<ArrowRightOutlined />}
              >
                {t("login.steps.otp.changeNumber")}
              </Button>
            </div>
          ) : (
            <React.Fragment>
              <AntInput.Group compact>
                <Row style={{ width: "100%", display: "flex" }}>
                  <Col span={16}>
                    <Form.Item name="phone">
                      <Input
                        name="phone"
                        size="large"
                        autoComplete="off"
                        placeholder={t("login.steps.phone.phoneNumber")}
                        // prefix={<UserOutlined />}
                        style={{ textAlign: "left" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="phoneRegion">
                      <Input
                        style={{
                          textAlign: "left",
                        }}
                        name="phoneRegion"
                        size="large"
                        autoComplete="off"
                        placeholder={t("login.steps.phone.phoneRegion")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </AntInput.Group>
              <Button
                type="primary"
                block
                disabled={!formik.isValid}
                size="large"
                loading={OTPSending}
                onClick={() =>
                  sendOTP(formik.values).then(() => {
                    setOTP(true);
                  })
                }
              >
                {t("login.steps.phone.sendCodeButton")}
              </Button>
            </React.Fragment>
          )}
        </Form>
      )}
    </Formik>
  );
};
export default observer(Login);
