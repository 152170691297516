import { date } from ".";
import localesFile from "./../locales/index";

class Locales {
  locales = ["ku", "fa"];

  defaultLocale = "ku";
  locale = "ku";
  langsData = {};

  setLangsData(locale) {
    let langsData = {};
    localesFile.forEach((item) => {
      import(`./../locales/${locale}/${item}.json`).then(
        ({ default: langsJson }) => {
          langsData[item] = langsJson;
        }
      );
    });
    this.langsData = langsData;
  }

  init() {
    const lastLocale = localStorage.getItem("locale");
    this.setLocale(lastLocale);
  }

  setLocale(locale) {
    const isValid = this.locales.includes(locale);
    if (isValid) {
      this.setLangsData(locale);
      this.locale = locale;
      date.config(this.locale);
    } else this.changeLocale(this.defaultLocale);
  }

  changeLocale(locale) {
    this.setLocale(locale);
    localStorage.setItem("locale", locale);
    window.location.reload();
  }

  translate(namespace, ref) {
    const address = ref.split(".");
    let text = this.langsData[namespace] || {};

    for (let i = 0; i < address.length; i++) {
      if (text[address[i]]) text = text[address[i]];
    }

    return typeof text !== "string" ? "⚠️UNDEFINED⚠️" : text;
  }
}

const locales = new Locales();
export default locales;
