import React from "react";
import { Field } from "formik-antd";

import { FormFieldWrapper } from "../../../../components/private";
import { useLocale, useMobile } from "../../../../hooks";

const VipField = React.memo(() => {
  const isSmallDevice = useMobile(true);
  const t = useLocale("posts/form");
  return (
    <FormFieldWrapper
      span={isSmallDevice ? 24 : 12}
      name="vip"
      placeholder={t("fields.vip.placeholder")}
    >
      <Field type="checkbox" name="vip" />
    </FormFieldWrapper>
  );
});

export default VipField;