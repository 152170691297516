import React from "react";
import { Dropdown, Typography, Menu } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { UserOutlined, LogoutOutlined, DownOutlined } from "@ant-design/icons";

import "./HeaderProfile.less";
import { useLocale, useStore } from "../../../hooks";
import { observer } from "mobx-react";

const { Text } = Typography;

const HeaderProfile = () => {
  const t = useLocale("components");
  return (
    <Dropdown overlay={DropdownMenu} trigger={["click"]}>
      <div className="header-profile">
        <Avatar icon={<UserOutlined />} />
        <Text strong>{t("headerProfile.label")}</Text>
        <DownOutlined />
      </div>
    </Dropdown>
  );
};

const DropdownMenu = () => {
  const t = useLocale("components");
  const { AuthStore } = useStore();
  const { logout } = AuthStore;

  return (
    <Menu>
      <Menu.Item key="0">
        <a href="http://www.alipay.com/">{t("headerProfile.profile")}</a>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item danger key="3" onClick={logout}>
        {t("headerProfile.logOut")}
        <LogoutOutlined />
      </Menu.Item>
    </Menu>
  );
};
export default observer(HeaderProfile);
