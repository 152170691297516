import React, { useEffect, useState } from "react";
import classNames from "classnames";

import {
  DeleteOutlined,
  LoadingOutlined,
  UndoOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ClickNHold from "react-click-n-hold";

import "./PhotoList.less";
import { ImagesModal, UploadButton } from "../../private";
import { useLocale } from "../../../hooks";

const modalDefaults = {
  isOpen: false,
  slides: [],
  initialSlide: 0,
};

const PhotoList = ({ photos, editable, upload, onChange, setMainImage }) => {
  const [modalData, setModalData] = useState(modalDefaults);
  const t = useLocale("components");

  const onHold = (photoDetails) => {
    setMainImage(photoDetails.uid);
  };

  useEffect(() => {
    if (photos.every((item) => !item.uploading)) {
      onChange(
        photos.map((item) => ({
          uid: item.uid,
          name: item.name,
          isMainImage: item.isMainImage,
          preview: item.preview,
          origin: item.origin,
          uploadStatus: item.uploadStatus,
          thumb: item.thumb,
        }))
      );
    }
  }, [photos]);

  return (
    <div className="photoList">
      {photos.map((photoDetails, i) => (
        <ClickNHold
          time={1}
          //   onStart={}
          onClickNHold={() => onHold(photoDetails)}
        >
          <PhotoThumb
            idx={i}
            removable={editable}
            key={photoDetails.uid}
            onClick={() => {
              setModalData({
                slides: [
                  ...photos
                    .filter((item) => item.uploadStatus === "success")
                    .map((item) => item.origin || item.preview),
                ],
                initialSlide: i,
                isOpen: true,
              });
            }}
            {...photoDetails}
          />
        </ClickNHold>
      ))}

      <ImagesModal
        data={modalData}
        onClose={() => setModalData(modalDefaults)}
      />

      {editable && (
        <UploadButton
          placeholder={t("photoList.placeholder")}
          upload={upload}
        />
      )}
    </div>
  );
};

const PhotoThumb = ({
  removable,
  preventDeletion,
  thumb64,
  name,
  remove,
  reUpload,
  removing,
  uploading,
  isMainImage,
  uploadStatus,
  onClick,
}) => {
  const t = useLocale("components");
  const isFialdImage = uploadStatus === "faild";
  const className = classNames("photoThumb", {
    "-faild": isFialdImage,
    "-isMain": isMainImage,
  });

  const optionsClassName = classNames("photoThumb__options", {
    "-removing": removing,
  });

  return (
    <div className={className}>
      {removable && !uploading && !isFialdImage && (
        <div className={optionsClassName}>
          {removing ? (
            <LoadingOutlined spin />
          ) : (
            <button type="button" onClick={remove}>
              <DeleteOutlined />
            </button>
          )}

          <span className="loadingText">{t("photoList.removing")}</span>

          <button type="button" onClick={preventDeletion} className="undoBtn">
            <span>{t("photoList.cancel")}</span>
            <UndoOutlined />
          </button>
        </div>
      )}

      {isFialdImage && (
        <div className="photoThumb__options">
          <button type="button" className="-reUpload" onClick={reUpload}>
            <SyncOutlined spin={false} />
          </button>
        </div>
      )}

      {uploading && (
        <div className="photoThumb__uploading">
          <LoadingOutlined spin />
          <span className="uploadingText">{t("photoList.uploading")}</span>
        </div>
      )}

      <img
        src={thumb64}
        onClick={!isFialdImage && onClick}
        loading="lazy"
        alt={name}
      />
    </div>
  );
};

export default PhotoList;
