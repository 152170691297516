import { applySnapshot, flow, getSnapshot, types } from "mobx-state-tree";
import { SHOPS } from "../../../constants";
import { useStore } from "../../../hooks";
import { api, fakeApi } from "../../../utils";
import { ShopDocumentsModel } from "./ShopDocumentsModel";

const ShopModel = types
  .compose(
    types.model({
      shopId: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      about: types.maybeNull(types.string),
      city: types.maybeNull(types.number),
      category: types.maybeNull(types.number),
      logo: types.optional(types.string, ""),
      owner: types.maybeNull(types.string),
      idCart: types.maybeNull(types.string),
      phoneNumber: types.maybeNull(types.string),
      shopNumber: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      subCategories: types.optional(types.array(types.number), []),
      cover: types.optional(types.string, ""),
      address: types.maybeNull(types.string),
      status: types.maybeNull(types.number),
      location: types.maybeNull(
        types.model({
          lat: types.number,
          lng: types.number,
        })
      ),
      contactInfo: types.optional(
        types.array(
          types.model({
            title: types.string,
            value: types.string,
          })
        ),
        []
      ),
      workingTimes: types.optional(
        types.array(
          types.model({
            from: types.number,
            to: types.number,
          })
        ),
        []
      ),
    }),
    ShopDocumentsModel,
    types.model({
      fetchLoading: types.optional(types.boolean, false),
      updateAndCreateLoading: types.optional(types.boolean, false),
      actionLoading: types.optional(types.boolean, false),
      uploadLoading: types.optional(types.boolean, false),
    })
  )

  .views((self) => ({
    get exportForPost() {
      return {
        name: self.name,
        shopId: self.shopId,
        shopNumber: self.shopNumber,
        logo: self.logo,
      };
    },
    get getValues() {
      return {
        phoneNumber: self.phoneNumber,
        description: self.description,
        name: self.name,
        owner: self.owner,
        idCart: self.idCart,
        location: JSON.parse(JSON.stringify(self.location)),
        documents: self.documentFiles,
        address: self.address,
        category: self.category,
        city: self.city,
        subCategories: self.subCategories,
        workingTimes: JSON.parse(JSON.stringify(self.workingTimes)),
        about: self.about,
        contactInfo: JSON.parse(JSON.stringify(self.contactInfo)),
        cover: self.cover,
        logo: self.logo,
      };
    },
  }))
  .actions((self) => {
    let initialState = {};
    const { ShopStore } = useStore();

    const updateStatus = (status) => {
      self.loadingOn();
      return api
        .call("Shops", "updateStatus", [self.id, ShopStore.getStatus(status)])
        .then(() => self.fetch(self.id))
        .finally(self.loadingOff);
    };

    return {
      loadingOff() {
        self.actionLoading = false;
      },
      loadingOn() {
        self.actionLoading = true;
      },
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      fetch: flow(function* (id) {
        try {
          self.fetchLoading = true;
          const { data } = yield api.call("Shops", "getSingleShop", [id]);
          console.log({ data });
          applySnapshot(self, data);
          self.fetchLoading = false;
        } catch (err) {
          console.log({ err });
        } finally {
          self.fetchLoading = false;
        }
      }),
      kill: () => {
        applySnapshot(self, initialState);
      },
      generate: () => {
        self.id = api.static("Shops", "create");
      },

      uploadSingle(file) {
        self.setUploadLoading(true);
        return new Promise(function uploadFile(resolve, reject) {
          api
            .call("Shops", "addImage", [self.id, file])
            .then(({ data }) => {
              if (Boolean(data && data.url)) {
                resolve(data.url);
              } else reject();
            })
            .catch((er) => reject(er))
            .finally(() => {
              self.setUploadLoading(false);
            });
        });
      },

      create: flow(function* (
        formData,
        callback = () => false,
        error = () => false
      ) {
        try {
          self.updateAndCreateLoading = true;
          yield api.call("Shops", "addShop", [
            {
              ...formData,
              ...(formData.location && {
                location: [formData.location.lat, formData.location.lng],
              }),
              documents: formData.documents.map((item) => item.url),
              workingTimes: JSON.parse(JSON.stringify(formData.workingTimes)),
              contactInfo: formData.contactInfo.filter(
                (item) => item.title && item.value
              ),
            },
            self.id,
          ]);
          callback(self.id);
          self.kill();
        } catch (err) {
          console.log(err);
          error();
        } finally {
          self.updateAndCreateLoading = false;
        }
      }),
      update: flow(function* (
        formData,
        callback = () => false,
        error = () => false
      ) {
        try {
          self.updateAndCreateLoading = true;
          yield api.call("Shops", "updateShop", [
            {
              ...formData,
              ...(formData.location && {
                location: [formData.location.lat, formData.location.lng],
              }),
              documents: formData.documents.map((item) => item.url),
              workingTimes: JSON.parse(JSON.stringify(formData.workingTimes)),
              contactInfo: JSON.parse(
                JSON.stringify(formData.contactInfo)
              ).filter((item) => item.title && item.value),
            },
            self.id,
          ]);
          callback(self.id);
          self.kill();
        } catch (err) {
          console.log(err);
          error();
        } finally {
          self.updateAndCreateLoading = false;
        }
      }),

      apply(key, value) {
        self[key] = value;
      },

      setUploadLoading: (loading) => {
        self.uploadLoading = loading;
      },
      delete() {
        self.loadingOn();

        return api
          .call("Shops", "removeShop", [self.id])
          .then(self.kill)
          .finally(self.loadingOff);
      },
      getStatusType() {
        return api.static("Shops", "getStatusType", [self.status]);
      },
      accept: () => updateStatus("ACCEPTED"),
      reject: () => updateStatus("REJECTED"),
      unverify: () => updateStatus("NOT_VERIFIED"),
    };
  })
  .preProcessSnapshot((sn) => {
    if (sn) {
      const shouldConvertLocation =
        sn.location && sn.location.wf && sn.location.df;

      return {
        ...sn,
        ...(shouldConvertLocation && {
          location: {
            lat: sn.location.df,
            lng: sn.location.wf,
          },
        }),
      };
    }
  });

export { ShopModel };
