import { toJS } from "mobx";
import { types, destroy } from "mobx-state-tree";
import arrayMove from "array-move";

import { utils } from "../utils";

import {
  CategoryAttributeModel,
  CategoryModel,
  SubCategoryModel,
  CategoryOptionModel,
} from "./models";

const FieldsStore = types
  .compose(
    "FieldsStore",
    types.model({
      collapsed: types.optional(types.boolean, false),
      fullScreenMode: types.optional(types.boolean, false),
      loading: types.optional(types.boolean, true),
      modal: types.maybeNull(
        types.enumeration(["category", "attribute", "options", "subCategory"])
      ),
      editMode: types.optional(types.boolean, false),
      editableData: types.optional(
        types.model({
          category: types.maybeNull(CategoryModel),
          subCategory: types.maybeNull(SubCategoryModel),
          attribute: types.maybeNull(CategoryAttributeModel),
          options: types.maybeNull(CategoryOptionModel),
        }),
        {}
      ),
    }),
    types.model({
      categories: types.optional(types.array(CategoryModel), []),
    })
  )
  .views((self) => ({
    get reviews() {
      let changes = [];
      function pushChanges(item, model, route) {
        if (!item.changeType) return;
        const modelTitle = {
          options: "آپشن",
          attribute: "ویژگی",
          category: "دسته بندی",
          subCategory: "زیر دسته",
        }[model];
        const title = {
          new: `${modelTitle} به ${route} اضافه شد.`,
          removed: `${modelTitle} ${route} حذف شد.`,
          updated: `${modelTitle} ${route} بروزرسانی شد.`,
        }[item.changeType];
        changes.push({
          key: changes.length + 1,
          title,
          type: item.changeType,
          item,
          model,
        });
      }
      function checkOptions(options, title) {
        options.forEach((option) => {
          const optTitle = `${title} / ${option.title}`;
          pushChanges(option, "options", optTitle);
        });
      }
      function checkAttributes(attributes, title) {
        attributes.forEach((attr) => {
          const attrTitle = `${title} / ${attr.attributeTitle}`;
          pushChanges(attr, "attribute", attrTitle);
          if (attr.changeType === "removed") return;
          checkOptions(attr.attributeOptions, attrTitle);
        });
      }
      self.categories.forEach((cat) => {
        const title = cat.categoryTitle;
        pushChanges(cat, "category", title);
        if (cat.changeType === "removed") return;
        checkOptions(cat.sortOptions, title);
        checkAttributes(cat.attributes, title);
        cat.subCategories.forEach((subCat) => {
          const subTitle = `${title} / ${subCat.categoryTitle}`;
          pushChanges(subCat, "subCategory", subTitle);
          if (subCat.changeType === "removed") return;
          checkOptions(subCat.currencyOptions, subTitle);
          checkAttributes(subCat.attributes, subTitle);
        });
      });
      return changes;
    },
    get exportableData() {
      function updatedOptions(options = []) {
        return utils.fieldsMap(options);
      }
      function updateAttributes(attributes) {
        return utils.fieldsMap(attributes).map((attr) => ({
          ...attr,
          attributeOptions: updatedOptions(attr.attributeOptions),
        }));
      }
      const data = utils.fieldsMap(toJS(self.categories)).map((cat) => ({
        ...cat,
        sortOptions: updatedOptions(cat.sortOptions),
        attributes: updateAttributes(cat.attributes),
        subCategories: utils.fieldsMap(cat.subCategories).map((subCat) => ({
          ...subCat,
          currencyOptions: updatedOptions(subCat.currencyOptions),
          attributes: updateAttributes(subCat.attributes),
        })),
      }));
      return data;
    },
    getCat(id) {
      const item = toJS(self.categories).findIndex(
        (item) => item.categoryID === Number(id)
      );

      return self.categories[item] || {};
    },
    getSubCat(id, subId) {
      if (!Object.values(self.getCat(id)).length) return {};
      const item = toJS(self.getCat(id).subCategories || []).findIndex(
        (item) => item.categoryID === Number(subId)
      );

      return self.getCat(id).subCategories[item] || {};
    },
    getCategories(id) {
      const parent = id ? this.getCat(id) : self;
      const field = id ? "subCategories" : "categories";
      const data = (parent[field] || []).map((item, index) => ({
        key: String(item.categoryID),
        title: item.categoryTitle,
        slug: item.slug,
        item: item,
        editMode: item.editMode,
        remove: item.remove,
        removed: item.removed,
        update: item.update,
        index,
      }));
      return { data, parent };
    },
    getAttributes(id, category) {
      const parent = category ? this.getSubCat(category, id) : this.getCat(id);
      const data = (parent.attributes || []).map((item, index) => ({
        key: String(item.attributeID),
        title: item.attributeTitle,
        type: item.type,
        editMode: item.editMode,
        remove: item.remove,
        removed: item.removed,
        update: item.update,
        index,
      }));
      return { data, parent };
    },
    getOptions({ id, type, category, subcat }) {
      let parent;
      let field;
      if (type === "attributes") {
        const cat = subcat
          ? this.getSubCat(category, subcat)
          : this.getCat(category);
        const item = toJS(cat.attributes).findIndex(
          (item) => String(item.attributeID) === String(id)
        );
        parent = cat.attributes[item];
        field = "attributeOptions";
      } else if (type === "category") {
        parent = this.getCat(id);
        field = "sortOptions";
      } else if (type === "subcat") {
        parent = this.getSubCat(category, id);
        field = "currencyOptions";
      }
      const data = ((parent || {})[field] || []).map((item, index) => ({
        key: String(item.optionID),
        title: item.title,
        editMode: item.editMode,
        remove: item.remove,
        removed: item.removed,
        update: item.update,
        index,
      }));
      return { data, parent, field };
    },
  }))
  .actions((self) => ({
    fillCategories(data) {
      self.categories = data;
    },
    setCollapse: (collapse) => {
      self.collapsed = collapse;
      self.fullScreenMode = false;
    },
    setFullScreenMode: (fullScreenMode) => {
      self.fullScreenMode = fullScreenMode;
      self.collapsed = fullScreenMode;
    },
    removeItem(item) {
      destroy(item);
    },
    toggleModal: (type, data, editMode = false) => {
      self.editableData[type] = data;
      self.editMode = editMode;
      self.modal = self.modal === type ? null : type;
    },
    createModel({ form, categoryId, subCategoryId, attributeId, type }) {
      const category = self.getCat(categoryId);
      const subCategory = self.getSubCat(categoryId, subCategoryId);
      let parent = subCategoryId ? subCategory : category;
      let model;
      let parentId = "categoryID";
      let unit = 1;
      let keyId = "categoryID";

      if (type === "category") {
        if (categoryId) {
          model = "subCategories";
          unit = 100000;
        } else {
          parent = self;
          model = "categories";
          parentId = null;
          unit = 100000000;
        }
      } else if (type === "attribute") {
        model = "attributes";
        unit = 10000;
        keyId = "attributeID";
      } else if (type === "options") {
        keyId = "optionID";
        if (attributeId) {
          const cat = subCategoryId ? subCategory : category;
          const item = toJS(cat.attributes).findIndex(
            (item) => String(item.attributeID) === String(attributeId)
          );
          parent = cat.attributes[item];
          model = "attributeOptions";
          parentId = "attributeID";
        } else if (subCategoryId) {
          model = "currencyOptions";
          unit = 0;
        } else {
          model = "sortOptions";
        }
      }
      if (type === "category" && !categoryId) {
        self.categories.push({
          ...form,
          new: true,
          categoryID: utils.fieldID(null, parent[model], keyId, unit),
        });
        return;
      }
      parent.addNewItemToChild(model, {
        ...form,
        [keyId]: utils.fieldID(parent[parentId], parent[model], keyId, unit),
        new: true,
      });
    },
    sortData(oldIndex, newIndex) {
      const sortItem = JSON.parse(
        JSON.stringify(toJS(self.categories[oldIndex]))
      );
      self.categories.splice(oldIndex, 1);
      self.categories.splice(newIndex, 0, sortItem);
    },
  }));

export default FieldsStore.create();
