import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { PageHeader, Modal } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { useStore, useMobile } from "./../hooks";

const pageWrapper = (Component, passedProps) =>
  observer((compProps) => {
    const {
      hasHeader,
      title: wrapperTitle,
      subTitle,
      customHeaderButtons = [],
    } = passedProps;

    const { GlobalStore } = useStore();
    const isMobile = useMobile();
    const { fullScreenMode } = GlobalStore;
    const { goBack, listen } = useHistory();
    const title = wrapperTitle || compProps.title;
    const pageClassName = classNames("page", {
      fullscreen: fullScreenMode || isMobile,
    });

    // router change
    listen(() => {
      Modal.destroyAll();
    });

    return (
      <React.Fragment>
        {title && <Helmet title={title}></Helmet>}
        <div className="page-wrapper">
          {hasHeader && (
            <PageHeader
              className="site-page-header"
              title={title || ""}
              subTitle={subTitle || ""}
              onBack={goBack}
              backIcon={<ArrowRightOutlined />}
              extra={[...customHeaderButtons]}
            />
          )}
          <div className={pageClassName}>
            <Component {...compProps} useStore={useStore()} />
          </div>
        </div>
      </React.Fragment>
    );
  });

export default pageWrapper;
