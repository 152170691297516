import React from "react";
import { Dropdown, Menu, Modal, Typography, Button, Badge } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useLocale } from "../../../hooks";

const { Text } = Typography;
const { confirm } = Modal;

const ActionDropMenu = ({
  data,
  title,
  model,
  badge,
  removeCallBack = () => false,
}) => {
  const { push } = useHistory();
  const t = useLocale("global");

  const showConfirm = (item) => {
    confirm({
      title: item.title,
      okType: item.okType,
      okText: item.okText,
      icon: <item.icon />,
      cancelText: t("cancel"),
      maskClosable: true,
      cancelButtonProps: {
        type: "text",
      },
      onOk: () => {
        return model[item.actionName]().then(() => {
          if (item.actionName === "delete") removeCallBack();
        });
      },
    });
  };

  return (
    <Dropdown
      overlay={() => (
        <Menu>
          {data.map((item) => {
            return (
              <Menu.Item
                key="0"
                onClick={() => {
                  item.link
                    ? push(`${item.link}/${model.id}`)
                    : showConfirm(item.confirmModal);
                }}
              >
                <Text>{item.title}</Text>
              </Menu.Item>
            );
          })}
        </Menu>
      )}
      trigger={["click"]}
    >
      <Button>
        {Number(badge) > 0 ? (
          <Badge className="badge-primary" count={badge} />
        ) : null}

        <Text>{title}</Text>
        <DownOutlined style={{ marginRight: 8 }} />
      </Button>
    </Dropdown>
  );
};
export default ActionDropMenu;
