import React, { useEffect } from "react";
import { Tabs, Button, Space } from "antd";
import { observer } from "mobx-react";
import {
  SyncOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";

import "./Shops.less";
import pageWrapper from "../../utils/pageWrapper";
import { Link } from "react-router-dom";
import { useLocale, useMobile } from "../../hooks";
import { SHOPS } from "../../constants";
import ShopTable from "./components/ShopTable";
import { ActionDropMenu } from "../../components/private";

const { TabPane } = Tabs;
let isNotFirstTime = 0;
const ShopsPage = ({ useStore }) => {
  const { ShopStore, GlobalStore } = useStore;
  const isMobile = useMobile();
  const {
    activeTab,
    changeTab,
    shopList,
    getAllowedActions,
    getAction,
  } = ShopStore;

  const { list, fetchLoading, select, next, prev, page, fetch } = shopList;
  console.log({ list });
  const t = useLocale("shops/list");
  const { fullScreenMode, setFullScreenMode } = GlobalStore;

  useEffect(() => {
    changeTab(isNotFirstTime ? "ACCEPTED" : activeTab);

    isNotFirstTime++;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const operations = (
    <Space className="operations">
      <ActionDropMenu
        title={t("batchActions.title")}
        data={getAllowedActions({ ignore: null, isSingleAction: false })}
        model={shopList}
        badge={shopList.selectedKeys.length}
      />
      <Button
        icon={<SyncOutlined spin={fetchLoading} />}
        type={fetchLoading ? "primary" : "ghost"}
        shape="circle"
        onClick={fetch}
      />

      {!isMobile && (
        <Button
          icon={
            fullScreenMode ? <FullscreenExitOutlined /> : <FullscreenOutlined />
          }
          type={fullScreenMode ? "primary" : "ghost"}
          shape="circle"
          onClick={() => setFullScreenMode(!fullScreenMode, !isMobile)}
        />
      )}
    </Space>
  );

  return (
    <div className="shops">
      <Tabs
        defaultActiveKey={activeTab}
        onChange={changeTab}
        size="small"
        className="posts__tabs"
        tabBarExtraContent={operations}
      >
        {SHOPS.tabs.map((item) => (
          <TabPane tab={item.title} key={item.key}>
            <ShopTable
              loading={fetchLoading}
              data={list}
              page={page}
              next={next}
              prev={prev}
              activeTab={activeTab}
              select={select}
              getAllowedActions={getAllowedActions}
              getAction={getAction}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

const AddShopButton = () => {
  const t = useLocale("shops/list");
  return (
    <Link to="/shops/new">
      <Button type={"primary"}>{t("addShopButtonTitle")}</Button>
    </Link>
  );
};

const Shops = pageWrapper(observer(ShopsPage), {
  hasHeader: true,
  get subTitle() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("shops/list")("description");
  },
  customHeaderButtons: [<AddShopButton />],
});

export default Shops;
