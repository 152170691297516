import { Button } from "antd";
import React from "react";
import { useLocale } from "../../../hooks";
import "./Pagination.less";

const Pagination = ({ page, next, prev }) => {
  const t = useLocale("components");
  return (
    <div className="pagination">
      <Button type="primary" onClick={next}>
        {t("pagination.next")}
      </Button>
      <span>{page}</span>
      <Button type="primary" onClick={prev} disabled={page === 1}>
        {t("pagination.prev")}
      </Button>
    </div>
  );
};
export default Pagination;
