import React, { useState, useEffect } from "react";
import { Form, Input } from "formik-antd";
import { Formik } from "formik";
import { Row, Col, Typography, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { STAFF } from "../../../constants";
import { useLocale } from "../../../hooks";

const { Text } = Typography;

const FindUserStep = ({ prev, next, defaultValues, loading }) => {
  const [isInitialValid, setIsInitialValid] = useState(false);
  const schema = STAFF.userDefinitionValidation;
  const t = useLocale("staff/form");

  useEffect(() => {
    schema
      .validate(defaultValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <div>
      <Formik
        initialValues={defaultValues}
        validationSchema={schema}
        isInitialValid={isInitialValid}
        onSubmit={next}
        render={(formik) => (
          <Form layout="vertical" size="large">
            <Row justify="center" align="middle">
              <Col span={12} xs={{ span: 24 }}>
                <Text strong>{t("steps.findUser.fields.id.label")}</Text>

                <Form.Item style={{ marginTop: 16 }} name="role">
                  <Input
                    name="id"
                    placeholder={t("steps.findUser.fields.id.placeholder")}
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>

                <Button
                  htmlType="submit"
                  style={{ fontSize: 14 }}
                  disabled={!formik.isValid}
                  onClick={formik.handleSubmit}
                  loading={loading}
                  type="primary"
                >
                  {t("steps.findUser.submitButton")}
                </Button>
                <Button
                  htmlType="button"
                  onClick={prev}
                  style={{ margin: "0 8px", fontSize: 14 }}
                >
                  {t("steps.findUser.cancelButton")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

export default FindUserStep;
