import React from "react";
import { Typography } from "antd";
import classNames from "classnames";
import "./Label.less";

const { Text } = Typography;

const Label = ({ title, children, responsive = [] }) => {
  const labelClassName = classNames(
    "my-label",
    responsive.map((item) => `show-in-${item}`).join(" ")
  );

  return (
    <div className={labelClassName}>
      <Text style={{ marginLeft: 20 }}>{title}</Text>
      {children}
    </div>
  );
};
export default Label;
