import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Form, Input } from "formik-antd";
import { Formik } from "formik";
import { Row, Col, Spin, notification, Button } from "antd";
import pageWrapper from "../../utils/pageWrapper";
import { Prompt, Link } from "react-router-dom";

import "./Shops.less";
import { FormFieldWrapper } from "../../components/private";
import { useLocale, useMobile, useStore } from "../../hooks";
import {
  BasicInfo,
  CatFields,
  CityField,
  ContactFieldGenerator,
  MapField,
  WorkingTimeFields,
} from "../shops/components/formFields";
import { useHistory, useParams } from "react-router";
import DocumentsField from "./components/formFields/documentsField/DocumentsField";
import ImagesField from "./components/formFields/imagesField/ImagesField";
import FormButtons from "./components/formFields/formButtons/FormButtons";
import { SHOPS } from "../../constants";

const { TextArea } = Input;

const SingleShopPage = () => {
  const [isInitialValid, setIsInitialValid] = useState(false);

  const formikRef = useRef();
  const isSmallDevice = useMobile(true);
  const { id } = useParams();
  const { ShopStore } = useStore();
  const {
    getValues,
    generate,
    kill,
    fetch,
    create,
    fetchLoading,
    update,
    uploadSingle,
  } = ShopStore.shop;

  const { category, subCategories } = getValues;
  const { push } = useHistory();
  const t = useLocale("shops/form");

  const fullSpan = 24;
  const newShopMode = id === "new";
  const loading = newShopMode ? false : fetchLoading;

  const schema = SHOPS.createShopValidation();

  useEffect(() => {
    schema
      .validate(getValues)
      .then(() => setIsInitialValid(true))
      .catch(() => setIsInitialValid(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues]);

  useEffect(() => {
    newShopMode ? generate() : fetch(id);
    return () => kill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const setFormikField = (key, value) => {
    formikRef.current.setFieldValue(key, value);
  };

  const handleSubmitForm = (form) => {
    const submitFunction = newShopMode ? create : update;

    submitFunction(
      form,
      (id) => {
        if (newShopMode) {
          notification.success({ message: t("create.notification.success") });
          push(`/shops/${id}`);
        } else {
          notification.success({ message: t("update.notification.success") });
          push(`/shops`);
        }
      },
      () => {
        let message = t("create.notification.failed");
        if (!newShopMode) message = t("update.notification.success");
        notification.error({ message });
      }
    );
  };

  if (loading) return <FormLoading />;

  return (
    <div className="singleShop">
      <div className="container">
        <Formik
          validationSchema={schema}
          isInitialValid={isInitialValid}
          initialValues={getValues}
          onSubmit={handleSubmitForm}
          innerRef={formikRef}
        >
          {(formik) => (
            <>
              <Form layout="vertical" size="large">
                <Prompt
                  when={
                    newShopMode &&
                    formik.values.name &&
                    formik.values.phoneNumber
                  }
                  message={t("leavingPageAlert")}
                />
                <Row justify="start" align="middle">
                  <Col span={24}>
                    <BasicInfo />
                    <CityField />
                    <MapField
                      label={formik.values.name}
                      location={formik.values.location}
                      setFormikField={setFormikField}
                    />

                    <CatFields
                      defaultCategory={category}
                      defaultSubCat={subCategories}
                      setFormikField={setFormikField}
                    />
                    <DocumentsField setFormikField={setFormikField} />

                    <ImagesField
                      cover={formik.values.cover}
                      logo={formik.values.logo}
                      logoError={formik.touched.logo && formik.errors.logo}
                      coverError={formik.touched.cover && formik.errors.cover}
                      setFormikField={setFormikField}
                      upload={uploadSingle}
                    />

                    <WorkingTimeFields
                      values={formik.values.workingTimes}
                      name="workingTimes"
                      setFormikField={setFormikField}
                      placeholder={t("fields.workingTimes.placeholder")}
                    />

                    <Row>
                      <FormFieldWrapper
                        name="about"
                        placeholder={t("fields.about.placeholder")}
                        multiLine
                      >
                        <TextArea name="about"></TextArea>
                      </FormFieldWrapper>
                    </Row>

                    <ContactFieldGenerator
                      placeholder={t("fields.contact.placeholder")}
                      values={formik.values}
                    />
                  </Col>
                </Row>

                <FormButtons newShopMode={newShopMode} />
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

const FormLoading = () => {
  return (
    <div className="singleShop__loading">
      <Spin size="large" />
    </div>
  );
};

const AddPostButton = () => {
  const t = useLocale("posts/list");
  const { id } = useParams();
  return (
    <Link to={`/posts/new?shop=${id}`}>
      <Button type={"primary"}>{t("addPostButtonTitle")}</Button>
    </Link>
  );
};

const SingleShop = pageWrapper(observer(SingleShopPage), {
  hasHeader: true,
  get subTitle() {
    const pageType =
      window.location.pathname.split("/shops/")[1] === "new"
        ? "create"
        : "update";

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("shops/form")(`${pageType}.description`);
  },
  get title() {
    const pageType =
      window.location.pathname.split("/shops/")[1] === "new"
        ? "create"
        : "update";

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocale("shops/form")(`${pageType}.title`);
  },
  customHeaderButtons: [<AddPostButton />],
});

export default SingleShop;
